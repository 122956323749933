import { useLabourHour } from "@hooks/crud/labourHours/useLabourHours";
import { useProjectAssignedEmployee } from "@hooks/crud/projectEmployee/useProjectAssignedEmployee";
import { downloadFile, uploadFile } from "@hooks/utils/useUpload";
import { Grid } from "@mui/material";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import LabourHourImport, {
  ImportStep,
} from "@stories/molecules/LabourHourImport/LabourHourImport";
import { LabourHoursTableMUI } from "@stories/molecules/LabourHoursTable/LabourHoursTableMUI";
import { getEmployeeFactors } from "@utils/employeSocialFactors";
import { catchSentryError } from "@utils/sentry";
import { ExportIcon, ImportIcon } from "assets/constants";
import dayjs from "dayjs";
import { ChangeEvent, FocusEvent, useCallback, useRef, useState } from "react";
import { toast, ToastOptions } from "react-toastify";
import { EmploymentLevel } from "social-pro-common/entities/contractorEmployee";
import { PackageCommentType } from "social-pro-common/entities/packageComment";
import { ReportDocumentType } from "social-pro-common/entities/reportDocument";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  createDefaultEmployeeHoursToDateLineItem,
  createDefaultLabourHour,
  EmployeeHoursToDateLineItem,
  LabourHourLineItem,
} from "social-pro-common/interfaces/labourHour";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { packageCommentTypeToString } from "social-pro-common/interfaces/packageComment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  createDefaultReportDocument,
  ReportDocumentLineItem,
} from "social-pro-common/interfaces/reportDocument";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";

import { Header, HeaderButtonConfig, HeaderButtonType } from "../Header/Header";

interface LabourHoursProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  userOrganisation?: OrganisationLineItem;
  reportId: string;
  contractorPackage?: ContractorPackageLineItem;
  commentMap: Map<PackageCommentType, string>;
  isSubmitting: boolean;
  readOnly?: boolean;
  reportDocuments: ReportDocumentLineItem[];
  createReportDocument: (
    reportDocument: ReportDocumentLineItem,
  ) => Promise<void>;
  updateReportDocument: (
    reportDocument: ReportDocumentLineItem,
  ) => Promise<void>;
  setIsSubmitting: (isSubmitting: boolean) => void;
  updateComment: (
    packageType: PackageCommentType,
    comment: string,
    save: boolean,
  ) => Promise<void>;
  handleOnBack?: () => void;
}

export const LabourHours = ({
  commentMap,
  contractorPackage,
  createReportDocument,
  isLoading,
  isSubmitting,
  project,
  readOnly,
  reportDocuments,
  reportId,
  setIsSubmitting,
  updateComment,
  updateReportDocument,
  userOrganisation,
}: LabourHoursProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const onFileInput = async () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const [step, setStep] = useState<ImportStep>(ImportStep.Init);

  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);

  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [_uploadProgress, setUploadProgress] = useState(0);

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [searchTerm, setSearchTerm] = useState("");
  const { isLabourHourLoading, labourHours, upsertLabourHours } = useLabourHour(
    project?.id,
    reportId,
    contractorPackage?.id,
  );

  const {
    employeeCount,
    employees,
    isEmployeeLoading,
    labourHoursForEmployee,
  } = useProjectAssignedEmployee(
    userOrganisation?.id,
    project?.id,
    contractorPackage?.id,
    reportId,
  );

  // const [tableKey, setTableKey] = useState(0);

  // const handleUpdateLabourHour = useCallback(
  //   async (labourHour: LabourHourLineItem) => {
  //     setIsSubmitting(true);
  //     try {
  //       setLabourHours((prevLabourHours) => {
  //         const matchingLabourHour = prevLabourHours.find(
  //           (lh) => lh.id === labourHour.id,
  //         );
  //         if (matchingLabourHour) {
  //           prevLabourHours.map((lh) =>
  //             lh.id === labourHour.id ? { ...lh, ...labourHour } : lh,
  //           );
  //         } else {
  //           prevLabourHours.push(labourHour);
  //         }
  //         return prevLabourHours;
  //       });
  //       setTableKey((prevKey) => prevKey + 1);
  //       await upsertLabourHours([labourHour]);
  //     } catch (error) {
  //       console.error("Failed to update labour hour:", error);
  //     } finally {
  //       setIsSubmitting(false);
  //     }
  //   },
  //   [upsertLabourHours, labourHours],
  // );
  const [currentSelectedEmployees, setCurrentSelectedEmployees] = useState<
    string[]
  >([]);

  const handleUpdateLabourHourMUI = async (
    labourHours: LabourHourLineItem[],
  ) => {
    setIsSubmitting(true);
    setCurrentSelectedEmployees((prev) => [
      ...prev,
      ...labourHours.map((lh) => lh.employeeId),
    ]);
    await upsertLabourHours(labourHours);
    setCurrentSelectedEmployees((prev) =>
      prev.filter((e) => !labourHours.map((lh) => lh.employeeId).includes(e)),
    );
    setIsSubmitting(false);
  };

  const commentValue = commentMap.get(PackageCommentType.Hour) || "";

  const labourHourReportDocument = reportDocuments.find(
    (d) => d.reportDocumentType === ReportDocumentType.LabourHour,
  );

  const onDownload = useCallback(async () => {
    setIsDownloading(true);
    const labourHourReportDocument = reportDocuments.find(
      (d) => d.reportDocumentType === ReportDocumentType.LabourHour,
    );
    if (labourHourReportDocument) {
      await downloadFile(
        labourHourReportDocument.uri,
        `Labour hours - ${labourHourReportDocument.reportId}`,
      );
    }
    setIsDownloading(false);
  }, [reportDocuments]);

  const onUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setUploadProgress(0);
      if (
        e.currentTarget?.files &&
        e.currentTarget?.files.length > 0 &&
        contractorPackage
      ) {
        const file = e.currentTarget?.files[0] || null;
        if (file) {
          setIsUploading(true);

          const fileKey = `supporting-documents/${contractorPackage.id}/${reportId}/${file.name}`;
          await uploadFile(
            fileKey,
            file,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
              if (total === 100) {
                toast("Upload complete!", {
                  type: "success",
                } as ToastOptions);
              }
            },
            (error: any) => {
              setUploadProgress(0);
              catchSentryError(error);
              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
              setIsUploading(false);
            },
          );

          if (labourHourReportDocument) {
            await updateReportDocument({
              ...labourHourReportDocument,
              uri: fileKey,
            });
          } else {
            await createReportDocument(
              createDefaultReportDocument(
                contractorPackage.id,
                reportId,
                fileKey,
                ReportDocumentType.LabourHour,
              ),
            );
          }
          // const urlToImage = URL.createObjectURL(file);
          setIsUploading(false);
        }
      }
    },
    [
      contractorPackage,
      createReportDocument,
      reportId,
      reportDocuments,
      updateReportDocument,
    ],
  );

  const loading =
    (isLoading || isEmployeeLoading || isLabourHourLoading) && !isSubmitting;

  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const buttons = [
    {
      action: () => {
        setIsOpen(true);
      },
      icon: <ImportIcon />,
      text: "Import",
      type: HeaderButtonType.SECONDARY,
      width: 110,
    },
  ] as HeaderButtonConfig[];

  const tabs = [
    {
      label: "Standard",
    },
    {
      label: "Trainees and Apprentices",
    },
  ];
  const supportingDocumentsButtons: HeaderButtonConfig[] = [];

  if (!labourHourReportDocument) {
    supportingDocumentsButtons.push({
      action: onFileInput,
      icon: <ImportIcon />,
      text: "Supporting Document",
      type: HeaderButtonType.SECONDARY,
    });
  } else {
    supportingDocumentsButtons.push({
      action: onDownload,
      icon: <ExportIcon />,
      text: "Download",
      type: HeaderButtonType.SECONDARY,
    });
    supportingDocumentsButtons.push({
      action: onFileInput,
      icon: <ImportIcon />,
      text: "Reupload",
      type: HeaderButtonType.SECONDARY,
    });
  }

  const currentDate = dayjs(reportId);

  const filteredEmployees = employees.filter((employee) => {
    const contractEndDate = employee.traineeContractEndDate
      ? dayjs(employee.traineeContractEndDate)
      : null;

    const isContractEnded = contractEndDate
      ? contractEndDate.isBefore(currentDate)
      : false;

    if (tabIndex) {
      return (
        employee.employmentLevel !== EmploymentLevel.FullEmployment &&
        !isContractEnded
      );
    } else {
      return (
        employee.employmentLevel === EmploymentLevel.FullEmployment ||
        isContractEnded
      );
    }
  });

  const mappedLabourHoursForEmployee: LabourHourLineItem[] =
    project && contractorPackage && userOrganisation
      ? employees.map((employee) => {
          const employeeLabourHours = labourHours.filter(
            (lh) => lh.employeeId === employee.id,
          );

          //Does this table view only want standard employment?
          const standardEmploymentOnly = tabIndex === 0;

          //Get all social factors for employee, based on project requirements
          const socialFactors = getEmployeeFactors(employee, project);

          //Filter out social factors that are not relevant to the current view
          const socialFactorIds = socialFactors
            .filter((sf) =>
              standardEmploymentOnly
                ? !sf?.employmentLevel?.length ||
                  sf.employmentLevel?.includes(EmploymentLevel.FullEmployment)
                : true,
            )
            .map((sf) => sf.id);

          if (employeeLabourHours.length === 0) {
            return createDefaultLabourHour(
              project.id,
              reportId,
              contractorPackage.id,
              userOrganisation,
              employee,
              socialFactorIds,
            );
          }
          return { ...employeeLabourHours[0], socialFactors: socialFactorIds };
        })
      : [];

  const mappedPreviousLabourHoursForEmployee: EmployeeHoursToDateLineItem[] =
    project && contractorPackage && userOrganisation
      ? employees.map((employee) => {
          const employeeLabourHours = labourHoursForEmployee.filter(
            (lh) => lh.id === employee.id,
          );
          if (employeeLabourHours.length === 0) {
            return createDefaultEmployeeHoursToDateLineItem(employee);
          }
          return employeeLabourHours[0];
        })
      : [];

  return (
    <>
      <Header
        loading={loading}
        subTitle={`${project?.projectName}: ${
          reportId ? formatReportDate(stringToDate(reportId)) : ""
        }`}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
        mainTitle={"Labour Hours"}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        headerFontSizes={[18, 14]}
        headerGap={0}
      />

      <LabourHoursTableMUI
        isLoading={loading}
        reportId={reportId}
        isSubmitting={isSubmitting || isUploading || isDownloading}
        allLabourHours={mappedLabourHoursForEmployee}
        employeeCount={employeeCount}
        previousEmployeeLabourHours={mappedPreviousLabourHoursForEmployee}
        filterName={searchTerm}
        showStudiedHours={tabIndex === 1}
        readOnly={readOnly}
        userOrganisation={userOrganisation}
        employees={filteredEmployees}
        setPage={setPage}
        project={project}
        page={page}
        currentSelectedEmployees={currentSelectedEmployees}
        upsertLabourHours={handleUpdateLabourHourMUI}
      />
      <input
        ref={fileInput}
        hidden
        id="fileUploadInput"
        accept="*"
        type="file"
        style={{
          cursor: "pointer",
          height: "100px",
          opacity: 0,
          padding: 0.5,
          position: "fixed",
          width: "100px",
          zIndex: 1,
        }}
        onChange={onUpload}
      />
      <Header
        loading={loading}
        mainTitle=""
        subTitle={"Supporting Information"}
        buttons={supportingDocumentsButtons}
      />
      <Grid item xs={12} md={12} mt={2}>
        <StyledTextField
          data-test-id="labour-hour-comment-input"
          loading={loading}
          disabled={loading || isSubmitting || readOnly}
          id={`${packageCommentTypeToString(PackageCommentType.Hour)}`}
          name={`${packageCommentTypeToString(PackageCommentType.Hour)}`}
          label={`${packageCommentTypeToString(
            PackageCommentType.Hour,
          )} Comments`}
          fullWidth
          rows={5}
          maxLength={1000}
          multiline
          margin={"dense"}
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            await updateComment(PackageCommentType.Hour, e.target.value, false);
          }}
          onBlur={async (e: FocusEvent<HTMLInputElement>) => {
            await updateComment(PackageCommentType.Hour, e.target.value, true);
          }}
          value={commentValue}
          className="textareaBlack"
        />
      </Grid>

      {!readOnly ? (
        <LabourHourImport
          loading={loading}
          project={project}
          userOrganisation={userOrganisation}
          contractorPackageId={contractorPackage?.id}
          existingLabourHours={labourHours}
          reportId={reportId}
          step={step}
          open={isOpen}
          setIsOpen={setIsOpen}
          setStep={setStep}
          upsertLabourHours={upsertLabourHours}
        />
      ) : null}
    </>
  );
};
