import { useProjectContext } from "@hooks/context/useProjectContext";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ListTableHead from "@stories/molecules/ListTable/ListTableHead";
import { NoResultsFoundRow } from "@stories/molecules/ListTable/NoResultsFoundRow";
import {
  CustomPagination,
  paginate,
  PAGINATION_LIMIT,
} from "@stories/organisms/Table/CustomPagination";
import * as RouteHelper from "@utils/routes";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EmploymentLevel } from "social-pro-common/entities/contractorEmployee";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import {
  EmployeeHoursToDateLineItem,
  LabourHourLineItem,
  createDefaultLabourHour,
} from "social-pro-common/interfaces/labourHour";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { TableHeaderCell } from "social-pro-common/interfaces/tables";

import { LabourHourTableRow } from "./LabourHourTableRow";
import { LabourHourTableRowSkeleton } from "./LabourHourTableRowSkeleton";

interface LabourHoursTableProps {
  isLoading: boolean;
  isSubmitting: boolean;
  reportId: string;
  userOrganisation?: OrganisationLineItem;
  allLabourHours: LabourHourLineItem[];
  previousEmployeeLabourHours: EmployeeHoursToDateLineItem[];
  filterName: string;
  employees: EmployeeLineItem[];
  showStudiedHours?: boolean;
  currentSelectedEmployees: string[];
  employeeCount: number;
  readOnly?: boolean;
  setPage: any;
  page: number;
  project?: ProjectLineItem;
  upsertLabourHours: (labourHours: LabourHourLineItem[]) => Promise<void>;
}

export const LabourHoursTableMUI = ({
  allLabourHours,
  currentSelectedEmployees,
  employeeCount,
  employees,
  filterName,
  isLoading,
  isSubmitting,
  page,
  previousEmployeeLabourHours,
  project,
  readOnly,
  reportId,
  setPage,
  showStudiedHours,
  upsertLabourHours,
  userOrganisation,
}: LabourHoursTableProps) => {
  const { contractorPackage, selectedProject } = useProjectContext();
  const [filteredEmployees, setFilteredEmployees] =
    useState<EmployeeLineItem[]>(employees);

  const TABLE_HEAD: TableHeaderCell[] = [
    {
      align: "left",
      id: "employee-name",
      label: "Name or Code",
      minWidth: "15%",
      width: "20%",
    },
    {
      align: "center",
      id: "employee-social-factor",
      label: "Social Factors",
      minWidth: "15%",
      width: "25%",
    },
    {
      align: "center",
      id: "hours-to-date",
      label: "Hours to date",
      minWidth: "10%",
      width: "10%",
    },
    {
      align: "center",
      id: "hours-studied-this-month",
      label: showStudiedHours ? "Hours studied this month" : "",
      minWidth: "25%",
      width: "25%",
    },
    {
      align: "center",
      id: "hours-this-month",
      label: "Hours worked this month",
      minWidth: "25%",
      width: "25%",
    },
  ];

  const isNotFound =
    employeeCount >= 0 && !filteredEmployees.length && Boolean(filterName);

  useEffect(() => {
    if (filterName) {
      const filtered = employees.filter((employee) =>
        employee.employeeName.toLowerCase().includes(filterName.toLowerCase()),
      );
      setFilteredEmployees(filtered);
    } else {
      setFilteredEmployees(employees);
    }
  }, [filterName, employees]);

  const { data, totalPages } = paginate<EmployeeLineItem>(
    filteredEmployees,
    page,
  );

  const loading = isLoading && !isSubmitting;
  return (
    <>
      {loading ? (
        <Table>
          <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
          <TableBody>
            {Array.from({ length: 10 }, (_, index) => (
              <LabourHourTableRowSkeleton key={index} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Table
          data-test-id="labour-hours-submission-table"
          sx={{ mb: 2, mt: 2 }}
        >
          <ListTableHead loading={loading} headLabel={TABLE_HEAD} />
          <TableBody>
            {!loading &&
            selectedProject &&
            contractorPackage?.id &&
            userOrganisation &&
            employeeCount >= 0 &&
            reportId
              ? data.map((row) => {
                  const employeeLabourHour = allLabourHours.find(
                    (lh) =>
                      lh.employeeId === row.id && lh.reportId === reportId,
                  );

                  const previousEmployeeLabourHour =
                    previousEmployeeLabourHours.find((lh) => lh.id === row.id);

                  const employeeSocialFactors = project?.commitmentsHours
                    .filter((e) =>
                      employeeLabourHour?.socialFactors?.includes(e.id),
                    )
                    .filter((e) =>
                      !showStudiedHours
                        ? !e.employmentLevel?.length ||
                          e.employmentLevel?.includes(
                            EmploymentLevel.FullEmployment,
                          )
                        : true,
                    );

                  return (
                    <LabourHourTableRow
                      key={row.id}
                      loading={loading}
                      disabled={isSubmitting}
                      isSubmitting={currentSelectedEmployees.length > 0}
                      project={selectedProject}
                      employee={row}
                      labourHour={
                        employeeLabourHour ||
                        createDefaultLabourHour(
                          selectedProject.id,
                          reportId,
                          contractorPackage.id,
                          userOrganisation,
                          row,
                        )
                      }
                      showStudiedHours={showStudiedHours}
                      previousEmployeeLabourHour={previousEmployeeLabourHour}
                      readOnly={readOnly}
                      employeeSocialFactors={employeeSocialFactors}
                      handleUpdateLabourHour={upsertLabourHours}
                    />
                  );
                })
              : null}

            {isNotFound && (
              <NoResultsFoundRow colSpan={TABLE_HEAD.length + 1} />
            )}

            {employeeCount === -1 && !loading ? (
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={TABLE_HEAD.length + 1}
                  sx={{ borderRadius: "12px !important", py: 3 }}
                  className="fullBorderRad"
                >
                  <Typography paragraph align="center" sx={{ margin: "0" }}>
                    No employees on project{" "}
                    {!readOnly ? (
                      <>
                        -{" "}
                        <Link
                          to={RouteHelper.projectEmployees(
                            selectedProject?.id || "",
                            contractorPackage?.id || "",
                          )}
                          style={{
                            color: "#000",
                            fontWeight: "bold",
                            textDecorationColor: "#000",
                          }}
                        >
                          Add Employees To Project
                        </Link>
                      </>
                    ) : null}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      )}
      {!loading && filteredEmployees.length > PAGINATION_LIMIT ? (
        <CustomPagination
          onPageChange={setPage}
          page={page}
          totalPages={totalPages}
        />
      ) : null}
    </>
  );
};
