import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save";
import { Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import {
  StyledButton,
  StyledLoadingButton,
} from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import GreenMetricModal from "@stories/organisms/GreenMetricModal/GreenMetricModal";
import { Table } from "@stories/organisms/Table/Table";
import { getHeaderAndCellStyles } from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useState } from "react";
import { GreenMetricLineItem } from "social-pro-common/interfaces/greenMetric";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import {
  ActionCell,
  TABLE_HEAD,
} from "../GreenMetricTableRow/GreenMetricTableRowCells";
import { GreenMetricTableRowSkeleton } from "../GreenMetricTableRow/GreenMetricTableRowSkeleton";

interface GreenMetricRequirementFormProps {
  loading: boolean;
  project: ProjectLineItem;
  greenMetrics: GreenMetricLineItem[];
  onSubmit: (greenMetrics: GreenMetricLineItem[]) => void;
  handleBack: () => void;
  setProject: (project: ProjectLineItem) => void;
  setDirtyOnChange: (isDirty: boolean) => void;
}

export const GreenMetricRequirementForm = ({
  greenMetrics,
  handleBack,
  loading,
  onSubmit,
  project,
  setDirtyOnChange,
  setProject,
}: GreenMetricRequirementFormProps) => {
  const [metrics, setMetrics] = useState<GreenMetricLineItem[]>(greenMetrics);
  const [selectedGreenMetric, setSelectedGreenMetric] =
    useState<GreenMetricLineItem>();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const createMetric = (metric: GreenMetricLineItem) => {
    setMetrics([...metrics, metric]);
    setDirtyOnChange(true);
  };

  const goBack = () => {
    setProject({
      ...project,
      greenMetrics: metrics,
    });
    handleBack();
  };

  const updateMetric = (metric: GreenMetricLineItem) => {
    setMetrics(
      metrics.map((m) => {
        if (m.id === metric.id) {
          return metric;
        }
        return m;
      }),
    );
    setDirtyOnChange(true);
  };

  const handleEdit = (greenMetric: GreenMetricLineItem) => {
    setSelectedGreenMetric(greenMetric);
    setOpen(true);
  };

  const handleDelete = (greenMetric: GreenMetricLineItem) => {
    setSelectedGreenMetric(greenMetric);
    setOpenConfirmDialog(true);
  };

  const onDelete = () => {
    setMetrics(metrics.filter((f) => f.id !== selectedGreenMetric?.id));
    setOpenConfirmDialog(false);
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...getHeaderAndCellStyles(undefined, false),
      field: "reportTargetName",
      flex: 3,
      headerName: "Target Name",
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCell,
      cellRendererParams: {
        handleDelete,
        handleEdit,
        loading,
      },
      field: "",
      flex: 0.5,
      headerName: "",
    },
  ]);

  return (
    <Box>
      <Grid
        container
        sx={{
          padding: "25px 40px 0px 40px",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "25px",
            width: "100%",
          }}
        >
          <SubTitle loading={loading} title={"Sustainability Reporting"} />
          <Stack
            direction="row"
            spacing={2}
            alignContent={"end"}
            justifyContent={"end"}
          >
            <StyledButton
              className="blackBtn"
              loading={loading}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setSelectedGreenMetric(undefined);
                setOpen(true);
              }}
            >
              Add Custom Target
            </StyledButton>
          </Stack>
        </Box>
        <Grid item xs={12} md={12}>
          {loading ? (
            <TableSkeleton
              tableHead={TABLE_HEAD}
              rows={GreenMetricTableRowSkeleton}
            />
          ) : (
            <Table<GreenMetricLineItem>
              columnDefs={colDefs}
              loading={loading}
              data={metrics}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          marginTop: "0px",
          padding: "20px 40px 30px",
        }}
      >
        <Stack direction="row" spacing={1}>
          <StyledButton
            loading={loading}
            onClick={goBack}
            disabled={loading}
            variant="outlined"
          >
            Back
          </StyledButton>
          <StyledLoadingButton
            color="primary"
            type="submit"
            loading={loading}
            disabled={loading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={() => {
              onSubmit(metrics);
            }}
          >
            Save
          </StyledLoadingButton>
        </Stack>
      </Grid>
      {open ? (
        <GreenMetricModal
          open={open}
          loading={loading}
          projectId={project.id}
          existingMetric={selectedGreenMetric}
          createMetric={createMetric}
          updateMetric={updateMetric}
          handleClose={handleClose}
        />
      ) : null}

      <ConfirmationDialog
        message={"Are you sure you want to delete this commitment?"}
        open={openConfirmDialog}
        title={"Delete Commitment"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={onDelete}
      />
    </Box>
  );
};
