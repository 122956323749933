import { Grid } from "@mui/material";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  CellAction,
  getHeaderAndCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ProjectRowSkeleton } from "@stories/organisms/Tables/ProjectRowSkeleton";
import { ReportStatusCell } from "@stories/organisms/Tables/ProjectTableCells";
import { EditProjectIcon, ExportIcon, ViewProjectIcon } from "assets/constants";
import { FileGenerationStatus } from "social-pro-common/entities/fileGeneration";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ProjectReportSubmissionLineItem } from "social-pro-common/interfaces/projectReportSubmission";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";

import { PdfDownlodDialog } from "../PdfDownlodDialog/PdfDownlodDialog";

interface ProjectReportTableProps {
  loading: boolean;
  searchTerm: string;
  project?: ProjectLineItem;
  reportSubmissions: ProjectReportSubmissionLineItem[];
  downloadStep: FileGenerationStatus;
  downloadProgress: number;
  handleEditReport: (project: ProjectReportSubmissionLineItem) => Promise<void>;
  handlViewReport: (project: ProjectReportSubmissionLineItem) => Promise<void>;
  handleDownloadReport: (
    project: ProjectReportSubmissionLineItem,
  ) => Promise<void>;
}

export const ProjectReportTable = ({
  downloadProgress,
  downloadStep,
  handlViewReport,
  handleDownloadReport,
  handleEditReport,
  loading,
  project,
  reportSubmissions,
  searchTerm,
}: ProjectReportTableProps) => {
  const handleRowClick = async (data: ProjectReportSubmissionLineItem) => {
    await handlViewReport(data);
  };

  const cellActions = [
    {
      action: handleRowClick,
      icon: <ViewProjectIcon />,
      title: "Submit Report",
      validateEnabled: (reportSubmission: ProjectReportSubmissionLineItem) => {
        return (
          reportSubmission.reportStatus !== ReportStatus.Accepted &&
          reportSubmission.reportStatus !== ReportStatus.Complete
        );
      },
    },
    {
      action: handleDownloadReport,
      icon: <ExportIcon />,
      title: "Download Report",
      validateEnabled: (reportSubmission: ProjectReportSubmissionLineItem) =>
        reportSubmission.reportStatus === ReportStatus.Accepted ||
        reportSubmission.reportStatus === ReportStatus.Complete,
    },
    {
      action: handleEditReport,
      adminOnly: true,
      icon: <EditProjectIcon />,
      title: "Edit Report",
      validateEnabled: (reportSubmission: ProjectReportSubmissionLineItem) =>
        reportSubmission.reportStatus === ReportStatus.InProgress ||
        reportSubmission.reportStatus === ReportStatus.Accepted ||
        reportSubmission.reportStatus === ReportStatus.Complete,
    },
  ] as CellAction[];

  const colDefs = [
    {
      ...getHeaderAndCellStyles(undefined, false),
      field: "reportId",
      flex: 2,
      headerName: "Report Month",
      minWidth: 100,
      valueFormatter: (params: any) =>
        `${formatReportDate(stringToDate(params.data.reportId))}`,
    },
    {
      ...getHeaderAndCellStyles("center", false),
      field: "reportId",
      headerName: "Due Date",
      minWidth: 150,
      resizable: false,
      valueFormatter: (params: any) => {
        if (project) {
          const baseDate = stringToDate(params.data.reportId);
          const reportDueDate = project.reportingDueDate;
          baseDate.setDate(reportDueDate);
          return `${baseDate.toLocaleDateString("en-GB")}`;
        }
        return "-";
      },
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ReportStatusCell,
      field: "status",
      headerName: "Status",
      minWidth: 150,
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCell,
      field: "",
      flex: 0.5,
      headerName: "",
      minWidth: 100,
    },
  ];

  return (
    <Grid item xs={12} md={12}>
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={ProjectRowSkeleton}
        />
      ) : null}
      {!loading ? (
        <Table
          searchValue={searchTerm}
          searchKeys={["reportId"]}
          columnDefs={colDefs}
          loading={loading}
          data={reportSubmissions || []}
          context={{ cellActions }}
        />
      ) : null}

      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Package Report Export"}
      />
    </Grid>
  );
};
