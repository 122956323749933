import { Box, Stack } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import Logo from "assets/images/home/logo-white.png";
import { useNavigate } from "react-router-dom";

import {
  NavigateButton,
  StyledBox,
  StyledBoxContainer,
  StyledDescription,
  StyledTitle,
  Typography404,
} from "./components";

const ErrorPage = () => {
  const navigate = useNavigate();

  return (
    <StyledBoxContainer>
      <StyledBox>
        <Stack
          direction={"column"}
          alignItems={"center"}
          justifyContent={"flex-end"}
          gap={6}
        >
          <img src={Logo} alt="" />
          <Typography404>404</Typography404>
          <Stack direction={"column"} gap={"50px"}>
            <Stack direction={"column"} gap={"30px"}>
              <StyledTitle>An error has occurred.</StyledTitle>
              <Box sx={{ margin: "0 auto", maxWidth: "647px" }}>
                <Stack direction={"column"} gap={"30px"}>
                  <StyledDescription>
                    We are sorry for the inconvenience. Please check your
                    internet connection, firewall, proxies or try again later.
                  </StyledDescription>
                  <StyledDescription>
                    If the problem persists, please reach out to support through
                    the chat icon.
                  </StyledDescription>
                </Stack>
              </Box>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <NavigateButton
                disableRipple
                onClick={() => {
                  navigate(RouteHelper.login, { replace: true });
                  window.location.reload();
                }}
              >
                Return to Home
              </NavigateButton>
            </Stack>
          </Stack>
        </Stack>
      </StyledBox>
    </StyledBoxContainer>
  );
};

export default ErrorPage;
