import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { AnalyticsLocalContentLevel2Table } from "@stories/organisms/AnalyticsLocalContentTables/AnalyticsLocalContentLevel2Table";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import * as RouteHelper from "@utils/routes";
import { writeXlsxLidp } from "@utils/xlsx/localContent";
import { ExportIcon } from "assets/constants";
import { MapPinned } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  getSortedLocalContent,
  PackageLocalContentLineItem,
} from "social-pro-common/interfaces/packageLocalContent";

export const AnalyticsLocalContentLevel2 = () => {
  const { packageId } = useParams();
  const navigate = useNavigate();

  const [subPackage, setContractorPackage] =
    useState<ContractorPackageLineItem>();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredContent, setFilteredContent] = useState<
    PackageLocalContentLineItem[]
  >([]);

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (index: number) => {
    setTabIndex(index);
    setSearchTerm("");
  };

  const { getContractorPackage, isContractorPackageLoading } =
    useContractorPackage();
  const handleOnBack = () => {
    navigate(
      RouteHelper.analyticsLocalContent(
        selectedProject?.id || "",
        subPackage?.id || "",
      ),
    );
  };

  useEffect(() => {
    const fetchContractorPackage = async () => {
      if (packageId) {
        const contractorPackage = await getContractorPackage(packageId);
        if (contractorPackage) {
          setContractorPackage(contractorPackage);
        }
      }
    };
    fetchContractorPackage();
  }, [packageId]);

  const loading = isProjectLoading || isContractorPackageLoading;

  const tabs = [
    {
      label: "Reported",
    },
    {
      label: "Actual",
    },
  ];

  const exportLocalContent = async () => {
    if (filteredContent.length > 0) {
      writeXlsxLidp(filteredContent);
    }
  };

  const buttons = [
    {
      action: exportLocalContent,
      icon: <ExportIcon />,
      text: "Export Targets",
      type: HeaderButtonType.SECONDARY,
      width: 150,
    },
  ] as HeaderButtonConfig[];

  const breadcrumbData = [
    {
      href:
        selectedProject && contractorPackage
          ? RouteHelper.analyticsLocalContent(
              selectedProject.id,
              contractorPackage.id,
            )
          : "",
      label: "Local Content",
    },
    {
      href:
        selectedProject && contractorPackage && subPackage
          ? RouteHelper.analyticsLocalContentDetails(
              selectedProject.id,
              contractorPackage.id,
              subPackage?.id,
            )
          : "",
      label: subPackage ? subPackage.contractorName : "",
    },
  ];

  return (
    <PageContainer>
      <Header
        loading={loading}
        breadcrumbIcon={<MapPinned />}
        breadcrumbs={breadcrumbData}
        subTitle={isPrimaryPackage ? "Project Analytics" : "Package Analytics"}
        mainTitle={`Local Content - ${subPackage ? subPackage.contractorName : ""}`}
        handleBack={isPrimaryPackage ? handleOnBack : undefined}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
      />
      <AnalyticsLocalContentLevel2Table
        loading={loading}
        isPrimaryPackage={isPrimaryPackage}
        contractorPackage={contractorPackage}
        subPackage={subPackage}
        tabIndex={tabIndex}
        searchTerm={searchTerm}
        localContents={getSortedLocalContent(selectedProject?.localContents)}
        onFilteredDataChange={setFilteredContent}
      />
    </PageContainer>
  );
};
