import TrackChanges from "@mui/icons-material/TrackChanges";
import { useTheme } from "@mui/material";
import * as RouteHelper from "@utils/routes";
import { PieChart, Package, ChartNoAxesCombined } from "lucide-react";
import {
  CalendarClock,
  CircleDollarSign,
  MapPinned,
  ScrollText,
} from "lucide-react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { getMaximalDate } from "social-pro-common/utils/date";
import { NavItemType } from "types";

export const getPackageNav = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
  isOwner?: boolean,
): NavItemType => {
  const theme = useTheme();

  const currentDate = getMaximalDate(
    isOwner ? project?.projectStartDate : contractorPackage?.packageStartDate,
    isOwner ? project?.projectEndDate : contractorPackage?.packageEndDate,
  );

  const children = [];

  children.push({
    icon: <PieChart />,
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.contractorDashboard(project.id, contractorPackage.id)
        : "",
  });

  children.push({
    icon: <Package />,
    id: "my-package",
    title: "My Package",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.userPackage(project.id, contractorPackage.id)
        : "",
  });

  const analyticsChildren = [];
  if (contractorPackage?.commitmentsHours.length) {
    analyticsChildren.push({
      icon: <CalendarClock />,
      id: "analytics-labour-hours",
      title: "Labour Hours",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsLabourHoursLevel1(
              project?.id,
              contractorPackage?.id,
              currentDate,
            )
          : "",
    });
  }
  if (contractorPackage?.commitmentsSpend.length) {
    analyticsChildren.push({
      icon: <CircleDollarSign />,
      id: "analytics-spend",
      title: "Social Spend",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsSpendLevel1(
              project.id,
              contractorPackage?.id,
              currentDate,
            )
          : "",
    });
  }

  const isPrimaryPackage = contractorPackage?.id === project?.id;
  if (project?.localContents.length) {
    if (isPrimaryPackage) {
      analyticsChildren.push({
        icon: <MapPinned />,
        id: "analytics-local-content",
        title: "Local Content",
        type: "item",
        url:
          project && contractorPackage
            ? RouteHelper.analyticsLocalContent(
                project.id,
                contractorPackage.id,
              )
            : "",
      });
    } else if (!isPrimaryPackage) {
      analyticsChildren.push({
        icon: <MapPinned />,
        id: "analytics-local-content",
        title: "Local Content",
        type: "item",
        url:
          project && contractorPackage
            ? RouteHelper.analyticsPackageLocalContentDetails(
                project.id,
                contractorPackage.id,
                contractorPackage.id,
              )
            : "",
      });
    }
  }
  if (project?.policies.length) {
    analyticsChildren.push({
      icon: <ScrollText />,
      id: "analytics-policies",
      title: "Policies",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsPolicies(project.id, contractorPackage.id)
          : "",
    });
  }

  children.push({
    children: analyticsChildren,
    childrenIds: [
      ...analyticsChildren.map((item) => item.id),
      "analytics-labour-subcontractors",
      "view-analytics-labour-hours",
      "view-analytics-labour-hours-employee",
      "analytics-spend-subcontractors",
      "view-analytics-spend",
    ],
    icon: <ChartNoAxesCombined />,
    id: "analytics",
    title: "Analytics",
    type: "item",
    url: "",
  });

  // if (userHasEditorAccess(project)) {
  //   children.push({
  //     icon: <InsightsIcon />,
  //     id: "forecast",
  //     title: "Forecasting",
  //     type: "item",
  //     url:
  //       project && contractorPackage
  //         ? RouteHelper.forecast(project.id, contractorPackage.id)
  //         : "",
  //   });
  // }

  return {
    children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "overview",
    title: "Overview",
    type: "group",
  };
};

export const getReducedPackageNav = (
  project?: ProjectLineItem,
  contractorPackage?: ContractorPackageLineItem,
  isOwner?: boolean,
): NavItemType => {
  const currentDate = getMaximalDate(
    isOwner ? project?.projectStartDate : contractorPackage?.packageStartDate,
    isOwner ? project?.projectEndDate : contractorPackage?.packageEndDate,
  );

  const theme = useTheme();
  const children = [];

  children.push({
    icon: <PieChart />,
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.contractorDashboard(project.id, contractorPackage.id)
        : "",
  });

  children.push({
    icon: <Package />,
    id: "my-package",
    title: "My Package",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.userPackage(project.id, contractorPackage.id)
        : "",
  });

  const analyticsChildren = [];
  if (contractorPackage?.commitmentsHours.length) {
    analyticsChildren.push({
      icon: <CalendarClock />,
      id: "analytics-labour-hours",
      title: "Labour Hours",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsLabourHoursLevel1(
              project?.id,
              contractorPackage?.id,
              currentDate,
            )
          : "",
    });
  }
  if (contractorPackage?.commitmentsSpend.length) {
    analyticsChildren.push({
      icon: <CircleDollarSign />,
      id: "analytics-spend",
      title: "Spend",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsSpendLevel1(
              project.id,
              contractorPackage?.id,
              currentDate,
            )
          : "",
    });
  }

  const isPrimaryPackage = contractorPackage?.id === project?.id;
  if (project?.localContents.length) {
    if (isPrimaryPackage) {
      analyticsChildren.push({
        icon: <MapPinned />,
        id: "analytics-local-content",
        title: "Local Content",
        type: "item",
        url:
          project && contractorPackage
            ? RouteHelper.analyticsLocalContent(
                project.id,
                contractorPackage.id,
              )
            : "",
      });
    } else if (!isPrimaryPackage) {
      analyticsChildren.push({
        icon: <MapPinned />,
        id: "package-analytics-local-content",
        title: "Local Content",
        type: "item",
        url:
          project && contractorPackage
            ? RouteHelper.analyticsPackageLocalContentDetails(
                project.id,
                contractorPackage.id,
                contractorPackage.id,
              )
            : "",
      });
    }
  }
  if (project?.policies.length) {
    analyticsChildren.push({
      icon: <ScrollText />,
      id: "analytics-policies",
      title: "Policies",
      type: "item",
      url:
        project && contractorPackage
          ? RouteHelper.analyticsPolicies(project.id, contractorPackage.id)
          : "",
    });
  }

  children.push({
    children: analyticsChildren,
    childrenIds: [
      ...analyticsChildren.map((item) => item.id),
      "analytics-labour-subcontractors",
      "view-analytics-labour-hours",
      "view-analytics-labour-hours-employee",
      "package-analytics-local-content",
      "analytics-spend-subcontractors",
      "view-analytics-spend",
    ],
    icon: <ChartNoAxesCombined />,
    id: "analytics",
    title: "Analytics",
    type: "item",
    url:
      project && contractorPackage
        ? RouteHelper.analyticsLabourHoursLevel1(
            project?.id,
            contractorPackage?.id,
            currentDate,
          )
        : "",
  });

  return {
    children: children,
    icon: <TrackChanges sx={{ color: theme.palette.primary.main }} />,
    id: "overview",
    title: "Overview",
    type: "group",
  };
};
