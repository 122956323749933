import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import { CustomCellRendererProps } from "ag-grid-react";
import { useCallback } from "react";
import { ProfileProjectAccessLevel } from "social-pro-common/entities/profileProjectAccess";
import {
  ProfileProjectAccessLineItem,
  createDefaultProfileProjectAccessLineItem,
  profileProjectAccessLevelToString,
} from "social-pro-common/interfaces/profileProjectAccess";

interface ProjectAccessRendererProps extends CustomCellRendererProps {
  profileProjectAccess: ProfileProjectAccessLineItem[];
  handleUpdateAccess: (
    profileProjectAccess: ProfileProjectAccessLineItem,
    isNew: boolean,
  ) => void;
  selectedAccessLevel?: ProfileProjectAccessLevel;
  projectId: string;
}

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    paddingLeft: 0,
    paddingRight: "24px",
  },
  "&:focus": {
    background: "transparent",
  },
  background: "transparent",
  boxShadow: "none",
  fontSize: "inherit",
  height: "100%",
  maxWidth: "10vw",
  minWidth: "fit-content",
});

const ProjectAccessRenderer = ({
  data,
  handleUpdateAccess,
  profileProjectAccess,
  projectId,
  selectedAccessLevel,
}: ProjectAccessRendererProps) => {
  const ppa = profileProjectAccess.find(
    (p) => p.projectId === projectId && p.profileId === data.id,
  );

  const handleChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      if (ppa) {
        handleUpdateAccess(
          {
            ...ppa,
            accessLevel: event.target.value as ProfileProjectAccessLevel,
          },
          false,
        );
      } else {
        handleUpdateAccess(
          {
            ...createDefaultProfileProjectAccessLineItem(
              projectId,
              data.organisationId,
              data.id,
            ),
            accessLevel: event.target.value as ProfileProjectAccessLevel,
            created: new Date(),
          },
          true,
        );
      }
    },
    [handleUpdateAccess, ppa, projectId, data],
  );

  if (selectedAccessLevel && ppa?.accessLevel !== selectedAccessLevel) {
    return null;
  }

  return (
    <FormControl fullWidth>
      <StyledSelect
        value={ppa?.accessLevel || ProfileProjectAccessLevel.NoAccess}
        onChange={handleChange}
        variant="standard"
        disableUnderline
      >
        {Object.values(ProfileProjectAccessLevel).map((level) => (
          <MenuItem value={level} key={level}>
            {profileProjectAccessLevelToString(level)}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default ProjectAccessRenderer;
