import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackage } from "@hooks/crud/contractorPackages/useContractorPackages";
import { AnalyticsLocalContentLevel1Table } from "@stories/organisms/AnalyticsLocalContentTables/AnalyticsLocalContentLevel1Table";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import * as RouteHelper from "@utils/routes";
import { MapPinned } from "lucide-react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";

export const AnalyticsLocalContentLevel1 = () => {
  const navigate = useNavigate();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { contractorPackages, isContractorPackageLoading } =
    useContractorPackage(selectedProject?.id);

  const loading = isProjectLoading || isContractorPackageLoading;

  const sortedData = contractorPackages.sort((a, b) => {
    return a.packageNumber.localeCompare(b.packageNumber);
  });

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const viewSubContractorDetails = useCallback(
    (subPackage: ContractorPackageLineItem) => {
      console.log("View package details", subPackage);
      if (selectedProject && contractorPackage) {
        navigate(
          RouteHelper.analyticsLocalContentDetails(
            selectedProject.id,
            contractorPackage.id,
            subPackage.id,
          ),
        );
      }
    },
    [selectedProject, contractorPackage, navigate],
  );

  const breadcrumbData = [
    {
      href:
        selectedProject && contractorPackage
          ? RouteHelper.analyticsLocalContent(
              selectedProject.id,
              contractorPackage.id,
            )
          : "",
      label: "Local Content",
    },
  ];

  return (
    <PageContainer>
      <Header
        breadcrumbIcon={<MapPinned />}
        loading={loading}
        subTitle={isPrimaryPackage ? "Project Analytics" : "Package Analytics"}
        mainTitle={"Local Content"}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        breadcrumbs={breadcrumbData}
      />
      <AnalyticsLocalContentLevel1Table
        loading={loading}
        searchTerm={searchTerm}
        contractorPackages={sortedData}
        handleAction={viewSubContractorDetails}
      />
    </PageContainer>
  );
};
