import { Skeleton, TableCell, TableRow, TextField } from "@mui/material";

export const LabourHourTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell
        align="center"
        sx={{ paddingBottom: "7px", paddingTop: "7px" }}
      >
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell
        align="center"
        sx={{ paddingBottom: "7px", paddingTop: "7px" }}
      >
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell
        align="center"
        sx={{ paddingBottom: "7px", paddingTop: "7px" }}
      >
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell
        align="center"
        sx={{ paddingBottom: "7px", paddingTop: "7px" }}
      />
      <TableCell
        align="center"
        sx={{ paddingBottom: "7px", paddingTop: "7px" }}
      >
        <Skeleton animation="wave" variant="rounded" sx={{ margin: "auto" }}>
          <TextField className="search tableInput" />
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};
