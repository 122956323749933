import { Autocomplete, Grid, Skeleton, Stack, TextField } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { ProfileGroupTable } from "@stories/molecules/ProfileGroupTable/ProfileGroupTable";
import { useState } from "react";
import { ProfileProjectAccessLevel } from "social-pro-common/entities/profileProjectAccess";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import {
  ProfileProjectAccessLineItem,
  profileProjectAccessLevelToString,
} from "social-pro-common/interfaces/profileProjectAccess";
import { ProjectDetailLineItem } from "social-pro-common/interfaces/project";

interface ProfileGroupsProps {
  loading: boolean;
  searchTerm: string;
  projects: ProjectDetailLineItem[];
  profiles: ProfileLineItem[];
  profileProjectAccessLevels: ProfileProjectAccessLineItem[];
  isProfileProjectAccessLoading: boolean;
  updateProfileProjectAccess: (
    accessItem: ProfileProjectAccessLineItem,
  ) => void;
}

export const ProfileGroups = ({
  loading,
  profileProjectAccessLevels,
  profiles,
  projects,
  searchTerm,
  updateProfileProjectAccess,
}: ProfileGroupsProps) => {
  const [selectedProject, setSelectedProject] =
    useState<ProjectDetailLineItem | null>(null);

  const [selectedAccessLevel, setSelectedAccessLevel] =
    useState<ProfileProjectAccessLevel | null>(null);

  const isLoading = loading;

  const filteredProfiles = profiles.filter((profile) => !profile.isAdmin);

  return (
    <Grid item md={12}>
      <WhiteBox>
        <Stack direction="column" gap={2}>
          <Grid
            item
            md={12}
            justifyContent={"end"}
            sx={{ display: "flex", marginTop: "32px", paddingRight: "32px" }}
          >
            <Stack
              direction="row"
              spacing={2}
              justifyContent={"end"}
              width={"50%"}
            >
              {loading ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={40}
                  width={"100%"}
                >
                  <TextField sx={{ height: "40px" }} />
                </Skeleton>
              ) : (
                <>
                  <Autocomplete
                    fullWidth
                    id="project-select"
                    className="select"
                    sx={{ width: "25%" }}
                    options={projects}
                    value={selectedProject}
                    disabled={isLoading}
                    getOptionLabel={(option) => option.projectName}
                    onChange={(
                      event: any,
                      newValue: ProjectDetailLineItem | null,
                    ) => {
                      setSelectedProject(newValue || null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        data-test-id="project-select"
                        {...params}
                        name="entitproject-select"
                        label="Project"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </>
              )}
              {isLoading ? (
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  height={40}
                  width={"100%"}
                >
                  <TextField sx={{ height: "40px" }} />
                </Skeleton>
              ) : (
                <>
                  <Autocomplete
                    fullWidth
                    id="roles"
                    className="select"
                    sx={{ width: "25%" }}
                    options={Object.values(ProfileProjectAccessLevel)}
                    disabled={isLoading}
                    value={selectedAccessLevel}
                    getOptionLabel={(option) =>
                      profileProjectAccessLevelToString(option)
                    }
                    onChange={(
                      event: any,
                      newValue: ProfileProjectAccessLevel | null,
                    ) => {
                      setSelectedAccessLevel(newValue || null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        data-test-id="role-select"
                        {...params}
                        name="role-select"
                        label="Role"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </>
              )}
            </Stack>
          </Grid>
          <ProfileGroupTable
            loading={isLoading}
            profiles={filteredProfiles}
            projects={projects}
            searchTerm={searchTerm}
            selectedAccessLevel={selectedAccessLevel}
            selectedProject={selectedProject}
            profileProjectAccess={profileProjectAccessLevels}
            handleUpdateAccess={updateProfileProjectAccess}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
