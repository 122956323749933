import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import usePdf from "@hooks/crud/downloadFiles/usePdf";
import { useProjectReportSocialSubmission } from "@hooks/crud/projectReportSocialSubmission/useProjectReportSocialSubmission";
import { ProjectReportTable } from "@stories/molecules/ProjectReportTable/ProjectReportTable";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import * as RouteHelper from "@utils/routes";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { ProjectReportSubmissionLineItem } from "social-pro-common/interfaces/projectReportSubmission";
import { stringToDate } from "social-pro-common/utils/date";
import { getZipTitle } from "social-pro-common/utils/string";

export const ProjectSocialReports = () => {
  const navigate = useNavigate();

  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { downloadProgress, downloadStep, getPdfInfo } = usePdf();

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [searchTerm, setSearchTerm] = useState("");

  const { isProjectReportLoading, projectReportSubmissions } =
    useProjectReportSocialSubmission(contractorPackage?.id);

  const loading =
    isAuthProfileLoading || isProjectLoading || isProjectReportLoading;

  const orderedSubmissions = projectReportSubmissions.slice().reverse();

  const reportsToDisplay = (() => {
    switch (tabIndex) {
      default:
      case 0:
        return orderedSubmissions;
      case 1:
        return orderedSubmissions.filter(
          (r) => r.reportStatus === ReportStatus.Overdue,
        );
      case 2:
        return orderedSubmissions.filter(
          (r) => r.reportStatus === ReportStatus.Upcoming,
        );
      case 3:
        return orderedSubmissions.filter(
          (r) =>
            r.reportStatus === ReportStatus.Complete ||
            r.reportStatus === ReportStatus.Accepted,
        );
    }
  })();

  const handleDownloadReport = useCallback(
    async (reportSubmission: ProjectReportSubmissionLineItem) => {
      if (selectedProject && contractorPackage) {
        const reportDate = stringToDate(reportSubmission.reportId);
        const reportTitle = getZipTitle(reportDate);
        getPdfInfo(selectedProject.id, reportSubmission.reportId, reportTitle);
      }
    },
    [selectedProject, contractorPackage, getPdfInfo],
  );

  const handlViewReport = useCallback(
    async (reportSubmission: ProjectReportSubmissionLineItem) => {
      if (selectedProject && contractorPackage) {
        navigate(
          RouteHelper.projectSocialWizard(
            selectedProject.id,
            contractorPackage.id,
            reportSubmission.reportId,
          ),
        );
      }
    },
    [selectedProject, contractorPackage, navigate],
  );

  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const tabs = [
    {
      label: "All Reports",
    },
    {
      label: "Overdue",
    },
    {
      label: "Upcoming",
    },
    {
      label: "Submitted",
    },
  ];

  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={userOrganisation?.organisationName}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        mainTitle={"Social Project Reports"}
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
      />
      <ProjectReportTable
        searchTerm={searchTerm}
        loading={loading}
        project={selectedProject}
        reportSubmissions={reportsToDisplay}
        downloadProgress={downloadProgress}
        downloadStep={downloadStep}
        handleEditReport={handlViewReport}
        handlViewReport={handlViewReport}
        handleDownloadReport={handleDownloadReport}
      />
    </PageContainer>
  );
};
