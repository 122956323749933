import {
  EmployeeSocialFactor,
  EmploymentLevel,
  Gender,
  OccupationType,
} from "social-pro-common/entities/contractorEmployee";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { isValidPostCode } from "social-pro-common/interfaces/projectSocialSpendCommitment";

import { getGeoDistance } from "./location";

export const isValidDistance = (
  distance?: number,
  lat1?: number,
  lon1?: number,
  lat2?: number,
  lon2?: number,
) => {
  if (distance && lat1 && lon1 && lat2 && lon2) {
    return distance >= getGeoDistance(lat1, lon1, lat2, lon2);
  }
  return true;
};

export const isValidGender = (employeeGender: Gender, genders?: Gender[]) => {
  if (genders && genders.length > 0) {
    return genders.includes(employeeGender);
  }
  return true;
};

export const isValidSocialFactor = (
  employeeSocialFactors: EmployeeSocialFactor[],
  socialFactors?: EmployeeSocialFactor[],
) => {
  if (socialFactors && socialFactors.length > 0) {
    return (
      employeeSocialFactors.filter((x) => socialFactors.includes(x)).length > 0
    );
  }
  return true;
};

export const isValidOccupationType = (
  employeeOccupationType: OccupationType,
  occupationTypes?: OccupationType[],
) => {
  if (occupationTypes && occupationTypes.length > 0) {
    return occupationTypes.includes(employeeOccupationType);
  }
  return true;
};

export const isValidEmploymentLevel = (
  employeeEmploymentType: EmploymentLevel,
  employmentType?: EmploymentLevel[],
) => {
  if (employmentType && employmentType.length > 0) {
    return employmentType.includes(employeeEmploymentType);
  }
  return true;
};

export const isValidABN = (socialSpendABN: string, abns?: string[]) => {
  if (abns && abns.length > 0) {
    return abns.includes(socialSpendABN);
  }
  return true;
};

export const getEmployeeFactors = (
  employee: EmployeeLineItem,
  project?: ProjectLineItem,
) => {
  if (project) {
    return project.commitmentsHours.filter((c) => {
      return (
        isValidDistance(
          c.maxDistance,
          employee.geoLocationLat,
          employee.geoLocationLong,
          project.projectAddress.geoLocationLat,
          project.projectAddress.geoLocationLong,
        ) &&
        isValidGender(employee.gender, c.gender) &&
        isValidSocialFactor(employee.socialFactors, c.socialFactors) &&
        isValidOccupationType(employee.occupationType, c.occupationType) &&
        isValidEmploymentLevel(employee.employmentLevel, c.employmentLevel) &&
        isValidABN(employee.employerAbn, c.abns) &&
        isValidPostCode(employee.postCode, c.postCodes)
      );
    });
  }
  return [];
};
