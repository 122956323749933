import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { Box, Button, Grid, Typography } from "@mui/material";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  CellAction,
  getHeaderAndCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { getEmployeeFactors } from "@utils/employeSocialFactors";
import * as RouteHelper from "@utils/routes";
import { ColDef, ColGroupDef, ValueFormatterParams } from "ag-grid-community";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EmployeeLineItem,
  occupationToString,
  occupationTypeOptionToString,
} from "social-pro-common/interfaces/contractorEmployee";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

import {
  EmployeeSocialFactorsCell,
  ToggleActionCell,
} from "./ProjectEmployeeTableCells";
import { ProjectEmployeeTableRowSkeleton } from "./ProjectEmployeeTableRowSkeleton";

interface TableContextProps {
  project: ProjectLineItem;
  cellActions: CellAction[];
  handleOpenListModal: (employee: EmployeeLineItem) => void;
  handleEmployeeAssignment: (
    employee: EmployeeLineItem,
    toggle: boolean,
  ) => Promise<void>;
}
interface ProjectEmployeeTableProps {
  loading: boolean;
  project?: ProjectLineItem;
  employees: EmployeeLineItem[];
  employeeCount: number;
  searchTerm: string;
  handleOpenListModal: (employee: EmployeeLineItem) => void;
  handleEmployeeAssignment: (
    employee: EmployeeLineItem,
    toggle: boolean,
  ) => Promise<void>;
}

export const ProjectEmployeeTable = ({
  employeeCount,
  employees,
  handleEmployeeAssignment,
  handleOpenListModal,
  loading,
  project,
  searchTerm,
}: ProjectEmployeeTableProps) => {
  const navigate = useNavigate();
  const cellActions = [
    {
      action: handleOpenListModal,
      adminOnly: true,
      singleAction: true,
      title: "View",
    },
  ] as CellAction[];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...getHeaderAndCellStyles(),
      field: "employeeName",
      flex: 2,
      headerName: "Name or Code",
      onCellClicked: (params) => handleOpenListModal(params.data),
      valueFormatter: (params) =>
        `${params.data.employeeName}${
          params.data.employeeIdentifier
            ? ` (${params.data.employeeIdentifier})`
            : ""
        }`,
    },
    {
      ...getHeaderAndCellStyles(),
      field: "occupation",
      flex: 2,
      headerName: "Occupation",
      onCellClicked: (params) => handleOpenListModal(params.data),
      valueFormatter: (params) =>
        `${occupationToString(params.data.occupation)}`,
    },
    {
      ...getHeaderAndCellStyles(),
      field: "occupationType",
      flex: 1.5,
      headerName: "Occupation Type",
      onCellClicked: (params) => handleOpenListModal(params.data),
      valueFormatter: (params) =>
        `${occupationTypeOptionToString(params.data.occupationType)}`,
    },
    {
      ...getHeaderAndCellStyles("center"),
      cellRenderer: (params: ValueFormatterParams<any, any>) => {
        const socialFactors = getEmployeeFactors(
          params.data,
          params.context.project,
        );

        return <EmployeeSocialFactorsCell factors={socialFactors} />;
      },
      field: "socialFactors",
      flex: 2,
      headerName: "Social Factors",
      onCellClicked: (params) => handleOpenListModal(params.data),
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ToggleActionCell,
      field: "toggle",
      flex: 1.5,
      headerName: "Assign To Project",
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCell,
      cellRendererParams: {
        text: "View",
      },
      field: "",
      headerName: "",
    },
  ]);

  return (
    <Grid item xs={12} md={12}>
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={ProjectEmployeeTableRowSkeleton}
        />
      ) : null}

      {!loading && employeeCount >= 0 ? (
        <Table<EmployeeLineItem>
          columnDefs={colDefs}
          loading={loading}
          data={employees || []}
          context={
            {
              cellActions,
              handleEmployeeAssignment,
              handleOpenListModal,
              project,
            } as TableContextProps
          }
          searchValue={searchTerm}
          searchKeys={[
            "employeeIdentifier",
            "employeeName",
            "occupation",
            "occupationType",
            "socialFactors",
          ]}
        />
      ) : null}

      {!loading && employeeCount === -1 ? (
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            alignItems: "center",
            background: "#E9F0F5",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            margin: "0 40px",
            minHeight: "400px",
            padding: "5px 0 !important",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              justifyContent: "center",
            }}
          >
            <GroupsOutlinedIcon
              sx={{ fontSize: "100px", height: "100px", width: "100px" }}
            />
            <Typography
              variant="h4"
              style={{ color: "#87959F", fontSize: "18px", fontWeight: "400" }}
            >
              Add an employee
            </Typography>
            <Button
              className="blackBtn"
              variant="contained"
              color="primary"
              onClick={() => navigate(RouteHelper.employees)}
            >
              Manage Employees
            </Button>
          </Box>
        </Grid>
      ) : null}
    </Grid>
  );
};
