import { useProjectContext } from "@hooks/context/useProjectContext";
import { useSocialSpendAnalyticsLevel3 } from "@hooks/crud/analytics/useSocialSpendAnalyticsLevel3";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { AnalyticsSpendLevel3Table } from "@stories/organisms/AnalyticsSpendTables/AnalyticsSpendLevel3Table/AnalyticsSpendLevel3Table";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import SocialSpendAnalyticsSummaryLevel3 from "@stories/organisms/Summary/SocialSpendAnalyticsSummaryLevel3";
import * as RouteHelper from "@utils/routes";
import { writeXlsxSocialSpendTargetsSummary } from "@utils/xlsx/spend";
import { ExportIcon } from "assets/constants";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";

export const AnalyticsSpendLevel3 = () => {
  const { mode, projectId, reportId, subPackageId, targetId } = useParams();
  if (!targetId) {
    throw Error("No target id");
  }
  if (!reportId) {
    throw Error("No report id");
  }
  if (!projectId) {
    throw Error("No project id");
  }
  if (!subPackageId) {
    throw Error("No contractor package id");
  }

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, socialSpendAnalytics } =
    useSocialSpendAnalyticsLevel3(
      projectId,
      subPackageId,
      reportId,
      targetId,
      mode,
    );

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAnalytics, setFilteredAnalytics] = useState<
    SocialSpendLineItem[]
  >([]);

  const handleExportSpend = async () => {
    if (filteredAnalytics?.length > 0) {
      writeXlsxSocialSpendTargetsSummary(filteredAnalytics);
    }
  };
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const loading = isProjectLoading || isAnalysisLoading;

  const selectedTarget = selectedProject?.commitmentsSpend.find(
    (c) => c.id === targetId,
  );
  const matchingTarget = selectedProject?.commitmentsSpend.find(
    (target) => target.id === targetId,
  );

  const displayText =
    mode == "all"
      ? "All"
      : socialSpendAnalytics?.subContractor.organisationName || "Subcontractor";

  const breadcrumbData = [
    {
      href: RouteHelper.analyticsSpendLevel1(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        reportId,
      ),
      label: "Social Spend",
    },
  ];

  if (isPrimaryPackage) {
    breadcrumbData.push({
      href: RouteHelper.analyticsSpendLevel2(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        targetId || "",
        reportId,
      ),
      label: matchingTarget
        ? matchingTarget.targetDescription ||
          socialSpendCommitmentTypeToString(matchingTarget.targetName)
        : "",
    });
  }
  breadcrumbData.push({
    href: RouteHelper.analyticsSpendLevel3(
      selectedProject?.id || "",
      contractorPackage?.id || "",
      subPackageId || "",
      reportId,
      targetId,
      "package",
    ),
    label: displayText,
  });

  const buttons: HeaderButtonConfig[] = [
    {
      action: handleExportSpend,
      icon: <ExportIcon />,
      text: "Export Targets",
      type: HeaderButtonType.SECONDARY,
      width: 150,
    },
  ];
  return (
    <PageContainer>
      <Header
        breadcrumbIcon={<AttachMoneyIcon />}
        breadcrumbs={breadcrumbData}
        loading={loading}
        subTitle={
          isPrimaryPackage
            ? `Project Analytics for ${selectedProject?.projectName}`
            : `Package Analytics for ${selectedProject?.projectName}`
        }
        mainTitle={`Social Spend - ${
          selectedTarget
            ? selectedTarget.targetDescription ||
              socialSpendCommitmentTypeToString(selectedTarget.targetName)
            : ""
        }`}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
      />

      <SocialSpendAnalyticsSummaryLevel3
        summary={socialSpendAnalytics?.summary}
        loading={loading}
      />
      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <WhiteBox>
          <Stack direction="column" gap={2}>
            <Header
              loading={loading}
              mainTitle="Target Summary"
              subTitle="Level 3 Analytics"
              isSubheading
              headerFontSizes={[18, 14]}
              headerGap={0}
            />

            <AnalyticsSpendLevel3Table
              loading={loading}
              searchTerm={searchTerm}
              socialSpendAnalytics={socialSpendAnalytics}
              onFilteredDataChange={setFilteredAnalytics}
            />
          </Stack>
        </WhiteBox>
      </Grid>
    </PageContainer>
  );
};
