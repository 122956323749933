import { TableCell, TableRow, Typography } from "@mui/material";

interface NoResultsFoundRowProps {
  colSpan: number;
}
export const NoResultsFoundRow = ({ colSpan }: NoResultsFoundRowProps) => {
  return (
    <TableRow>
      <TableCell
        align="center"
        colSpan={colSpan - 1}
        sx={{ borderRadius: "12px", textAlign: "center !important" }}
      >
        <Typography variant="h5" fontWeight={400}>
          No Rows To Show
        </Typography>
      </TableCell>
    </TableRow>
  );
};
