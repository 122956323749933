import { Grid } from "@mui/material";
import {
  filterByKeys,
  generateTableHead,
  Table,
} from "@stories/organisms/Table/Table";
import {
  ActionCell,
  CellAction,
  getHeaderAndCellStyles,
  ProgressCell,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import {
  CellClickedEvent,
  ColDef,
  ColGroupDef,
  ValueFormatterParams,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { ViewProjectIcon } from "assets/constants";
import { useEffect, useMemo, useState } from "react";
import {
  AnalyticsSocialSpendLevel1PageModelLineItem,
  SocialSpendTargetAnalyticsLineItem,
} from "social-pro-common/interfaces/analytics";
import { socialSpendCommitmentTypeToString } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { AnalyticsSpendLevel1TableRowSkeleton } from "./AnalyticsSpendLevel1TableRowSkeleton";

interface AnalyticsSpendLevel1TableProps {
  loading: boolean;
  searchTerm: string;
  socialSpendAnalysis?: AnalyticsSocialSpendLevel1PageModelLineItem;
  handleAction: (target: SocialSpendTargetAnalyticsLineItem) => void;
  onFilteredDataChange: (
    filteredData: SocialSpendTargetAnalyticsLineItem[],
  ) => void;
  isPrimaryPackage: boolean;
}

const searchKeys: (keyof SocialSpendTargetAnalyticsLineItem)[] = [
  "targetType",
  "targetDescription",
];

export const AnalyticsSpendLevel1Table = ({
  handleAction,
  isPrimaryPackage,
  loading,
  onFilteredDataChange,
  searchTerm,
  socialSpendAnalysis,
}: AnalyticsSpendLevel1TableProps) => {
  const cellActions = [
    {
      action: handleAction,
      adminOnly: true,
      icon: <ViewProjectIcon />,
      singleAction: true,
      title: "View",
    },
  ] as CellAction[];

  const initialColDefs: ColDef[] = [
    {
      ...getHeaderAndCellStyles(),
      field: "targetType",
      flex: 3,
      headerName: "Target Type",
      onCellClicked: (params) => handleAction(params.data),
      valueGetter: (params: any) => {
        const description = params.data.targetDescription;
        const type = params.data.targetType
          ? socialSpendCommitmentTypeToString(params.data.targetType)
          : "";
        return description || type; // Prefer description, fallback to type
      },
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "targetValue",
      headerName: "Target (Spend)",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    ...(isPrimaryPackage
      ? [
          {
            ...getHeaderAndCellStyles("right"),
            field: "committedSpend",
            flex: 1.5,
            headerName: "Committed (Spend)",
            onCellClicked: (params: CellClickedEvent<any, any>) =>
              handleAction(params.data),
            valueFormatter: (params: ValueFormatterParams<any, any>) =>
              `$${formatDecimalPlaces(params.value, 0)}`,
          },
        ]
      : []),
    ...(isPrimaryPackage
      ? [
          {
            ...getHeaderAndCellStyles("right"),
            field: "uncommittedSpend",
            flex: 1.5,
            headerName: "Uncommitted (Spend)",
            onCellClicked: (params: CellClickedEvent<any, any>) =>
              handleAction(params.data),
            valueFormatter: (params: ValueFormatterParams<any, any>) =>
              `$${formatDecimalPlaces(params.value, 0)}`,
          },
        ]
      : []),
    {
      ...getHeaderAndCellStyles("right"),
      field: "totalReportedValue",
      headerName: "Actual (Spend)",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "monthReportValue",
      headerName: "This Month",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right"),
      cellRenderer: ProgressCell,
      field: "progress",
      flex: 2,
      headerName: "Progress to Target",
      onCellClicked: (params) => handleAction(params.data),
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCell,
      cellRendererParams: {
        text: "View",
      },
      field: "",
      headerName: "",
    },
  ];

  const [colDefs, _setColDefs] =
    useState<(ColDef | ColGroupDef)[]>(initialColDefs);

  useEffect(() => {
    _setColDefs(initialColDefs);
  }, [isPrimaryPackage]);

  const sortedTargets =
    socialSpendAnalysis?.targets.sort((a, b) => {
      return (a.targetDescription || a.targetType).localeCompare(
        b.targetDescription || b.targetType,
      );
    }) || [];

  const filteredData = useMemo(() => {
    const searchFilter = filterByKeys(sortedTargets, searchKeys, colDefs);
    return searchFilter(searchTerm);
  }, [sortedTargets, searchTerm]);

  useEffect(() => {
    onFilteredDataChange(filteredData);
  }, [filteredData, onFilteredDataChange]);

  return (
    <Grid item md={12}>
      {loading || !sortedTargets ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          noFullTableBorder
          rows={() => <AnalyticsSpendLevel1TableRowSkeleton singleAction />}
        />
      ) : (
        <Table<SocialSpendTargetAnalyticsLineItem>
          columnDefs={colDefs}
          loading={loading}
          data={sortedTargets}
          searchValue={searchTerm}
          noFullTableBorder
          context={{
            cellActions,
          }}
          searchKeys={searchKeys}
        />
      )}
    </Grid>
  );
};
