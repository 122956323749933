import { Skeleton, Stack, TableCell, TableRow } from "@mui/material";

const InputSkeletonCell = () => {
  return (
    <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }}>
      <Stack direction="row" justifyContent={"center"}>
        <Skeleton animation="wave" width={120} height={50} />
      </Stack>
    </TableCell>
  );
};
export const PackageSocialRequirementTableRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell
        sx={{ minWidth: "10vw", paddingBottom: "2px", paddingTop: "2px" }}
      >
        <Skeleton animation="wave" width={300} />
      </TableCell>
      <InputSkeletonCell />
      <InputSkeletonCell />
    </TableRow>
  );
};
