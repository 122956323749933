import { usePackageLocalContent } from "@hooks/crud/packageLocalContent/usePackageLocalContent";
import { downloadFile, uploadFile } from "@hooks/utils/useUpload";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { AnalyticsLocalContentLevel2Table } from "@stories/organisms/AnalyticsLocalContentTables/AnalyticsLocalContentLevel2Table";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import LidpModal from "@stories/organisms/LidpModal/LidpModal";
import { catchSentryError } from "@utils/sentry";
import { AddIcon, ExportIcon, ImportIcon } from "assets/constants";
import { CloudUploadIcon } from "lucide-react";
import { ChangeEvent, FocusEvent, useCallback, useRef, useState } from "react";
import { toast, ToastOptions } from "react-toastify";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { PackageCommentType } from "social-pro-common/entities/packageComment";
import { ReportDocumentType } from "social-pro-common/entities/reportDocument";
import { packageCommentTypeToString } from "social-pro-common/interfaces/packageComment";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  createDefaultReportDocument,
  ReportDocumentLineItem,
} from "social-pro-common/interfaces/reportDocument";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";
import { getFileName } from "social-pro-common/utils/string";

import LidpImport, { ImportStep } from "../LidpImport/LidpImport";

interface PackageLidpSubmissionFormProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  isPrimaryPackage: boolean;
  isSubmitting: boolean;
  contractorPackageId?: string;
  reportId: string;
  commentMap: Map<PackageCommentType, string>;
  readOnly?: boolean;
  packageValue: number;
  packageType: PackageType;
  updateComment: (
    commentType: PackageCommentType,
    comment: string,
    save: boolean,
  ) => Promise<void>;
  handleOnBack?: () => Promise<void>;
  createReportDocument: (
    reportDocument: ReportDocumentLineItem,
  ) => Promise<void>;
  updateReportDocument: (
    reportDocument: ReportDocumentLineItem,
  ) => Promise<void>;
  reportDocuments: ReportDocumentLineItem[];
}

export const PackageLidpSubmissionForm = ({
  commentMap,
  contractorPackageId,
  createReportDocument,
  handleOnBack,
  isLoading,
  isPrimaryPackage,
  isSubmitting,
  packageValue,
  project,
  readOnly,
  reportDocuments,
  reportId,
  updateComment,
  updateReportDocument,
}: PackageLidpSubmissionFormProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const onFileInput = async () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [_uploadProgress, setUploadProgress] = useState(0);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [step, setStep] = useState<ImportStep>(ImportStep.Init);

  const handleOpenImportModal = () => {
    setOpenImportModal(true);
  };
  const [selectedLocalContent, setSelectedLocalContent] = useState<
    PackageLocalContentLineItem | undefined
  >();
  const {
    createPackageLocalContent,
    deletePackageLocalContents,
    isPackageLocalContentLoading,
    packageLocalContents,
    updatePackageLocalContent,
  } = usePackageLocalContent(contractorPackageId, reportId);

  const upsertLocalContent = async (
    lc: PackageLocalContentLineItem[],
  ): Promise<void> => {
    const updatedData = [...packageLocalContents, ...lc];
    await createPackageLocalContent(updatedData);
  };

  const handleCreatePackageLocalContent = useCallback(
    async (localContent: PackageLocalContentLineItem) => {
      await createPackageLocalContent(localContent);
    },
    [packageLocalContents],
  );

  const handleUpdatePackageLocalContent = useCallback(
    async (localContent: PackageLocalContentLineItem) => {
      await updatePackageLocalContent(localContent);
    },
    [packageLocalContents],
  );

  const handleDeleteLocalContent = useCallback(
    async (localContent: PackageLocalContentLineItem) => {
      await deletePackageLocalContents([localContent]);
    },
    [packageLocalContents],
  );

  const handleEditLocalContent = useCallback(
    async (localContent: PackageLocalContentLineItem) => {
      setSelectedLocalContent(localContent);
      setOpen(true);
    },
    [packageLocalContents],
  );

  const handleClose = () => {
    setSelectedLocalContent(undefined);
    setOpen(false);
  };

  const handleOpen = async () => {
    setOpen(true);
  };

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const onDownload = useCallback(async () => {
    setIsDownloading(true);
    const localContentReportDocument = reportDocuments.find(
      (d) => d.reportDocumentType === ReportDocumentType.LabourHour,
    );
    if (localContentReportDocument) {
      await downloadFile(
        localContentReportDocument.uri,
        getFileName(localContentReportDocument.uri),
      );
    }
    setIsDownloading(false);
  }, [reportDocuments]);

  const onUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setUploadProgress(0);
      if (
        e.currentTarget?.files &&
        e.currentTarget?.files.length > 0 &&
        contractorPackageId
      ) {
        const file = e.currentTarget?.files[0] || null;
        if (file) {
          setIsUploading(true);

          const fileKey = `supporting-documents/${contractorPackageId}/${reportId}/${file.name}`;
          await uploadFile(
            fileKey,
            file,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
              if (total === 100) {
                toast("Upload complete!", {
                  type: "success",
                } as ToastOptions);
              }
            },
            (error: any) => {
              setUploadProgress(0);
              catchSentryError(error);
              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
              setIsUploading(false);
            },
          );

          if (localContentReportDocument) {
            await updateReportDocument({
              ...localContentReportDocument,
              uri: fileKey,
            });
          } else {
            await createReportDocument(
              createDefaultReportDocument(
                contractorPackageId,
                reportId,
                fileKey,
                ReportDocumentType.LocalContent,
              ),
            );
          }
          // const urlToImage = URL.createObjectURL(file);
          setIsUploading(false);
        }
      }
    },
    [
      contractorPackageId,
      createReportDocument,
      reportId,
      reportDocuments,
      updateReportDocument,
    ],
  );

  const commentValue = commentMap.get(PackageCommentType.LocalContent) || "";

  const loading = isLoading || isPackageLocalContentLoading;

  const tabs = [{ label: "Actual" }, { label: "Expected" }];

  const buttons = [
    {
      action: handleOpenImportModal,
      icon: <CloudUploadIcon size={20} />,
      text: "Import Local Content",
      type: HeaderButtonType.SECONDARY,
      width: 200,
    },
    {
      action: handleOpen,
      icon: <AddIcon variant="secondary" />,
      text: "Add Local Content",
      type: HeaderButtonType.PRIMARY,
      width: 180,
    },
  ] as HeaderButtonConfig[];

  const localContentReportDocument = reportDocuments.find(
    (d) => d.reportDocumentType === ReportDocumentType.LocalContent,
  );

  const supportingDocumentsButtons: HeaderButtonConfig[] = [];

  if (!localContentReportDocument) {
    supportingDocumentsButtons.push({
      action: onFileInput,
      icon: <ImportIcon />,
      text: "Supporting Document",
      type: HeaderButtonType.SECONDARY,
    });
  } else {
    supportingDocumentsButtons.push({
      action: onDownload,
      icon: <ExportIcon />,
      text: "Download",
      type: HeaderButtonType.SECONDARY,
    });
    supportingDocumentsButtons.push({
      action: onFileInput,
      icon: <ImportIcon />,
      text: "Reupload",
      type: HeaderButtonType.SECONDARY,
    });
  }

  const submitting = isSubmitting || isUploading || isDownloading;

  return (
    <>
      <Header
        loading={loading}
        subTitle={`${project?.projectName}: ${
          reportId ? formatReportDate(stringToDate(reportId)) : ""
        }`}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
        mainTitle={"Local Content Reporting"}
        tabs={tabs}
        handleBack={handleOnBack}
        headerFontSizes={[18, 14]}
        headerGap={0}
      />

      <AnalyticsLocalContentLevel2Table
        loading={loading}
        isPrimaryPackage={isPrimaryPackage}
        localContents={packageLocalContents}
        tabIndex={1} //Show actual reported not aggregated
        searchTerm={searchTerm}
        showActions
        handleEditLocalContent={handleEditLocalContent}
        handleDeleteLocalContent={handleDeleteLocalContent}
        marginTop={4}
      />
      <input
        ref={fileInput}
        hidden
        id="fileUploadInput"
        accept="*"
        type="file"
        style={{
          cursor: "pointer",
          height: "100px",
          opacity: 0,
          padding: 0.5,
          position: "fixed",
          width: "100px",
          zIndex: 1,
        }}
        onChange={onUpload}
      />
      <Grid item xs={12} md={12}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"flex-start"}
          alignItems="center"
          mt={2}
        >
          {loading ? (
            <Skeleton animation="wave" width={200} height={40}>
              <Typography sx={{ fontWeight: "bold" }}>
                * Please ensure the total value of local content items is equal
                to the value of monthly progress claims
              </Typography>
            </Skeleton>
          ) : (
            <Typography sx={{ fontWeight: "bold" }}>
              * Please ensure the total value of local content items is equal to
              the value of monthly progress claims
            </Typography>
          )}
        </Stack>
      </Grid>
      <Header
        loading={loading}
        mainTitle=""
        subTitle={"Supporting Information"}
        buttons={supportingDocumentsButtons}
      />
      <Grid item xs={12} md={12} mt={2}>
        <StyledTextField
          data-test-id="localContent-comment-input"
          loading={loading}
          disabled={loading || submitting || readOnly}
          id={`${packageCommentTypeToString(PackageCommentType.LocalContent)}`}
          name={`${packageCommentTypeToString(
            PackageCommentType.LocalContent,
          )}`}
          label={`${packageCommentTypeToString(
            PackageCommentType.LocalContent,
          )} Comments`}
          fullWidth
          rows={5}
          maxLength={1000}
          multiline
          margin={"dense"}
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            await updateComment(
              PackageCommentType.LocalContent,
              e.target.value,
              false,
            );
          }}
          onBlur={async (e: FocusEvent<HTMLInputElement>) => {
            await updateComment(
              PackageCommentType.LocalContent,
              e.target.value,
              true,
            );
          }}
          value={commentValue}
          className="textareaBlack"
        />
      </Grid>
      <LidpImport
        open={openImportModal}
        step={step}
        project={project}
        setIsOpen={(open: boolean) => setOpenImportModal(open)}
        setStep={setStep}
        reportId={reportId}
        contractorPackageId={contractorPackageId}
        upsertLidp={upsertLocalContent}
      />

      {project && contractorPackageId && open ? (
        <LidpModal
          open={open}
          loading={loading}
          projectId={project.id}
          packageId={contractorPackageId}
          isCreateModal={false}
          reportId={reportId}
          isPrimaryPackage={isPrimaryPackage}
          packageValue={packageValue}
          existingLocalContent={selectedLocalContent}
          createLocalContent={handleCreatePackageLocalContent}
          updateLocalContent={handleUpdatePackageLocalContent}
          handleClose={handleClose}
        />
      ) : null}
    </>
  );
};
