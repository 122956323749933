import {
  filterByKeys,
  generateTableHead,
  Table,
} from "@stories/organisms/Table/Table";
import {
  ActionCell,
  CellAction,
  getHeaderAndCellStyles,
  ProgressCell,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import {
  CellClickedEvent,
  ColDef,
  ValueFormatterParams,
} from "ag-grid-community";
import { ViewProjectIcon } from "assets/constants";
import { useEffect, useMemo, useState } from "react";
import {
  LabourHoursAnalyticsModelLineItem,
  LabourHourTargetAnalyticsLineItem,
} from "social-pro-common/interfaces/analytics";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AnalyticsHoursLevel1TableRowSkeleton } from "./AnalyticsHoursLevel1TableRowSkeleton";

interface AnalyticsHoursLevel1TableProps {
  loading: boolean;
  labourHourAnalytics?: LabourHoursAnalyticsModelLineItem;
  searchTerm: string;
  handleAction: (target: LabourHourTargetAnalyticsLineItem) => void;
  onFilteredDataChange: (
    filteredData: LabourHourTargetAnalyticsLineItem[],
  ) => void;
  isPrimaryPackage: boolean;
}

const searchKeys: (keyof LabourHourTargetAnalyticsLineItem)[] = [
  "targetType",
  "targetDescription",
];

export const AnalyticsHoursLevel1Table = ({
  handleAction,
  isPrimaryPackage,
  labourHourAnalytics,
  loading,
  onFilteredDataChange,
  searchTerm,
}: AnalyticsHoursLevel1TableProps) => {
  const cellActions = [
    {
      action: handleAction,
      adminOnly: true,
      icon: <ViewProjectIcon />,
      singleAction: true,
      title: "View",
    },
  ] as CellAction[];

  const initialColDefs: ColDef[] = [
    {
      ...getHeaderAndCellStyles(),
      field: "targetType",
      flex: 2.5,
      headerName: "Target Type",
      onCellClicked: (params) => handleAction(params.data),
      valueGetter: (params: any) => {
        const description = params.data.targetDescription;
        const type = params.data.targetType
          ? labourHourCommitmentTypeToString(params.data.targetType)
          : "";
        return description || type; // Prefer description, fallback to type
      },
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "targetValue",
      flex: 1.5,
      headerName: "Target (Hours)",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    ...(isPrimaryPackage
      ? [
          {
            ...getHeaderAndCellStyles("right"),
            field: "committedHours",
            flex: 1.5,
            headerName: "Committed (Hours)",
            onCellClicked: (params: CellClickedEvent<any, any>) =>
              handleAction(params.data),
            valueFormatter: (params: ValueFormatterParams<any, any>) =>
              `${formatDecimalPlaces(params.value, 0)}`,
          },
        ]
      : []),
    ...(isPrimaryPackage
      ? [
          {
            ...getHeaderAndCellStyles("right"),
            field: "uncommittedHours",
            flex: 1.5,
            headerName: "Uncommitted (Hours)",
            onCellClicked: (params: CellClickedEvent<any, any>) =>
              handleAction(params.data),
            valueFormatter: (params: ValueFormatterParams<any, any>) =>
              `${formatDecimalPlaces(params.value, 0)}`,
          },
        ]
      : []),
    {
      ...getHeaderAndCellStyles("right"),
      field: "totalReportedValue",
      flex: 1.5,
      headerName: "Actual (Hours)",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "monthReportedValue",
      flex: 1.5,
      headerName: "This Month",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "actual",
      flex: 1.5,
      headerName: "% of Total Hours",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 1)}%`,
    },
    {
      ...getHeaderAndCellStyles("right"),
      cellRenderer: ProgressCell,
      field: "progress",
      flex: 2,
      headerName: "Progress to Target",
      onCellClicked: (params) => handleAction(params.data),
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCell,
      cellRendererParams: {
        text: "View",
      },
      field: "",
      headerName: "",
    },
  ];
  const [colDefs, _setColDefs] = useState<ColDef[]>(initialColDefs);
  useEffect(() => {
    _setColDefs(initialColDefs);
  }, [isPrimaryPackage]);

  const targetInfo = labourHourAnalytics?.targets || [];
  targetInfo.sort((a, b) => {
    const aType =
      a.targetDescription || labourHourCommitmentTypeToString(a.targetType);
    const bType =
      b.targetDescription || labourHourCommitmentTypeToString(b.targetType);
    return aType.localeCompare(bType);
  });

  const filteredData = useMemo(() => {
    const searchFilter = filterByKeys(targetInfo, searchKeys, colDefs);
    return searchFilter(searchTerm);
  }, [targetInfo, searchTerm]);

  useEffect(() => {
    onFilteredDataChange(filteredData);
  }, [filteredData, onFilteredDataChange]);

  return loading || !targetInfo ? (
    <TableSkeleton
      tableHead={generateTableHead(initialColDefs)}
      noFullTableBorder
      rows={() => <AnalyticsHoursLevel1TableRowSkeleton singleAction />}
    />
  ) : (
    <Table<LabourHourTargetAnalyticsLineItem>
      columnDefs={colDefs}
      loading={loading}
      data={targetInfo}
      context={{ cellActions }}
      searchValue={searchTerm}
      searchKeys={searchKeys}
      noFullTableBorder
    />
  );
};
