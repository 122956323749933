import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { Link } from "react-router-dom";

interface BreadcrumbProps {
  data: BreadcrumbData[];
  loading: boolean;
  icon?: React.ReactNode;
}

interface BreadcrumbSkeletonProps {
  length: number;
  icon?: React.ReactNode;
}

export interface BreadcrumbData {
  label: string;
  href?: string;
  action?: () => void;
  forceIcon?: boolean;
  disabledStyle?: boolean;
}

export function BreadcrumbsSkeleton(props: BreadcrumbSkeletonProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {props.icon ? <Skeleton variant="circular" width={20} /> : null}
      <Skeleton variant="text" width={80} />
      {props.length > 1 ? (
        <>
          <Typography variant="body2" color="textSecondary">
            /
          </Typography>
          <Skeleton variant="text" width={100} />
        </>
      ) : null}
    </Stack>
  );
}

export function Breadcrumbs(props: BreadcrumbProps) {
  if (props.loading) {
    return (
      <Grid
        item
        md={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <BreadcrumbsSkeleton length={props.data.length} icon={props.icon} />
      </Grid>
    );
  }
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement> | null) => {
    if (event) {
      setAnchorEl(event.currentTarget);
    }
  };

  if (props.data.length === 0) {
    return null;
  }
  const [first, ...rest] = props.data;
  const last = rest.pop();
  if (last) {
    last.disabledStyle = true;
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderBreadcrumbItem = ({
    action,
    disabledStyle,
    href,
    label,
  }: BreadcrumbData) => {
    if (action) {
      return (
        <Typography
          key={label}
          data-test-id="breadcrumb-item"
          fontWeight={disabledStyle ? "bold" : "normal"}
          sx={{
            "&:hover": { textDecoration: "none" },
            color: "textPrimary",
            cursor: `${disabledStyle ? "default" : "pointer"}`,
          }}
          onClick={action}
        >
          {label}
        </Typography>
      );
    }

    if (href) {
      return (
        <Link
          data-test-id="breadcrumb-item"
          key={label}
          color="textPrimary"
          to={href}
          style={{
            color: "inherit",
            cursor: `${disabledStyle ? "default" : "pointer"}`,
            fontWeight: `${disabledStyle ? "bold" : "normal"}`,
            textDecoration: "none",
          }}
        >
          {label}
        </Link>
      );
    }

    return (
      <Typography
        key={label}
        color="textSecondary"
        fontWeight={disabledStyle ? "bold" : "normal"}
        sx={{
          cursor: `${disabledStyle ? "default" : "pointer"}`,
        }}
      >
        {label}
      </Typography>
    );
  };

  return (
    <Grid
      item
      md={12}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
      data-test-id="breadcrumb-group"
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Box sx={{ transform: "scale(0.8)" }}>{props.icon || null}</Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          aria-labelledby="breadcrumbs"
        >
          {rest.map(({ action, href, label }) => (
            <MenuItem onClick={handleClose} key={label}>
              {renderBreadcrumbItem({ action, href, label })}
            </MenuItem>
          ))}
        </Menu>
        <MuiBreadcrumbs aria-label="breadcrumbs" sx={{ alignContent: "end" }}>
          {renderBreadcrumbItem(first)}
          {rest.length > 0 && (
            <IconButton color="primary" size="small" onClick={handleClick}>
              <MoreHorizIcon />
            </IconButton>
          )}
          {last && renderBreadcrumbItem(last)}
        </MuiBreadcrumbs>
      </Stack>
    </Grid>
  );
}
