import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Skeleton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import SocialSpendTargetModal from "@stories/organisms/SocialSpendTargetModal/SocialSpendTargetModal";
import { Table } from "@stories/organisms/Table/Table";
import { getHeaderAndCellStyles } from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import {
  socialSpendCommitmentTypeToDescription,
  socialSpendCommitmentTypeToString,
} from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  ProjectSocialSpendCommitmentLineItem,
  createDefaultSocialSpendCommitment,
  getBaseMultiplierForSpend,
} from "social-pro-common/interfaces/projectSocialSpendCommitment";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import * as yup from "yup";

import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import { SocialSpendTableRowSkeleton } from "../SocialSpendTable/SocialSpendTableRowSkeleton";
import {
  ActionCell,
  InfoCell,
  TABLE_HEAD,
} from "../SocialSpendTableRow/SocialSpendTableRowCells";

interface SocialRequirementFormSpendProps {
  loading: boolean;
  title: string;
  project: ProjectLineItem;
  commitmentLineItems: ProjectSocialSpendCommitmentLineItem[];
  setProject: (project: ProjectLineItem) => void;
  handleNext: () => void;
  handleBack: () => void;
  setDirtyOnChange: (isDirty: boolean) => void;
}

interface FormikField {
  name: string;
  title: string;
  description: string;
  label: string;
  outcomeMultiplier: number;
  initialValue: number;
  type: any;
  deleted?: boolean;
}

export const SocialRequirementFormSpend = ({
  commitmentLineItems,
  handleBack,
  handleNext,
  loading,
  project,
  setDirtyOnChange,
  setProject,
  title,
}: SocialRequirementFormSpendProps) => {
  const [commitments, setCommitments] =
    useState<ProjectSocialSpendCommitmentLineItem[]>(commitmentLineItems);
  const [selectedCommitment, setSelectedCommitment] = useState<
    ProjectSocialSpendCommitmentLineItem | undefined
  >();

  const [fields, setFields] = useState<FormikField[]>([]);
  const [initialValues, setInitialValues] = useState<{ [k: string]: number }>(
    {},
  );
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<
      {
        [x: string]: any;
      },
      yup.AnyObject,
      {
        [x: string]: any;
      },
      ""
    >
  >(yup.object());

  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [commitmentToDelete, setCommitmentToDelete] = useState("");

  useEffect(() => {
    const fieldsFromCommitments: FormikField[] = commitments.map((c) => {
      return {
        deleted: c.deleted,
        description:
          c.targetDescription ||
          socialSpendCommitmentTypeToDescription(c.targetName),
        initialValue: c.targetValue,
        label: socialSpendCommitmentTypeToString(c.targetName),
        name: c.id,
        outcomeMultiplier: getBaseMultiplierForSpend(project.financial),
        title:
          c.targetDescription ||
          socialSpendCommitmentTypeToString(c.targetName),
        type: yup.number().required().min(0),
      } as FormikField;
    }) as FormikField[];

    const initialValues = Object.fromEntries(
      fieldsFromCommitments.map((field) => [field.name, field.initialValue]),
    );

    const SchemaObject = Object.fromEntries(
      fieldsFromCommitments.map((field) => [field.name, field.type]),
    );
    const newValidationSchema = yup.object().shape(SchemaObject);

    setFields(fieldsFromCommitments);
    setInitialValues(initialValues);
    setValidationSchema(newValidationSchema);
  }, [commitments]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const updatedCommitments = commitments
        .map((c) => {
          const value = parseFloat(values[c.id] as unknown as string);
          return {
            ...c,
            targetValue: value,
            targetValueRealised: Math.round(
              getBaseMultiplierForSpend(project.financial) * (value / 100),
            ),
          } as ProjectSocialSpendCommitmentLineItem;
        })
        .filter((c) => !c.deleted) as ProjectSocialSpendCommitmentLineItem[];

      setProject({
        ...project,
        commitmentsSpend: updatedCommitments,
      });
      handleNext();
    },
    validationSchema,
  });

  const goBack = () => {
    setProject({
      ...project,
      commitmentsSpend: commitments,
    });
    handleBack();
  };

  const handleClose = () => {
    setSelectedCommitment(undefined);
    setOpen(false);
  };

  const createSocialCommitment = (
    socialCommitment: ProjectSocialSpendCommitmentLineItem,
  ) => {
    const matchingCommitment = commitments.find(
      (c) => c.id === socialCommitment.id,
    );
    if (matchingCommitment) {
      const updatedCommitments = commitments.reduce(
        (
          acc: ProjectSocialSpendCommitmentLineItem[],
          c: ProjectSocialSpendCommitmentLineItem,
        ) => {
          if (c.id === socialCommitment.id) {
            return [...acc, socialCommitment];
          }
          return [...acc, c];
        },
        [],
      );
      setCommitments(updatedCommitments);
    } else {
      setCommitments([...commitments, socialCommitment]);
    }

    setDirtyOnChange(true);
  };

  const handleEdit = (name: string) => {
    setSelectedCommitment(commitments.find((c) => c.id === name));
    setOpen(true);
  };

  const handleDelete = (name: string) => {
    setCommitmentToDelete(name);
    setOpenConfirmDialog(true);
  };

  const confirmDelete = () => {
    if (commitmentToDelete) {
      const updatedCommitments = commitments.reduce((acc, c) => {
        if (c.id === commitmentToDelete) {
          return [...acc, { ...c, deleted: true }];
        }
        return [...acc, c];
      }, [] as ProjectSocialSpendCommitmentLineItem[]);
      setCommitments(updatedCommitments);
      setDirtyOnChange(true);
    }
    setOpenConfirmDialog(false);
    setCommitmentToDelete("");
  };

  const filteredFields = useMemo(() => {
    return fields
      .filter((f) => !f.deleted)
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [fields]);

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...getHeaderAndCellStyles(undefined, false),
      cellRenderer: InfoCell,
      field: "title",
      flex: 2,
      headerName: "Commitment",
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "initialValue",
      flex: 0.5,
      headerName: "Target %",
      valueFormatter: (params) =>
        `${params.value ? formatDecimalPlaces(params.value) : 0}%`,
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "targetOutcome",
      flex: 0.5,
      headerName: "Target Outcome",
      valueFormatter: (params) => {
        return `$${Math.round((params.data.outcomeMultiplier * params.data.initialValue) / 100).toLocaleString()}`;
      },
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCell,
      cellRendererParams: {
        handleDelete,
        handleEdit,
        loading,
      },
      field: "",
      flex: 0.5,
      headerName: "",
    },
  ]);

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          sx={{
            padding: "25px 40px 0px 40px",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "25px",
              width: "100%",
            }}
          >
            {loading ? (
              <Skeleton animation="wave">
                <SubTitle title={`Target Spend`} />
              </Skeleton>
            ) : (
              <SubTitle title={`Target ${title}`} />
            )}

            <StyledButton
              data-test-id="add-spend-target-button"
              className="blackBtn"
              loading={loading}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setSelectedCommitment(
                  createDefaultSocialSpendCommitment(project.id),
                );
                setOpen(true);
              }}
            >
              {`Add ${title} Target`}
            </StyledButton>
          </Box>

          <Grid item md={12}>
            <Box>
              {loading ? (
                <TableSkeleton
                  tableHead={TABLE_HEAD}
                  rows={SocialSpendTableRowSkeleton}
                />
              ) : (
                <Table<ProjectSocialSpendCommitmentLineItem>
                  columnDefs={colDefs}
                  loading={loading}
                  data={
                    filteredFields as unknown as ProjectSocialSpendCommitmentLineItem[]
                  }
                />
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "end",
            padding: "20px 40px 30px",
          }}
        >
          <Stack direction="row" spacing={1}>
            <StyledButton loading={loading} onClick={goBack} variant="outlined">
              Back
            </StyledButton>
            <StyledButton
              loading={loading}
              disabled={loading || formik.isSubmitting}
              variant="contained"
              type="submit"
            >
              Next
            </StyledButton>
          </Stack>
        </Grid>
      </form>
      <ConfirmationDialog
        message={"Are you sure you want to delete this commitment?"}
        open={openConfirmDialog}
        title={"Delete Commitment"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => confirmDelete()}
      />

      {open && selectedCommitment ? (
        <SocialSpendTargetModal
          open={open}
          loading={loading}
          project={project}
          socialSpendCommitment={selectedCommitment}
          createSocialCommitment={createSocialCommitment}
          handleClose={handleClose}
        />
      ) : null}
    </Box>
  );
};
