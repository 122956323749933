import { listApiData, postApiData } from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useEffect, useState } from "react";
import {
  decodeReportSubmission,
  encodeReportSubmission,
} from "social-pro-common/decoders/reportSubmission";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { ReportSubmission } from "social-pro-common/entities/reportSubmission";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";

export const useReportSocialSubmissionForProject = (
  projectId?: string,
  reportId?: string,
) => {
  const [reportSubmissions, setReportSubmissions] = useState<
    ReportSubmissionLineItem[]
  >([]);
  const [
    isReportSubmissionsForProjectLoading,
    setIsReportSubmissionsForProjectLoading,
  ] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listReportSubmissionsForProject = useCallback(
    async (id: string, reportId: string) => {
      try {
        setIsReportSubmissionsForProjectLoading(true);
        const reportResult = await listApiData(
          "listReportSubmissionsForProject",
          "reportSubmissionSocial",
          id,
          { reportId },
        );
        reportResult.data.reverse();
        setReportSubmissions(
          reportResult.data.map((p) =>
            decodeReportSubmission(p as ReportSubmission),
          ),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not list reports");
      } finally {
        setIsReportSubmissionsForProjectLoading(false);
      }
    },
    [catchSentryError, decodeReportSubmission, listApiData],
  );

  const updateReportSubmission = useCallback(
    async (report: ReportSubmissionLineItem) => {
      try {
        setIsReportSubmissionsForProjectLoading(true);
        const encodedReport = encodeReportSubmission(report);
        await postApiData(
          "updateReportSubmission",
          "reportSubmissionSocial",
          encodedReport,
        );
        setReportSubmissions(
          reportSubmissions.map((p) =>
            p.id === report.id ? { ...p, ...report } : p,
          ),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not update report");
      } finally {
        setIsReportSubmissionsForProjectLoading(false);
      }
    },
    [encodeReportSubmission, postApiData, reportSubmissions],
  );

  const deleteReportSubmission = useCallback(
    async (report: ReportSubmissionLineItem) => {
      try {
        setIsReportSubmissionsForProjectLoading(true);
        const encodedReport = encodeReportSubmission(report);
        await postApiData(
          "deleteReportSubmission",
          "reportSubmissionSocial",
          encodedReport,
        );

        setReportSubmissions(
          reportSubmissions.map((p) =>
            p.id !== report.id
              ? p
              : { ...p, reportStatus: ReportStatus.InProgress },
          ),
        );
      } catch (e) {
        catchSentryError(e);
        setError("Could not delete report");
      } finally {
        setIsReportSubmissionsForProjectLoading(false);
      }
    },
    [encodeReportSubmission, postApiData, reportSubmissions],
  );

  useEffect(() => {
    if (projectId && reportId) {
      listReportSubmissionsForProject(projectId, reportId);
    }
  }, [projectId, reportId, listReportSubmissionsForProject]);

  return {
    deleteReportSubmission,
    error,
    isReportSubmissionsForProjectLoading,
    reportSubmissions,
    updateReportSubmission,
  };
};
