import { Box, Stack } from "@mui/material";
import ForgotPasswordForm from "@stories/organisms/ForgotPasswordForm/ForgotPasswordForm";
import ForgotPasswordSubmitForm from "@stories/organisms/ForgotPasswordSubmitForm/ForgotPasswordSubmitForm";
import Logo from "assets/images/home/socialproLoginimg.png";
import { useState } from "react";

const ForgotPassword = () => {
  const [showEnterCode, setShowEnterCode] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string>();
  return (
    <Box className="loginPage">
    <Stack spacing={2} className="loginCard" sx={{minHeight:'399px !important'}}>
      <a href="/" style={{ margin: "0 auto 20px", maxWidth:'276px',textAlign: "center" }}>
        <img src={Logo} alt="logo"/>
      </a>
        {showEnterCode && emailAddress ? (
          <ForgotPasswordSubmitForm emailAddress={emailAddress} />
        ) : (
          <ForgotPasswordForm
            setShowEnterCode={setShowEnterCode}
            setEmailAddress={setEmailAddress}
          />
        )}
      </Stack>
    </Box>
  );
};

export default ForgotPassword;
