import { Box, Chip, Switch, Tooltip, Zoom } from "@mui/material";
import { wordsToThreeLetterAcronym } from "@utils/string";
import { CustomCellRendererProps } from "ag-grid-react";
import { ChangeEvent, useState } from "react";
import { EmployeeLineItem } from "social-pro-common/interfaces/contractorEmployee";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  labourHourCommitmentTypeToAbbreviatedString,
  labourHourCommitmentTypeToString,
} from "social-pro-common/interfaces/packageLabourHourCommitment";
import {
  socialSpendCommitmentTypeToAbbreviatedString,
  socialSpendCommitmentTypeToString,
} from "social-pro-common/interfaces/packageSocialSpendCommitment";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ProjectLabourHourCommitmentLineItem } from "social-pro-common/interfaces/projectLabourHourCommitment";
import { ProjectSocialSpendCommitmentLineItem } from "social-pro-common/interfaces/projectSocialSpendCommitment";

interface ToggleActionCellProps extends CustomCellRendererProps {
  handleEmployeeAssignment: (
    employee: EmployeeLineItem,
    project: ProjectLineItem,
    contractorPackage: ContractorPackageLineItem,
    employees: EmployeeLineItem[],
    toggle: boolean,
  ) => void;
  project: ProjectLineItem;
}

const ToggleActionCell = (props: ToggleActionCellProps) => {
  const { context, data } = props;

  const [checked, setChecked] = useState(data.assigned);
  function handleAssignment(e: ChangeEvent<HTMLInputElement>) {
    setChecked(e.target.checked);
    context.handleEmployeeAssignment(data, e.target.checked);
  }

  return <Switch onChange={handleAssignment} checked={checked} />;
};

const EmployeeSocialFactorsCell = ({
  factors,
}: {
  factors: ProjectLabourHourCommitmentLineItem[];
}) => {
  return (
    <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center">
      {factors.map((sf, i) => (
        <Tooltip
          TransitionComponent={Zoom}
          key={`tool-tip-employee-social-factor-${i}`}
          placement="top"
          title={
            sf.targetDescription ||
            labourHourCommitmentTypeToString(sf.targetName)
          }
        >
          <Chip
            size="small"
            label={
              sf.targetDescription
                ? wordsToThreeLetterAcronym(sf.targetDescription)
                : labourHourCommitmentTypeToAbbreviatedString(sf.targetName)
            }
            onMouseDown={(event) => event.stopPropagation()}
            style={{
              backgroundColor: sf.colour,
              color: "#fff",
            }}
          />
        </Tooltip>
      ))}
    </Box>
  );
};

const SocialSpendFactorsCell = ({
  factors,
}: {
  factors: ProjectSocialSpendCommitmentLineItem[];
}) => {
  return (
    <Box display="flex" flexWrap="wrap" gap={1} justifyContent="center">
      {factors.map((sf, i) => (
        <Tooltip
          TransitionComponent={Zoom}
          key={`tool-tip-employee-social-factor-${i}`}
          placement="top"
          title={
            sf.targetDescription ||
            socialSpendCommitmentTypeToString(sf.targetName)
          }
        >
          <Chip
            size="small"
            label={
              sf.targetDescription
                ? wordsToThreeLetterAcronym(sf.targetDescription)
                : socialSpendCommitmentTypeToAbbreviatedString(sf.targetName)
            }
            onMouseDown={(event) => event.stopPropagation()}
            style={{
              backgroundColor: sf.colour,
              color: "#fff",
            }}
          />
        </Tooltip>
      ))}
    </Box>
  );
};

export { EmployeeSocialFactorsCell, SocialSpendFactorsCell, ToggleActionCell };
