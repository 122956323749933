import { useSocialSpend } from "@hooks/crud/socialSpend/useSocialSpend";
import { downloadFile, uploadFile } from "@hooks/utils/useUpload";
import { Grid } from "@mui/material";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { SocialSpendTable } from "@stories/molecules/SocialSpendTable/SocialSpendTable";
import SpendImport, {
  ImportStep,
} from "@stories/molecules/SpendImport/SpendImport";
import SocialSpendModal from "@stories/organisms/SocialSpendModal/SocialSpendModal";
import { catchSentryError } from "@utils/sentry";
import { AddIcon, ExportIcon, ImportIcon } from "assets/constants";
// import { ChangeEvent, FocusEvent, useState } from "react";
import { ChangeEvent, FocusEvent, useState, useCallback, useRef } from "react";
import { toast, ToastOptions } from "react-toastify";
import { PackageCommentType } from "social-pro-common/entities/packageComment";
import { ReportDocumentType } from "social-pro-common/entities/reportDocument";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";
// import { packageCommentTypeToString } from "social-pro-common/interfaces/packageComment";
import { packageCommentTypeToString } from "social-pro-common/interfaces/packageComment";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  createDefaultReportDocument,
  ReportDocumentLineItem,
} from "social-pro-common/interfaces/reportDocument";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";
import { getFileName } from "social-pro-common/utils/string";

import { Header, HeaderButtonConfig, HeaderButtonType } from "../Header/Header";

interface SocialSpendProps {
  isLoading: boolean;
  userProfile?: ProfileLineItem;
  userOrganisation?: OrganisationLineItem;
  selectedProject?: ProjectLineItem;
  contractorPackageId?: string;
  reportId: string;
  commentMap: Map<PackageCommentType, string>;
  isSubmitting: boolean;
  readOnly?: boolean;
  reportDocuments: ReportDocumentLineItem[];
  createReportDocument: (
    reportDocument: ReportDocumentLineItem,
  ) => Promise<void>;
  updateReportDocument: (
    reportDocument: ReportDocumentLineItem,
  ) => Promise<void>;
  setIsSubmitting: (isSubmitting: boolean) => void;
  updateComment: (
    packageType: PackageCommentType,
    comment: string,
    save: boolean,
  ) => Promise<void>;
  handleOnBack?: () => Promise<void>;
}

export const SocialSpends = ({
  commentMap,
  contractorPackageId,
  createReportDocument,
  handleOnBack,
  isLoading,
  isSubmitting,
  readOnly,
  reportDocuments,
  reportId,
  selectedProject,
  setIsSubmitting,
  updateComment,
  updateReportDocument,
  userOrganisation,
  userProfile,
}: SocialSpendProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const onFileInput = async () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const [openImportModal, setOpenImportModal] = useState(false);
  const [step, setStep] = useState<ImportStep>(ImportStep.Init);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [_uploadProgress, setUploadProgress] = useState(0);
  const {
    createSocialSpend,
    deleteSocialSpends,
    isSocialSpendLoading,
    socialSpends,
    updateSocialSpend,
    upsertSocialSpends,
  } = useSocialSpend(selectedProject?.id, reportId, contractorPackageId);

  const [selectedSocialSpend, setSelectedSocialSpend] =
    useState<SocialSpendLineItem>();

  const handleClose = () => {
    setOpen(false);
    setSelectedSocialSpend(undefined);
  };

  const handleOpen = () => {
    setOpen(true);
    setSelectedSocialSpend(undefined);
  };

  const handleDeleteSocialSpends = useCallback(
    async (socialSpend: SocialSpendLineItem) => {
      deleteSocialSpends([socialSpend]);
    },
    [deleteSocialSpends, socialSpends],
  );

  const handleEditSocialSpend = useCallback(
    async (socialSpend: SocialSpendLineItem) => {
      setSelectedSocialSpend(socialSpend);
      setOpen(true);
    },
    [socialSpends],
  );

  const handleOpenImportModal = () => {
    setOpenImportModal(true);
  };

  const commentValue = commentMap.get(PackageCommentType.Spend) || "";

  const loading = isLoading || isSocialSpendLoading;

  const [tabIndex, setTabIndex] = useState<number>(0);

  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChangev2 = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleTabChange = async (tabIndex: number) => {
    setTabIndex(tabIndex);
  };

  const onDownload = useCallback(async () => {
    setIsDownloading(true);
    const socialSpendReportDocument = reportDocuments.find(
      (d) => d.reportDocumentType === ReportDocumentType.LabourHour,
    );
    if (socialSpendReportDocument) {
      await downloadFile(
        socialSpendReportDocument.uri,
        getFileName(socialSpendReportDocument.uri),
      );
    }
    setIsDownloading(false);
  }, [reportDocuments]);

  const onUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setUploadProgress(0);
      if (
        e.currentTarget?.files &&
        e.currentTarget?.files.length > 0 &&
        contractorPackageId
      ) {
        const file = e.currentTarget?.files[0] || null;
        if (file) {
          setIsUploading(true);

          const fileKey = `supporting-documents/${contractorPackageId}/${reportId}/${file.name}`;
          await uploadFile(
            fileKey,
            file,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
              if (total === 100) {
                toast("Upload complete!", {
                  type: "success",
                } as ToastOptions);
              }
            },
            (error: any) => {
              setUploadProgress(0);
              catchSentryError(error);
              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
              setIsUploading(false);
            },
          );

          if (socialSpendReportDocument) {
            await updateReportDocument({
              ...socialSpendReportDocument,
              uri: fileKey,
            });
          } else {
            await createReportDocument(
              createDefaultReportDocument(
                contractorPackageId,
                reportId,
                fileKey,
                ReportDocumentType.SocialSpend,
              ),
            );
          }
          // const urlToImage = URL.createObjectURL(file);
          setIsUploading(false);
        }
      }
    },
    [
      contractorPackageId,
      createReportDocument,
      reportId,
      reportDocuments,
      updateReportDocument,
    ],
  );

  const buttons = [
    {
      action: () => {
        handleOpenImportModal();
      },
      icon: <ImportIcon />,
      text: "Import",
      type: HeaderButtonType.SECONDARY,
      width: 100,
    },
    {
      action: handleOpen,
      icon: <AddIcon variant="secondary" />,
      text: "Add Social Spend",
      type: HeaderButtonType.PRIMARY,
      width: 170,
    },
  ] as HeaderButtonConfig[];

  const socialSpendReportDocument = reportDocuments.find(
    (d) => d.reportDocumentType === ReportDocumentType.SocialSpend,
  );

  const supportingDocumentsButtons: HeaderButtonConfig[] = [];

  if (!socialSpendReportDocument) {
    supportingDocumentsButtons.push({
      action: onFileInput,
      icon: <ImportIcon />,
      text: "Supporting Document",
      type: HeaderButtonType.SECONDARY,
    });
  } else {
    supportingDocumentsButtons.push({
      action: onDownload,
      icon: <ExportIcon />,
      text: "Download",
      type: HeaderButtonType.SECONDARY,
    });
    supportingDocumentsButtons.push({
      action: onFileInput,
      icon: <ImportIcon />,
      text: "Reupload",
      type: HeaderButtonType.SECONDARY,
    });
  }

  const submitting = isSubmitting || isUploading || isDownloading;
  return (
    <>
      <Header
        loading={loading}
        subTitle={`${selectedProject?.projectName}: ${
          reportId ? formatReportDate(stringToDate(reportId)) : ""
        }`}
        handleSearchChange={handleSearchChangev2}
        searchTerm={searchTerm}
        buttons={buttons}
        mainTitle={"Social Spend"}
        tabs={[]}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        handleBack={handleOnBack}
        headerFontSizes={[18, 14]}
        headerGap={0}
      />
      <SocialSpendTable
        loading={loading}
        socialSpends={socialSpends}
        project={selectedProject}
        setPage={setPage}
        page={page}
        handleDeleteSocialSpends={handleDeleteSocialSpends}
        handleEditSocialSpend={handleEditSocialSpend}
        searchTerm={searchTerm}
      />
      <input
        ref={fileInput}
        hidden
        id="fileUploadInput"
        accept="*"
        type="file"
        style={{
          cursor: "pointer",
          height: "100px",
          opacity: 0,
          padding: 0.5,
          position: "fixed",
          width: "100px",
          zIndex: 1,
        }}
        onChange={onUpload}
      />
      <Header
        loading={loading}
        mainTitle=""
        subTitle={"Supporting Information"}
        buttons={supportingDocumentsButtons}
      />
      <Grid item xs={12} md={12} mt={2}>
        <StyledTextField
          data-test-id="spend-comments-input"
          variant="outlined"
          loading={loading && !submitting}
          disabled={loading || submitting || readOnly}
          id={`${packageCommentTypeToString(PackageCommentType.Spend)}`}
          name={`${packageCommentTypeToString(PackageCommentType.Spend)}`}
          label={`${packageCommentTypeToString(
            PackageCommentType.Spend,
          )} Comments`}
          fullWidth
          maxLength={1000}
          rows={5}
          multiline
          margin="dense"
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            await updateComment(
              PackageCommentType.Spend,
              e.target.value,
              false,
            );
          }}
          onBlur={async (e: FocusEvent<HTMLInputElement>) => {
            setIsSubmitting(true);
            await updateComment(PackageCommentType.Spend, e.target.value, true);
            setIsSubmitting(false);
          }}
          value={commentValue}
        />
      </Grid>
      {openImportModal && reportId ? (
        <SpendImport
          open={openImportModal}
          step={step}
          userProfile={userProfile}
          userOrganisation={userOrganisation}
          setIsOpen={setOpenImportModal}
          setStep={setStep}
          reportId={reportId}
          upsertSocialSpend={upsertSocialSpends}
        />
      ) : null}
      {open &&
      selectedProject &&
      contractorPackageId &&
      userOrganisation &&
      reportId ? (
        <SocialSpendModal
          open={open}
          reportId={reportId}
          loading={loading}
          project={selectedProject}
          contractorOrganisation={userOrganisation}
          contractorPackageId={contractorPackageId}
          existingSocialSpend={selectedSocialSpend}
          createSocialSpend={createSocialSpend}
          updateSocialSpend={updateSocialSpend}
          handleClose={handleClose}
        />
      ) : null}
    </>
  );
};
