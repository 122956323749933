import { downloadFile, uploadFile } from "@hooks/utils/useUpload";
import { Grid } from "@mui/material";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useCallback, useState } from "react";
import { toast, ToastOptions } from "react-toastify";
import { PackageType } from "social-pro-common/entities/contractorPackage";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  createDefaultPolicySubmission,
  PolicySubmissionLineItem,
} from "social-pro-common/interfaces/policySubmission";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  policyTypeToString,
  ProjectPolicyLineItem,
} from "social-pro-common/interfaces/projectPolicy";

import { PolicyRowSkeleton } from "./PolicyRowSkeleton";
import { generateTableHead, Table } from "../Table/Table";
import {
  getHeaderAndCellStyles,
  SubmissionCell,
  UploadDownloadCell,
} from "../Table/TableCells";
import TableSkeleton from "../Table/TableSkeleton";

interface PoliciesProps {
  loading: boolean;
  contractorPackage?: ContractorPackageLineItem;
  project?: ProjectLineItem;
  policySubmissions: PolicySubmissionLineItem[];
  createPolicySubmission: (
    policySubmission: PolicySubmissionLineItem,
  ) => Promise<PolicySubmissionLineItem>;
  updatePolicySubmission: (
    policySubmission: PolicySubmissionLineItem,
  ) => Promise<void>;
}

export const Policies = ({
  contractorPackage,
  createPolicySubmission,
  loading,
  policySubmissions,
  project,
  updatePolicySubmission,
}: PoliciesProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);

  const onDownload = async (uri: string, policyName: string) => {
    setIsDownloading(true);
    await downloadFile(uri, policyName);
    setIsDownloading(false);
  };

  const onUpload = useCallback(
    async (
      e: React.ChangeEvent<HTMLInputElement>,
      policy: ProjectPolicyLineItem,
    ) => {
      setUploadProgress(0);
      if (
        e.currentTarget?.files &&
        e.currentTarget?.files.length > 0 &&
        project &&
        contractorPackage
      ) {
        const file = e.currentTarget?.files[0] || null;
        if (file) {
          setIsUploading(true);

          const fileKey = `policies/${contractorPackage.id}/${policy.id}/${file.name}`;
          await uploadFile(
            fileKey,
            file,
            (progress: any) => {
              const total = (progress.loaded / progress.total) * 100;
              setUploadProgress(total);
              if (total === 100) {
                toast("Upload complete!", {
                  type: "success",
                } as ToastOptions);
              }
            },
            (error: any) => {
              setUploadProgress(0);
              console.log(error);
              toast("Upload failed - something went wrong!", {
                type: "error",
              } as ToastOptions);
              setIsUploading(false);
            },
          );

          const matchingProjectPolicy = project.policies.find(
            (p) => p.id === policy.id,
          );

          const matchingPackagePolicy = policySubmissions.find(
            (p) => p.projectPolicyId === policy.id,
          );

          if (matchingProjectPolicy) {
            if (matchingPackagePolicy) {
              matchingPackagePolicy.uri = fileKey;
              await updatePolicySubmission(matchingPackagePolicy);
            } else {
              const policySubmission = createDefaultPolicySubmission(
                project.id,
                contractorPackage.id,
                matchingProjectPolicy.id,
              );
              policySubmission.uri = fileKey;
              await createPolicySubmission(policySubmission);
            }
          } else {
            toast("Could not find policySubmissionx.", {
              type: "error",
            } as ToastOptions);
          }

          // const urlToImage = URL.createObjectURL(file);
          setIsUploading(false);
        }
      }
    },
    [project, contractorPackage, policySubmissions],
  );

  const [colDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...getHeaderAndCellStyles(undefined, false),
      field: "policyType",
      flex: 3,
      headerName: "Description",
      valueFormatter: (params) => {
        return (
          params.data.policyDescription ||
          policyTypeToString(params.data.policyType)
        );
      },
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: SubmissionCell,
      field: "created",
      headerName: "Submission Date",
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: UploadDownloadCell,
      flex: 0.6,
      headerName: "",
    },
  ]);

  const policiesForPackage = project?.policies
    .filter((p) => !p.isProjectLevelPolicy)
    .sort((a, b) => {
      const aName = a.policyDescription || policyTypeToString(a.policyType);
      const bName = b.policyDescription || policyTypeToString(b.policyType);
      return aName.localeCompare(bName);
    });
  const policiesForProject = project?.policies
    .filter(
      (p) =>
        p.isProjectLevelPolicy &&
        contractorPackage &&
        contractorPackage.packageType === PackageType.PrimaryPackage,
    )
    .sort((a, b) => {
      const aName = a.policyDescription || policyTypeToString(a.policyType);
      const bName = b.policyDescription || policyTypeToString(b.policyType);
      return aName.localeCompare(bName);
    });

  const allPolicies = [
    ...(policiesForProject || []),
    ...(policiesForPackage || []),
  ];

  return (
    <Grid item md={12}>
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={PolicyRowSkeleton}
        />
      ) : null}

      {!loading && allPolicies.length > 0 ? (
        <Table<ProjectPolicyLineItem>
          columnDefs={colDefs}
          loading={loading}
          data={allPolicies || []}
          context={{
            isDownloading,
            isUploading,
            onDownload,
            onUpload,
            policySubmissions,
            uploadProgress,
          }}
        />
      ) : null}
    </Grid>
  );
};
