"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.decodeAnalyticsSocialSpendLevel3PageModel = exports.decodeAnalyticsSocialSpendLevel2PageModel = exports.decodeAnalyticsSocialSpendLevel1PageModel = exports.decodeAnalyticsLabourHoursLevel4PageModel = exports.decodeAnalyticsLabourHoursLevel3PageModel = exports.decodeLabourHourForTargetLineItem = exports.decodeAnalyticsLabourHoursLevel2PageModel = exports.decodeAnalyticsLabourHoursLevel1PageModel = exports.decodeSustainabilitysAnalyticsModel = exports.decodeSustainabilityTargetAnalytics = exports.decodePolicyAnalyticsModel = exports.decodePolicyTargetAnalytics = exports.decodeSocialSpendAnalyticsForPackageModel = exports.decodeSocialSpendTargetAnalyticsForPackage = exports.decodeSocialSpendTargetAnalytics = exports.decodeLabourHoursAnalyticsForEmployeeForTargetModel = exports.decodeLabourHoursAnalyticsForEmployeeLineItem = exports.decodeLabourHoursAnalyticsForEmployeeModel = exports.decodeLabourHoursAnalyticsForEmployee = exports.decodeLabourHoursAnalyticsForPackageModel = exports.decodeLabourHourTargetAnalyticsForPackage = exports.decodeLabourHoursAnalyticsModel = exports.decodeLabourHourTargetAnalytics = void 0;
const labourHour_1 = require("../labourHour");
const organisation_1 = require("../organisation");
const socialSpend_1 = require("../socialSpend");
const decodeLabourHourTargetAnalytics = (data) => {
    return {
        actual: data.actual,
        committedHours: data.committed_hours,
        id: data.id,
        monthReportedValue: data.month_reported_value,
        progress: data.progress,
        targetDescription: data.target_description,
        targetType: data.target_type,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
        uncommittedHours: data.uncommitted_hours,
    };
};
exports.decodeLabourHourTargetAnalytics = decodeLabourHourTargetAnalytics;
const decodeLabourHoursAnalyticsModel = (data) => {
    return {
        targets: data.targets.map((target) => (0, exports.decodeLabourHourTargetAnalytics)(target)),
    };
};
exports.decodeLabourHoursAnalyticsModel = decodeLabourHoursAnalyticsModel;
const decodeLabourHourTargetAnalyticsForPackage = async (data, getImgUrl) => {
    const contractorLogo = await getImgUrl(data.contractor_logo);
    return {
        actual: data.actual,
        contractorLogo,
        contractorName: data.contractor_name,
        id: data.id,
        monthReportedValue: data.month_reported_value,
        packageNumber: data.package_number,
        progress: data.progress,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
    };
};
exports.decodeLabourHourTargetAnalyticsForPackage = decodeLabourHourTargetAnalyticsForPackage;
const decodeLabourHoursAnalyticsForPackageModel = async (data, getImgUrl) => {
    const targets = await Promise.all(data.targets.map((target) => (0, exports.decodeLabourHourTargetAnalyticsForPackage)(target, getImgUrl)));
    const summary = (0, exports.decodeLabourHourTargetAnalytics)(data.summary);
    return {
        summary,
        targets,
    };
};
exports.decodeLabourHoursAnalyticsForPackageModel = decodeLabourHoursAnalyticsForPackageModel;
const decodeLabourHoursAnalyticsForEmployee = (data) => {
    return {
        employeeIdentifier: data.employee_identifier,
        employeeName: data.employee_name,
        id: data.id,
        primaryEmployer: data.primary_employer,
        subcontractor: data.subcontractor,
        totalHoursStudied: data.total_hours_studied,
        totalHoursWorked: data.total_hours_worked,
    };
};
exports.decodeLabourHoursAnalyticsForEmployee = decodeLabourHoursAnalyticsForEmployee;
const decodeLabourHoursAnalyticsForEmployeeModel = async (data, getImageUrl) => {
    const summary = await (0, exports.decodeLabourHourTargetAnalyticsForPackage)(data.summary, getImageUrl);
    return {
        summary,
        targets: data.targets.map((target) => (0, exports.decodeLabourHoursAnalyticsForEmployee)(target)),
    };
};
exports.decodeLabourHoursAnalyticsForEmployeeModel = decodeLabourHoursAnalyticsForEmployeeModel;
const decodeLabourHoursAnalyticsForEmployeeLineItem = (data) => {
    return {
        employeeIdentifier: data.employee_identifier,
        employeeName: data.employee_name,
        id: data.id,
        primaryEmployer: data.primary_employer,
        subcontractor: data.subcontractor,
        totalHoursStudied: data.total_hours_studied,
        totalHoursWorked: data.total_hours_worked,
    };
};
exports.decodeLabourHoursAnalyticsForEmployeeLineItem = decodeLabourHoursAnalyticsForEmployeeLineItem;
const decodeLabourHoursAnalyticsForEmployeeForTargetModel = (data) => {
    return {
        summary: (0, exports.decodeLabourHoursAnalyticsForEmployeeLineItem)(data.summary),
        targets: data.targets.map((target) => (0, labourHour_1.decodeLabourHourForTarget)(target)),
    };
};
exports.decodeLabourHoursAnalyticsForEmployeeForTargetModel = decodeLabourHoursAnalyticsForEmployeeForTargetModel;
const decodeSocialSpendTargetAnalytics = (data) => {
    return {
        committedSpend: data.committed_spend,
        id: data.id,
        monthReportValue: data.month_report_value,
        progress: data.progress,
        targetDescription: data.target_description,
        targetType: data.target_type,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
        uncommittedSpend: data.uncommitted_spend,
    };
};
exports.decodeSocialSpendTargetAnalytics = decodeSocialSpendTargetAnalytics;
const decodeSocialSpendTargetAnalyticsForPackage = async (data, getImgUrl) => {
    const contractorLogo = await getImgUrl(data.contractor_logo);
    return {
        contractorLogo,
        contractorName: data.contractor_name,
        id: data.id,
        monthReportedValue: data.month_reported_value,
        packageNumber: data.package_number,
        progress: data.progress,
        targetValue: data.target_value,
        totalReportedValue: data.total_reported_value,
    };
};
exports.decodeSocialSpendTargetAnalyticsForPackage = decodeSocialSpendTargetAnalyticsForPackage;
const decodeSocialSpendAnalyticsForPackageModel = async (data, getImgUrl) => {
    const targets = await Promise.all(data.targets.map((target) => (0, exports.decodeSocialSpendTargetAnalyticsForPackage)(target, getImgUrl)));
    const summary = (0, exports.decodeSocialSpendTargetAnalytics)(data.summary);
    return {
        summary,
        targets,
    };
};
exports.decodeSocialSpendAnalyticsForPackageModel = decodeSocialSpendAnalyticsForPackageModel;
const decodePolicyTargetAnalytics = (data) => {
    return {
        expectedSubmissions: data.expected_submissions,
        id: data.id,
        isProjectLevelPolicy: data.is_project_level_policy,
        policyDescription: data.policy_description,
        policyType: data.policy_type,
        progress: data.progress,
        totalSubmissions: data.total_submissions,
        uri: data.uri,
    };
};
exports.decodePolicyTargetAnalytics = decodePolicyTargetAnalytics;
const decodePolicyAnalyticsModel = (data) => {
    return {
        targets: data.targets.map((target) => (0, exports.decodePolicyTargetAnalytics)(target)),
    };
};
exports.decodePolicyAnalyticsModel = decodePolicyAnalyticsModel;
const decodeSustainabilityTargetAnalytics = (data) => {
    return {
        category: data.category,
        greenMetricId: data.green_metric_id,
        id: data.id,
        pcIncrease: data.pc_increase,
        thisMonth: data.this_month,
        toDate: data.to_date,
        type: data.type,
        uri: data.uri,
    };
};
exports.decodeSustainabilityTargetAnalytics = decodeSustainabilityTargetAnalytics;
const decodeSustainabilitysAnalyticsModel = (data) => {
    return {
        targets: data.targets.map((target) => (0, exports.decodeSustainabilityTargetAnalytics)(target)),
    };
};
exports.decodeSustainabilitysAnalyticsModel = decodeSustainabilitysAnalyticsModel;
const decodeAnalyticsLabourHoursLevel1PageModel = (data) => {
    return {
        targets: data.targets.map((target) => (0, exports.decodeLabourHourTargetAnalytics)(target)),
    };
};
exports.decodeAnalyticsLabourHoursLevel1PageModel = decodeAnalyticsLabourHoursLevel1PageModel;
const decodeAnalyticsLabourHoursLevel2PageModel = async (data, getImgUrl) => {
    const subcontractorData = await Promise.all(data.subcontractorData.map((subcontractor) => (0, exports.decodeLabourHourTargetAnalyticsForPackage)(subcontractor, getImgUrl)));
    return {
        subcontractorData,
        summary: (0, exports.decodeLabourHourTargetAnalytics)(data.summary),
    };
};
exports.decodeAnalyticsLabourHoursLevel2PageModel = decodeAnalyticsLabourHoursLevel2PageModel;
const decodeLabourHourForTargetLineItem = (data) => {
    return {
        currentHoursStudied: data.current_hours_studied,
        currentHoursWorked: data.current_hours_worked,
        employeeIdentifier: data.employee_identifier,
        employeeName: data.employee_name,
        id: data.contractor_employee_id,
        organisationName: data.organisation_name,
        previousHoursWorked: data.previous_hours_worked,
        primaryEmployer: data.primary_employer,
        reportId: data.report_id,
        totalHoursStudied: data.total_hours_studied,
        totalHoursWorked: data.total_hours_worked,
    };
};
exports.decodeLabourHourForTargetLineItem = decodeLabourHourForTargetLineItem;
const decodeAnalyticsLabourHoursLevel3PageModel = async (data, getImageUrl) => {
    const subContractor = await (0, organisation_1.decodeOrganisation)(data.subContractor, getImageUrl);
    return {
        labourHours: data.labourHours.map((lh) => (0, exports.decodeLabourHourForTargetLineItem)(lh)),
        subContractor,
        summary: (0, exports.decodeLabourHourTargetAnalytics)(data.summary),
    };
};
exports.decodeAnalyticsLabourHoursLevel3PageModel = decodeAnalyticsLabourHoursLevel3PageModel;
const decodeAnalyticsLabourHoursLevel4PageModel = async (data, getImageUrl) => {
    const subContractor = await (0, organisation_1.decodeOrganisation)(data.subContractor, getImageUrl);
    return {
        employeeName: data.employeeName,
        labourHours: data.labourHours.map((labourHour) => (0, labourHour_1.decodeLabourHourForTarget)(labourHour)),
        subContractor,
        summary: (0, exports.decodeLabourHourTargetAnalytics)(data.summary),
    };
};
exports.decodeAnalyticsLabourHoursLevel4PageModel = decodeAnalyticsLabourHoursLevel4PageModel;
const decodeAnalyticsSocialSpendLevel1PageModel = (data) => {
    return {
        targets: data.targets.map((target) => (0, exports.decodeSocialSpendTargetAnalytics)(target)),
    };
};
exports.decodeAnalyticsSocialSpendLevel1PageModel = decodeAnalyticsSocialSpendLevel1PageModel;
const decodeAnalyticsSocialSpendLevel2PageModel = async (data, getImgUrl) => {
    const targets = await Promise.all(data.targets.map((target) => (0, exports.decodeSocialSpendTargetAnalyticsForPackage)(target, getImgUrl)));
    const summary = (0, exports.decodeSocialSpendTargetAnalytics)(data.summary);
    return {
        summary,
        targets,
    };
};
exports.decodeAnalyticsSocialSpendLevel2PageModel = decodeAnalyticsSocialSpendLevel2PageModel;
const decodeAnalyticsSocialSpendLevel3PageModel = async (data, getImgUrl) => {
    const subContractor = await (0, organisation_1.decodeOrganisation)(data.subContractor, getImgUrl);
    const summary = (0, exports.decodeSocialSpendTargetAnalytics)(data.summary);
    return {
        spend: data.spend.map((target) => (0, socialSpend_1.decodeSocialSpend)(target)),
        subContractor,
        summary,
    };
};
exports.decodeAnalyticsSocialSpendLevel3PageModel = decodeAnalyticsSocialSpendLevel3PageModel;
