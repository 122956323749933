import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourAnalyticsLevel2 } from "@hooks/crud/analytics/useLabourHourAnalyticsLevel2";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { AnalyticsHoursLevel2Table } from "@stories/organisms/AnalyticsHoursTables/AnalyticsHoursLevel2Table/AnalyticsHoursLevel2Table";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import LabourHourAnalyticsSummaryLevel2 from "@stories/organisms/Summary/LabourHourAnalyticsSummaryLevel2";
import * as RouteHelper from "@utils/routes";
import { ViewProjectIcon } from "assets/constants";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import {
  dateToString,
  getMaximalDate,
  stringToDate,
} from "social-pro-common/utils/date";

export const AnalyticsHoursLevel2 = () => {
  const { reportId, targetId } = useParams();
  if (!reportId) {
    throw Error("No report id");
  }
  if (!targetId) {
    throw Error("No target id");
  }
  const navigate = useNavigate();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, labourHoursAnalytics } =
    useLabourHourAnalyticsLevel2(
      reportId,
      targetId,
      selectedProject?.id,
      contractorPackage?.id,
    );

  const loading = isProjectLoading || isAnalysisLoading;

  if (labourHoursAnalytics) {
    labourHoursAnalytics.subcontractorData =
      labourHoursAnalytics.subcontractorData.sort((a, b) => {
        if (a.packageNumber > b.packageNumber) {
          return 1;
        }
        return -1;
      });
  }

  const viewSubContractorDetails = useCallback(
    (subPackage: ContractorPackageLineItem) => {
      console.log("View package details", subPackage);
      if (selectedProject && contractorPackage) {
        navigate(
          RouteHelper.analyticsLabourHoursLevel3(
            selectedProject.id,
            contractorPackage.id,
            subPackage.id,
            reportId,
            targetId,
            "package",
          ),
        );
      }
    },
    [selectedProject, contractorPackage, navigate, targetId, reportId],
  );
  const [currentDate, setCurrentDate] = useState<Date>(
    stringToDate(
      getMaximalDate(
        isPrimaryPackage
          ? selectedProject?.projectStartDate
          : contractorPackage?.packageStartDate,
        isPrimaryPackage
          ? selectedProject?.projectEndDate
          : contractorPackage?.packageEndDate,
      ),
    ),
  );

  const handleDateChange = async (currentDate: Date) => {
    setCurrentDate(currentDate);
  };
  const breadcrumbData = [
    {
      href: RouteHelper.analyticsLabourHoursLevel1(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        dateToString(currentDate),
      ),
      label: "Labour Hours",
    },
    {
      href: RouteHelper.analyticsLabourHoursLevel2(
        selectedProject?.id || "dasdasda",
        contractorPackage?.id || "",
        targetId,
        reportId || "",
      ),
      label: labourHoursAnalytics
        ? labourHoursAnalytics?.summary.targetDescription ||
          labourHourCommitmentTypeToString(
            labourHoursAnalytics?.summary.targetType,
          )
        : "",
    },
  ];

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleViewAll = async () => {
    navigate(
      RouteHelper.analyticsLabourHoursLevel3(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        contractorPackage?.id || "",
        reportId,
        targetId,
        "all",
      ),
    );
  };

  const buttons: HeaderButtonConfig[] = [
    {
      action: handleViewAll,
      icon: <ViewProjectIcon />,
      text: "View All",
      type: HeaderButtonType.SECONDARY,
      width: 110,
    },
  ];

  return (
    <PageContainer>
      <Header
        loading={loading}
        breadcrumbIcon={<HourglassEmptyIcon />}
        breadcrumbs={breadcrumbData}
        subTitle={
          isPrimaryPackage
            ? `Project Analytics for ${selectedProject?.projectName}`
            : `Package Analytics for ${selectedProject?.projectName}`
        }
        mainTitle="Labour Hours"
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
        handleDateChange={handleDateChange}
        currentDate={currentDate}
      />

      <Grid md={12} item>
        <LabourHourAnalyticsSummaryLevel2
          summary={labourHoursAnalytics?.summary}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <WhiteBox>
          <Stack direction="column" gap={2}>
            <Header
              loading={loading}
              mainTitle="Target Summary"
              subTitle="Level 2 Analytics"
              isSubheading
              headerFontSizes={[18, 14]}
              headerGap={0}
            />
            <AnalyticsHoursLevel2Table
              loading={loading}
              reportId={reportId}
              targetId={targetId}
              searchTerm={searchTerm}
              labourHoursAnalytics={labourHoursAnalytics}
              handleAction={viewSubContractorDetails}
            />
          </Stack>
        </WhiteBox>
      </Grid>
    </PageContainer>
  );
};
