import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { usePackageComments } from "@hooks/crud/packageComments/usePackageComments";
import { useReportDocument } from "@hooks/crud/reportDocument/useReportDocument";
import { useReportSocialSubmission } from "@hooks/crud/reportSocialSubmission/useReportSocialSubmission";
import { Grid, Stack } from "@mui/material";
import { CustomButtonWithIcon } from "@stories/atoms/CustomButtonWithIcon/CustomButtonWithIcon";
import { PackageLidpSubmissionForm } from "@stories/molecules/PackageLidpSubmissionForm/PackageLidpSubmissionForm";
import { WizardSteps } from "@stories/molecules/WizardSteps/WizardSteps";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { ReportConfirmation } from "@stories/organisms/ReportConfirmation/ReportConfirmation";
import { SocialSpends } from "@stories/organisms/SocialSpend/SocialSpend";
import { ArrowBackIosNewIcon, ArrowForwardIosIcon } from "assets/constants";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PackageCommentType } from "social-pro-common/entities/packageComment";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import {
  PackageCommentLineItem,
  createDefaultPackageComment,
} from "social-pro-common/interfaces/packageComment";
import { ReportDocumentLineItem } from "social-pro-common/interfaces/reportDocument";
import { createDefaultReportSubmission } from "social-pro-common/interfaces/reportSubmission";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";

import { ConfirmationStep } from "./ConfirmationStep";
import { LabourHours } from "../../organisms/LabourHours/LabourHours";

export const Wizard = () => {
  const { reportId } = useParams();

  if (!reportId) {
    throw Error("Not report number");
  }

  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { comments, upsertPackageComments } = usePackageComments(
    selectedProject?.id,
    reportId,
    contractorPackage?.id,
  );

  const {
    createReportDocument,
    isReportDocumentsLoading,
    reportDocuments,
    updateReportDocument,
  } = useReportDocument(contractorPackage?.id, reportId);

  const { createReportSubmission, isReportLoading } =
    useReportSocialSubmission();

  const [activeStep, setActiveStep] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [commentMap, setCommentMap] = useState(
    new Map<PackageCommentType, string>(),
  );

  //TODO: Fix this a bit better
  const handleCreateReportDocument = useCallback(
    async (doc: ReportDocumentLineItem) => {
      setIsSubmitting(() => true);
      await createReportDocument(doc);
      setIsSubmitting(() => false);
    },
    [reportDocuments],
  );

  const handleUpdateReportDocument = useCallback(
    async (doc: ReportDocumentLineItem) => {
      setIsSubmitting(() => true);
      await updateReportDocument(doc);
      setIsSubmitting(() => false);
    },
    [reportDocuments],
  );

  //TODO: Fix this a bit better
  const handleUpdateComment = useCallback(
    async (newComment: PackageCommentLineItem) => {
      setIsSubmitting(() => true);
      await upsertPackageComments([newComment]);
      setIsSubmitting(() => false);
    },
    [comments],
  );

  useEffect(() => {
    if (comments) {
      const commentMap = new Map<PackageCommentType, string>();
      comments.forEach((comment) => {
        commentMap.set(comment.commentType, comment.comment);
      });
      setCommentMap(commentMap);
    }
  }, [comments]);

  const updateComment = async (
    commentType: PackageCommentType,
    comment: string,
    save: boolean,
  ) => {
    if (selectedProject && contractorPackage && reportId) {
      if (save) {
        const matchingPackageComment = comments.find(
          (c) => c.commentType === commentType,
        );
        if (matchingPackageComment) {
          await handleUpdateComment({
            ...matchingPackageComment,
            comment: comment,
          });
        } else {
          await handleUpdateComment(
            createDefaultPackageComment(
              selectedProject.id,
              contractorPackage.id,
              reportId,
              commentType,
              comment,
            ),
          );
        }
      }
      setCommentMap((currentValue) => {
        return new Map(currentValue.set(commentType, comment));
      });
    }
  };

  const loading =
    (isAuthProfileLoading ||
      isProjectLoading ||
      isReportDocumentsLoading ||
      isReportLoading) &&
    !isSubmitting;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = async () => {
    setActiveStep(activeStep - 1);
  };

  const onSubmit = async () => {
    if (contractorPackage) {
      const reportSubmission = createDefaultReportSubmission(
        contractorPackage.projectId,
        contractorPackage.id,
        reportId,
      );
      reportSubmission.reportStatus = ReportStatus.Complete;
      await createReportSubmission(reportSubmission);
      setShowSuccess(true);
    }
  };

  const wizardComponents = [];
  if (contractorPackage) {
    if (contractorPackage?.commitmentsHours?.length) {
      wizardComponents.push(
        <LabourHours
          isLoading={loading}
          project={selectedProject}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          userOrganisation={userOrganisation}
          contractorPackage={contractorPackage}
          commentMap={commentMap}
          reportId={reportId}
          reportDocuments={reportDocuments}
          updateComment={updateComment}
          createReportDocument={handleCreateReportDocument}
          updateReportDocument={handleUpdateReportDocument}
        />,
      );
    }
    if (contractorPackage?.commitmentsSpend?.length) {
      wizardComponents.push(
        <SocialSpends
          isLoading={loading}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          selectedProject={selectedProject}
          contractorPackageId={contractorPackage?.id}
          reportId={reportId}
          commentMap={commentMap}
          userOrganisation={userOrganisation}
          updateComment={updateComment}
          createReportDocument={handleCreateReportDocument}
          updateReportDocument={handleUpdateReportDocument}
          reportDocuments={reportDocuments}
        />,
      );
    }
    if (selectedProject?.localContents?.length) {
      wizardComponents.push(
        <PackageLidpSubmissionForm
          isLoading={loading}
          isSubmitting={isSubmitting}
          project={selectedProject}
          contractorPackageId={contractorPackage?.id}
          isPrimaryPackage={isPrimaryPackage}
          reportId={reportId}
          commentMap={commentMap}
          packageValue={
            isPrimaryPackage
              ? selectedProject.financial.totalValue || 0
              : contractorPackage.financial.totalValue || 0
          }
          packageType={contractorPackage.packageType}
          updateComment={updateComment}
          createReportDocument={handleCreateReportDocument}
          updateReportDocument={handleUpdateReportDocument}
          reportDocuments={reportDocuments}
        />,
      );
    }
  }

  wizardComponents.push(
    showSuccess ? (
      <ConfirmationStep />
    ) : (
      <ReportConfirmation
        loading={loading}
        project={selectedProject}
        reportId={reportId}
      />
    ),
  );

  const selectedWizardStep = wizardComponents[activeStep];

  const reportDate = stringToDate(reportId);

  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={formatReportDate(reportDate)}
        mainTitle={"Package Report Submission"}
      />

      <WizardSteps
        activeStep={activeStep}
        loading={loading}
        project={selectedProject}
        contractorPackage={contractorPackage}
      />

      <Grid
        item
        md={12}
        sx={{
          bgcolor: "white",
          border: "1px solid #E9EAEB",
          borderRadius: "12px",
          boxShadow: "0px 1px 2px 0px #0A0D120D",
          padding: "0 32px 20px 32px",
        }}
      >
        {selectedProject && contractorPackage ? selectedWizardStep : undefined}
        {!selectedProject || !contractorPackage ? (
          <LabourHours
            isLoading
            project={selectedProject}
            isSubmitting={isSubmitting}
            contractorPackage={contractorPackage}
            setIsSubmitting={setIsSubmitting}
            commentMap={commentMap}
            reportId={reportId}
            updateComment={updateComment}
            reportDocuments={reportDocuments}
            createReportDocument={handleCreateReportDocument}
            updateReportDocument={handleUpdateReportDocument}
          />
        ) : undefined}

        <Stack
          direction="row"
          spacing={1}
          justifyContent={`${activeStep > 0 ? "space-between" : "flex-end"}`}
          pt={2}
          width={"100%"}
        >
          {activeStep > 0 ? (
            <CustomButtonWithIcon
              loading={loading}
              disabled={showSuccess}
              status={isSubmitting}
              handleClick={handleBack}
              text="Back"
              isBack
              startIcon={<ArrowBackIosNewIcon />}
            />
          ) : null}

          {activeStep < wizardComponents.length - 1 ? (
            <CustomButtonWithIcon
              loading={loading}
              disabled={showSuccess}
              status={isSubmitting}
              handleClick={handleNext}
              text="Next"
              endIcon={<ArrowForwardIosIcon />}
            />
          ) : !showSuccess ? (
            <CustomButtonWithIcon
              loading={loading}
              status={isSubmitting}
              handleClick={onSubmit}
              text="Submit"
              bgColor={"#004EEB"}
              textColor={"#fff"}
              endIcon={<ArrowForwardIosIcon variant="priamry" />}
            />
          ) : null}
        </Stack>
      </Grid>
    </PageContainer>
  );
};
