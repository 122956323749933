import { downloadFile } from "@hooks/utils/useUpload";
import { Grid } from "@mui/material";
import {
  filterByKeys,
  generateTableHead,
  Table,
} from "@stories/organisms/Table/Table";
import {
  ActionCellUriToggle,
  getHeaderAndCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { DownloadIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { AnalyticsSocialSpendLevel3PageModelLineItem } from "social-pro-common/interfaces/analytics";
import { SocialSpendLineItem } from "social-pro-common/interfaces/socialSpend";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { getDocumentName } from "social-pro-common/utils/string";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { AnalyticsSpendLevel3TableRowSkeleton } from "./AnalyticsSpendLevel3TableRowSkeleton";

interface AnalyticsSpendLevel3TableProps {
  loading: boolean;
  searchTerm: string;
  socialSpendAnalytics?: AnalyticsSocialSpendLevel3PageModelLineItem;
  onFilteredDataChange: (filteredData: SocialSpendLineItem[]) => void;
}

const searchKeys: (keyof SocialSpendLineItem)[] = [
  "organisationName",
  "supplier",
];

export const AnalyticsSpendLevel3Table = ({
  loading,
  onFilteredDataChange,
  searchTerm,
  socialSpendAnalytics,
}: AnalyticsSpendLevel3TableProps) => {
  const [_, setIsDownloading] = useState(false);
  const handleAction = async (data: SocialSpendLineItem) => {
    if (!data.uri) return;
    setIsDownloading(true);
    await downloadFile(
      data.uri,
      getDocumentName(
        `${data.supplier}-${data.transactionDate.toLocaleDateString("en-GB")}`,
        data.uri,
      ),
    );
    setIsDownloading(false);
  };

  const cellActions = [
    {
      action: handleAction,
      adminOnly: true,
      icon: <DownloadIcon />,
      singleAction: true,
      title: "Download",
    },
  ];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...getHeaderAndCellStyles("center", false),
      field: "transactionDate",
      flex: 1.2,
      headerName: "Transaction Date",
      valueFormatter: (params) => params.value.toLocaleDateString(),
    },
    {
      ...getHeaderAndCellStyles(undefined, false),
      field: "organisationName",
      flex: 3,
      headerName: "Subcontractor Name",
    },
    {
      ...getHeaderAndCellStyles(undefined, false),
      field: "supplier",
      flex: 3,
      headerName: "Supplier",
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "invoiceValue",
      flex: 2,
      headerName: "Amount",
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCellUriToggle,
      cellRendererParams: {
        text: "Download",
      },
      field: "",
      headerName: "",
    },
  ]);

  const filteredData = useMemo(() => {
    if (!socialSpendAnalytics?.spend) return [];
    const searchFilter = filterByKeys(
      socialSpendAnalytics?.spend,
      searchKeys,
      colDefs,
    );
    return searchFilter(searchTerm);
  }, [socialSpendAnalytics?.spend, searchTerm]);

  useEffect(() => {
    onFilteredDataChange(filteredData);
  }, [filteredData, onFilteredDataChange]);

  return (
    <Grid item md={12}>
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={AnalyticsSpendLevel3TableRowSkeleton}
          noFullTableBorder
        />
      ) : (
        <Table<SocialSpendLineItem>
          columnDefs={colDefs}
          loading={loading}
          searchValue={searchTerm}
          noFullTableBorder
          context={{ cellActions }}
          data={socialSpendAnalytics?.spend || []}
          searchKeys={searchKeys}
        />
      )}
    </Grid>
  );
};
