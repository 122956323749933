import { Grid, Stack, Tooltip, Typography, Zoom } from "@mui/material";
import Box from "@mui/material/Box";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  getHeaderAndCellStyles,
  InputEditableCell,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useState } from "react";
import { ContractorPackageSetupLineItem } from "social-pro-common/interfaces/contractorPackage";
import { PackageLabourHourCommitmentLineItem } from "social-pro-common/interfaces/packageLabourHourCommitment";
import { PackageSocialSpendCommitmentLineItem } from "social-pro-common/interfaces/packageSocialSpendCommitment";
import * as yup from "yup";

import { PackageSocialRequirementTableRowSkeleton } from "./PackageSocialRequirementTableRowSkeleton";

type AllowedCommitmentTypes =
  | PackageSocialSpendCommitmentLineItem
  | PackageLabourHourCommitmentLineItem;

interface PackageSocialRequirementFormProps<T extends AllowedCommitmentTypes> {
  loading: boolean;
  commitmentLineItems: T[];
  isFinalStep: boolean;
  contractorPackageSetup: ContractorPackageSetupLineItem;
  handleNext: (contractorPackage: ContractorPackageSetupLineItem) => void;
  handleBack: () => void;
  title: string;
  calculateTargetValueFromTargetValueRealised: (
    value: number,
    multiplier: number,
  ) => number;
  calculateTargetValueRealised: (value: number, multiplier: number) => number;
  getOutcomeMultiplier: (item: T) => number;
  getDescription: (item: T) => string;
  getLabel: (item: T) => string;
  commitmentKey: "commitmentsHours" | "commitmentsSpend";
}

export const PackageSocialRequirementForm = <T extends AllowedCommitmentTypes>({
  calculateTargetValueFromTargetValueRealised,
  calculateTargetValueRealised,
  commitmentKey,
  commitmentLineItems,
  contractorPackageSetup,
  getDescription,
  getLabel,
  getOutcomeMultiplier,
  handleBack,
  handleNext,
  isFinalStep,
  loading,
  title,
}: PackageSocialRequirementFormProps<T>) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [fields, setFields] = useState(
    commitmentLineItems
      .map((c) => ({
        description: getDescription(c),
        id: c.id,
        initialValue: c.targetValue,
        label: getLabel(c),
        outcomeMultiplier: getOutcomeMultiplier(c),
        targetValue: c.targetValue,
        targetValueRealised: c.targetValueRealised,
        title: getLabel(c),
        type: yup.number().required().min(0),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  );

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const updatedCommitments = fields.map((field) => {
      const matchingItem = commitmentLineItems.find((c) => c.id === field.id);
      if (!matchingItem) {
        throw new Error(`Field not found for ID: ${field.id}`);
      }
      return {
        ...matchingItem,
        targetValue: field.targetValue,
        targetValueRealised: field.targetValueRealised,
      };
    });

    const updatedContractorPackage = {
      ...contractorPackageSetup,
      contractorPackage: {
        ...contractorPackageSetup.contractorPackage,
        [commitmentKey]: updatedCommitments,
      },
    };

    handleNext(updatedContractorPackage);
    setIsSubmitting(false);
  };

  const isLoading = loading || isSubmitting;

  const getUnit = (): string => {
    return commitmentKey === "commitmentsSpend" ? "$" : "hours";
  };

  const handleCellValueChange = (
    id: string,
    field: "targetValue" | "targetValueRealised",
    newValue: number,
  ) => {
    if (isNaN(newValue)) return;

    setFields((prevFields) => {
      return prevFields
        .map((f) =>
          f.id === id
            ? {
                ...f,
                [field]: newValue,
                targetValue:
                  field === "targetValueRealised"
                    ? calculateTargetValueFromTargetValueRealised(
                        newValue,
                        f.outcomeMultiplier,
                      )
                    : newValue,
                targetValueRealised:
                  field === "targetValue"
                    ? calculateTargetValueRealised(
                        newValue,
                        f.outcomeMultiplier,
                      )
                    : newValue,
              }
            : f,
        )
        .sort((a, b) => a.label.localeCompare(b.label));
    });
  };

  const colDefs: (ColDef | ColGroupDef)[] = [
    {
      ...getHeaderAndCellStyles(undefined, false),
      cellRenderer: (params: any) => (
        <Tooltip title={params.data.description} TransitionComponent={Zoom}>
          <Typography>{params.data.label}</Typography>
        </Tooltip>
      ),
      field: "label",
      flex: 5,
      headerName: "Commitment",
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: InputEditableCell,
      cellRendererParams: {
        field: "targetValue",
        unit: "%",
      },
      field: "targetValue",
      headerName: "Target",
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: InputEditableCell,
      cellRendererParams: {
        field: "targetValueRealised",
        unit: getUnit(),
      },
      field: "targetValueRealised",
      headerName: "Target Outcome",
    },
  ];

  return (
    <Box pt={3} pb={5} pl={5} pr={5}>
      <Grid item xs={12} md={12}>
        <Grid item mb={3}>
          <SubTitle title={title} />
        </Grid>
        {loading ? (
          <TableSkeleton
            tableHead={generateTableHead(colDefs)}
            rows={PackageSocialRequirementTableRowSkeleton}
          />
        ) : (
          <Table<T>
            columnDefs={colDefs}
            loading={loading}
            data={fields as unknown as T[]}
            context={{
              handleCellValueChange,
              isLoading,
            }}
          />
        )}

        <Grid
          item
          md={12}
          mt={2}
          sx={{ display: "flex", justifyContent: "end" }}
        >
          <Stack direction="row" spacing={1}>
            <StyledButton
              loading={isLoading}
              disabled={isLoading}
              onClick={handleBack}
              variant="outlined"
            >
              Back
            </StyledButton>
            <StyledButton
              loading={isLoading}
              disabled={isLoading}
              variant="contained"
              onClick={handleSubmit}
            >
              {isFinalStep ? "Submit" : "Next"}
            </StyledButton>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};
