import { Skeleton, TableCell, TableRow } from "@mui/material";
import { TableHeaderCell } from "social-pro-common/interfaces/tables";

export const TABLE_HEAD: TableHeaderCell[] = [
  { align: "left", id: "contractorName", label: "Subcontractor", width: "30%" },
  {
    id: "targetValue",
    label: "Target ($)",
    width: "15%",
  },
  {
    id: "totalReportedValue",
    label: "Spend to Date ($)",
    width: "15%",
  },
  {
    id: "monthReportValue",
    label: "This Month",
    width: "15%",
  },
  {
    id: "progress",
    label: "Progress to Target",
    width: "15%",
  },
  { id: "action", label: "Actions", width: "10%" },
];

export const AnalyticsSpendLevel2TableRowSkeleton = () => {
  return (
    <TableRow>
      {Array.from({ length: 6 }, (_, index) => (
        <TableCell
          key={index}
          align={index === 0 ? "left" : "center"}
          sx={{
            paddingBottom: "10px",
            paddingTop: "10px",
          }}
        >
          <Skeleton animation="wave" />
        </TableCell>
      ))}
    </TableRow>
  );
};
