import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Tooltip, Zoom } from "@mui/material";
import { CustomSwitch } from "@stories/pages/Contractor/Forecast/CustomSwitch";
import { CustomCellRendererProps } from "ag-grid-react";
import { debounce } from "lodash";
import { useState } from "react";
import { ForecastUnitType } from "social-pro-common/entities/forecastData";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  ForecastDataLineItem,
  ForecastModelLineItem,
} from "social-pro-common/interfaces/forecastData";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

interface CellProps extends CustomCellRendererProps {
  context: {
    contractorPackage?: ContractorPackageLineItem;
    disabled: boolean;
    project?: ProjectLineItem;
    forecastModel: ForecastModelLineItem;
    updateForecastModel: (forecast: ForecastModelLineItem) => void;
    editForecastModel: (forecast: ForecastModelLineItem) => void;
  };
}

const findMatchingCommitment = (
  units: ForecastUnitType,
  isPrimaryPackage: boolean,
  project: ProjectLineItem,
  contractorPackage: ContractorPackageLineItem | undefined,
  data: any,
) => {
  if (units === ForecastUnitType.Hour) {
    return isPrimaryPackage
      ? project.commitmentsHours.find(
          (c) => c.id === data.projectLabourHourCommitmentId,
        )
      : contractorPackage?.commitmentsHours.find(
          (c) =>
            c.projectLabourHourCommitmentId ===
            data.projectLabourHourCommitmentId,
        );
  }

  return isPrimaryPackage
    ? project.commitmentsSpend.find(
        (c) => c.id === data.projectSocialSpendCommitmentId,
      )
    : contractorPackage?.commitmentsSpend.find(
        (c) =>
          c.projectSocialSpendCommitmentId ===
          data.projectSocialSpendCommitmentId,
      );
};

const TotalTargetCell = ({
  context: { contractorPackage, project },
  data,
}: CellProps) => {
  const units = data.units;
  if (!project) {
    return null;
  }

  const isPrimaryPackage = contractorPackage?.id === project?.id;

  const matchingCommitment = findMatchingCommitment(
    units,
    isPrimaryPackage,
    project,
    contractorPackage,
    data,
  );

  if (!matchingCommitment) {
    return null;
  }

  const targetTotal = matchingCommitment.targetValueRealised;
  const target = matchingCommitment?.targetValueRealised || targetTotal;

  return (
    <>
      {units == ForecastUnitType.Spend ? "$ " : ""}
      {formatDecimalPlaces(target, 0)}
    </>
  );
};

const RemainingTargetCell = ({
  context: { contractorPackage, project },
  data,
}: CellProps) => {
  const units = data.units;
  if (!project) {
    return null;
  }

  const isPrimaryPackage = contractorPackage?.id === project?.id;

  const matchingCommitment = findMatchingCommitment(
    units,
    isPrimaryPackage,
    project,
    contractorPackage,
    data,
  );

  if (!matchingCommitment) {
    return null;
  }

  const targetTotal = matchingCommitment.targetValueRealised;
  const reportedTotal = matchingCommitment.achievedValue;
  const remainingTotal = Math.max(0, targetTotal - reportedTotal);
  const target = matchingCommitment?.targetValueRealised || targetTotal;

  const reported = data.data.reduce(
    (acc: number, d: ForecastDataLineItem) => acc + (d.value || 0),
    0,
  );

  const remaining = Math.max(
    0,
    matchingCommitment ? target - reported : remainingTotal,
  );

  return (
    <>
      {units == ForecastUnitType.Spend ? "$ " : ""}
      {formatDecimalPlaces(remaining, 0)}
    </>
  );
};

const ReportedTargetCell = ({ data }: CellProps) => {
  const units = data.units;

  const reported = data.reduce(
    (acc: number, d: ForecastDataLineItem) => acc + (d.value || 0),
    0,
  );

  return (
    <>
      {units == ForecastUnitType.Spend ? "$ " : ""}
      {formatDecimalPlaces(reported)}
    </>
  );
};

const DisplayDataCell = ({
  context: { disabled, updateForecastModel },
  data,
}: CellProps) => {
  const [checked, setChecked] = useState(data.active || false);

  const debouncedUpdate = debounce(async (updatedData) => {
    try {
      await updateForecastModel(updatedData);
    } catch (error) {
      setChecked(!updatedData.active);
    }
  }, 300);

  const handleUpdate = () => {
    const newToggleValue = !checked;
    setChecked(newToggleValue);
    debouncedUpdate({ ...data, active: newToggleValue });
  };

  return (
    <>
      <CustomSwitch
        colour={data.colour}
        disabled={disabled}
        checked={checked}
        handleChange={handleUpdate}
      />
    </>
  );
};

const ToolsCell = ({
  context: { disabled, editForecastModel },
  data,
}: CellProps) => {
  return (
    <Box display="flex" alignItems="center">
      <Tooltip placement="top" title="Edit" TransitionComponent={Zoom}>
        <IconButton
          color="primary"
          size="medium"
          disabled={disabled}
          onClick={() => editForecastModel(data)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export {
  DisplayDataCell,
  RemainingTargetCell,
  ReportedTargetCell,
  ToolsCell,
  TotalTargetCell,
};
