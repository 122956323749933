import { Grid } from "@mui/material";
import {
  ColDef,
  ColGroupDef,
  ITooltipParams,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { ArchiveProjectIcon, EditProjectIcon } from "assets/constants";
import { useEffect, useMemo, useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  PackageLocalContentLineItem,
  aggregateLocalContentItems,
} from "social-pro-common/interfaces/packageLocalContent";
import {
  calculateLocalContentPackageAnzTotal,
  calculateLocalContentPackageContractTotal,
  calculateLocalContentPackageValueTotal,
} from "social-pro-common/utils/calc";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { AnalyticsLocalContentLevel2TableRowSkeleton } from "./AnalyticsLocalContentLevel2TableRowSkeleton";
import {
  filterByKeys,
  generateTableHead,
  getCellStyle,
  isTotalRow,
  Table,
} from "../Table/Table";
import { ActionCell, getHeaderAndCellStyles } from "../Table/TableCells";
import TableSkeleton from "../Table/TableSkeleton";

interface AnalyticsLocalContentLevel2TableProps {
  loading: boolean;
  showActions?: boolean;
  isPrimaryPackage: boolean;
  contractorPackage?: ContractorPackageLineItem;
  subPackage?: ContractorPackageLineItem;
  localContents: PackageLocalContentLineItem[];
  tabIndex: number;
  searchTerm: string;
  marginTop?: number;
  withoutBorder?: boolean;
  handleEditLocalContent?: (
    localContent: PackageLocalContentLineItem,
  ) => Promise<void>;
  handleDeleteLocalContent?: (
    localContent: PackageLocalContentLineItem,
  ) => Promise<void>;
  onFilteredDataChange?: (filteredData: PackageLocalContentLineItem[]) => void;
}

const searchKeys: (keyof PackageLocalContentLineItem)[] = [
  "itemDescription",
  "manufacturer",
  "supplier",
];

export const AnalyticsLocalContentLevel2Table = ({
  handleDeleteLocalContent,
  handleEditLocalContent,
  loading,
  localContents,
  marginTop,
  onFilteredDataChange,
  searchTerm,
  showActions,
  subPackage,
  tabIndex,
  withoutBorder = false,
}: AnalyticsLocalContentLevel2TableProps) => {
  const showActual = tabIndex === 1;

  const itemsToDisplay = showActual
    ? localContents
    : aggregateLocalContentItems(subPackage?.localContent || []);

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      cellStyle: (params) => getCellStyle(params, "right"),
      colSpan: (params) => (isTotalRow(params) ? 3 : 1),
      field: "itemDescription",
      headerClass: "left-table-header",
      headerName: "Item Description",
      maxWidth: 250,
      minWidth: 250,
      tooltipField: "itemDescription",
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "manufacturer",
      headerClass: "left-table-header",
      headerName: "Manufacturer",
      maxWidth: 250,
      minWidth: 250,
      tooltipField: "manufacturer",
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "supplier",
      headerClass: "left-table-header",
      headerName: "Supplier",
      maxWidth: 250,
      minWidth: 250,
      tooltipField: "supplier",
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "value",
      headerName: "Value",
      maxWidth: 120,
      minWidth: 120,
      tooltipValueGetter: (p: ITooltipParams) =>
        `$${formatDecimalPlaces(p.value, 0)}`,
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "steelMass",
      headerName: "Steel Mass",
      maxWidth: 150,
      minWidth: 150,
      valueFormatter: (params) => {
        return params.value != null &&
          params.value !== "-" &&
          params.value !== 0
          ? `${formatDecimalPlaces(params.value, 2)}T`
          : `${params.value}`;
      },
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "localValueAdd",
      headerName: "Local Value Add",
      maxWidth: 150,
      minWidth: 150,
      valueFormatter: (params) => {
        return params.value != null && params.value !== "-"
          ? `${formatDecimalPlaces(params.value, 2)}%`
          : `${params.value}`;
      },
    },
    {
      cellStyle: {
        ...getHeaderAndCellStyles("right", false).cellStyle,
        backgroundColor: "#f4f4f4",
      },
      field: "importValueAdd",
      headerClass: getHeaderAndCellStyles("right", false).headerClass,
      headerName: "Import Value Add",
      maxWidth: 150,
      minWidth: 150,
      valueFormatter: (params) => {
        return params.value != null && params.value !== "-"
          ? `${formatDecimalPlaces(params.value, 2)}%`
          : `${params.value}`;
      },
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "contractContent",
      headerName: "Contract Content",
      maxWidth: 150,
      minWidth: 150,
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 4)}%`,
    },
    {
      cellStyle: {
        ...getHeaderAndCellStyles("right", false).cellStyle,
        backgroundColor: "#f4f4f4",
      },
      field: "anzValueAdd",
      headerClass: getHeaderAndCellStyles("right", false).headerClass,
      headerName: "ANZ Value-Add Activity",
      maxWidth: 150,
      minWidth: 150,
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 4)}%`,
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "smeCount",
      headerName: "SME Count",
      maxWidth: 100,
      minWidth: 100,
      valueFormatter: (params) => `${params.value ? params.value : "-"}`,
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "supplierCount",
      headerName: "Supplier Count",
      maxWidth: 120,
      minWidth: 120,
      valueFormatter: (params) => `${params.value ? params.value : "-"}`,
    },
  ]);

  const autoSizeStrategy = useMemo<SizeColumnsToContentStrategy>(
    () => ({
      type: "fitCellContents",
    }),
    [],
  );

  useEffect(() => {
    if (showActions) {
      _setColDefs((prev) => {
        return [
          ...prev,
          {
            ...getHeaderAndCellStyles("center", false),
            cellRenderer: ActionCell,
            headerName: "",
            minWidth: 50,
          },
        ];
      });
    }
  }, []);

  const cellActions = [
    {
      action: handleEditLocalContent,
      icon: <EditProjectIcon />,
      title: "Edit Local Content",
    },
    {
      action: handleDeleteLocalContent,
      icon: <ArchiveProjectIcon />,
      title: "Delete Local Content",
    },
  ];

  const contractTotal =
    calculateLocalContentPackageContractTotal(itemsToDisplay);

  const anzTotalTotal = calculateLocalContentPackageAnzTotal(itemsToDisplay);

  const valueTotal = calculateLocalContentPackageValueTotal(itemsToDisplay);

  const totalSme = itemsToDisplay.reduce(
    (acc, lc) => acc + (lc.smeCount ?? 0),
    0,
  );
  const totalSuppliers = itemsToDisplay.reduce(
    (acc, lc) => acc + (lc.supplierCount ?? 0),
    0,
  );

  const totalRow = {
    anzValueAdd: anzTotalTotal,
    contractContent: contractTotal,
    importValueAdd: "-",
    isTotalRow: true,
    itemDescription: "Total Local Content",
    localValueAdd: "-",
    smeCount: totalSme,
    steelMass: "-",
    supplier: "Total Local Content",
    supplierCount: totalSuppliers,
    value: valueTotal,
  };

  const filteredData = useMemo(() => {
    const searchFilter = filterByKeys(itemsToDisplay, searchKeys, colDefs);
    return searchFilter(searchTerm);
  }, [itemsToDisplay, searchTerm]);

  useEffect(() => {
    if (!onFilteredDataChange) return;
    onFilteredDataChange(filteredData);
  }, [filteredData, onFilteredDataChange]);

  return (
    <Grid item xs={12} md={12} mt={marginTop || 0}>
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          noFullTableBorder={withoutBorder}
          rows={AnalyticsLocalContentLevel2TableRowSkeleton}
        />
      ) : null}
      {!loading ? (
        <Table<PackageLocalContentLineItem>
          columnDefs={colDefs}
          loading={loading}
          searchValue={searchTerm}
          autoSizeStrategy={autoSizeStrategy}
          data={itemsToDisplay}
          noFullTableBorder={withoutBorder}
          context={{ cellActions, loading, subPackage }}
          searchKeys={searchKeys}
          pinnedBottomRowData={
            itemsToDisplay.length ? ([totalRow] as ColDef[]) : []
          }
        />
      ) : null}
    </Grid>
  );
};
