import { Grid } from "@mui/material";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import { getHeaderAndCellStyles } from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef } from "ag-grid-community";
import { useMemo } from "react";
import { ProfileProjectAccessLevel } from "social-pro-common/entities/profileProjectAccess";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import { ProfileProjectAccessLineItem } from "social-pro-common/interfaces/profileProjectAccess";
import { ProjectDetailLineItem } from "social-pro-common/interfaces/project";

import { ProfileGroupTableRowSkeleton } from "./ProfileGroupTableRowSkeleton";
import ProjectAccessRenderer from "./ProjectGroupTableRow";

interface ProfileGroupTableProps {
  loading: boolean;
  projects: ProjectDetailLineItem[];
  profiles: ProfileLineItem[];
  searchTerm: string;
  selectedAccessLevel: ProfileProjectAccessLevel | null;
  selectedProject: ProjectDetailLineItem | null;
  profileProjectAccess: ProfileProjectAccessLineItem[];
  handleUpdateAccess: (
    profileProjectAccess: ProfileProjectAccessLineItem,
  ) => void;
}

export const ProfileGroupTable = ({
  handleUpdateAccess,
  loading,
  profileProjectAccess,
  profiles,
  projects,
  searchTerm,
  selectedAccessLevel,
  selectedProject,
}: ProfileGroupTableProps) => {
  const columnDefs = useMemo<ColDef[]>(() => {
    const projectColumns = selectedProject
      ? [
          {
            ...getHeaderAndCellStyles("left"),
            cellRenderer: ProjectAccessRenderer,
            cellRendererParams: {
              handleUpdateAccess,
              profileProjectAccess,
              projectId: selectedProject.id,
              selectedAccessLevel,
            },
            field: selectedProject.id,
            headerName: selectedProject.projectName,
          },
        ]
      : projects?.map((project) => ({
          ...getHeaderAndCellStyles("left"),
          cellRenderer: ProjectAccessRenderer,
          cellRendererParams: {
            handleUpdateAccess,
            profileProjectAccess,
            projectId: project.id,
            selectedAccessLevel,
          },
          field: project.id,
          headerName: project.projectName,
        }));

    return [
      {
        ...getHeaderAndCellStyles("left", false),
        field: "name",
        flex: 2,
        headerName: "Name",
        valueFormatter: (params) => params.data.name,
      },
      ...projectColumns,
    ];
  }, [
    projects,
    selectedProject,
    profileProjectAccess,
    selectedAccessLevel,
    handleUpdateAccess,
  ]);

  const rowData: any = profiles.map((profile: ProfileLineItem) => {
    const row: { [key: string]: any } = {
      id: profile.id,
      name: profile.name,
    };

    (selectedProject ? [selectedProject] : projects).forEach((project) => {
      const access = profileProjectAccess.filter(
        (ppa) => ppa.profileId === profile.id && ppa.projectId === project.id,
      );

      row[project.id] =
        access.length > 0
          ? {
              id: access[0].id,
              level: access[0].accessLevel,
            }
          : {
              level: ProfileProjectAccessLevel.NoAccess,
            };
    });

    return { ...profile, rowData };
  });

  return (
    <>
      <Grid item xs={12} md={12}>
        {loading ? (
          <TableSkeleton
            tableHead={generateTableHead(columnDefs)}
            noFullTableBorder
            rows={ProfileGroupTableRowSkeleton}
          />
        ) : (
          <Table<ProfileLineItem>
            columnDefs={columnDefs}
            loading={loading}
            context={{
              handleUpdateAccess,
            }}
            noFullTableBorder
            searchValue={searchTerm}
            searchKeys={["name"]}
            data={(rowData as ProfileLineItem[]) || []}
          />
        )}
      </Grid>
    </>
  );
};
