import { Button, Skeleton, Typography, styled } from "@mui/material";

interface CustomButtonProps {
  bgColor?: string;
  disabled?: boolean;
  handleClick?: () => void;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  isBack?: boolean;
  loading?: boolean;
  status?: boolean;
  text: string;
  textColor?: string;
}

const StyledButton = styled(Button)<{
  bgColor?: string;
  textColor?: string;
  isBack?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px 12px;
  border: 1px solid #d5d7da;
  border-radius: 8px;
  text-transform: capitalize;
  background-color: ${({ bgColor, textColor }) =>
    textColor ? bgColor || "#fff" : "#fff"};
  color: ${({ textColor }) => (textColor ? "#fff" : "#414651")};
  flex-direction: ${({ isBack }) => (isBack ? "row-reverse" : "row")};

  &:hover {
    background-color: ${({ bgColor, textColor }) =>
      textColor ? bgColor || "#fff" : "#fff"};
  }

  &:disabled {
    background-color: white;
    color: #414651;
    cursor: not-allowed;
    opacity: 60%;
  }
`;

const StyledTypography = styled(Typography)`
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`;

export const CustomButtonWithIcon: React.FC<CustomButtonProps> = ({
  bgColor,
  disabled,
  endIcon,
  handleClick,
  isBack = false,
  loading = false,
  startIcon,
  status,
  text,
  textColor,
}) => {
  return loading ? (
    <Skeleton animation="wave" width={70} height={50} />
  ) : (
    <StyledButton
      disableRipple
      bgColor={bgColor}
      textColor={textColor}
      isBack={isBack}
      disabled={loading || status || disabled}
      onClick={handleClick}
    >
      {startIcon}
      <StyledTypography>{text}</StyledTypography>
      {endIcon}
    </StyledButton>
  );
};
