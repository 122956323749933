import { Skeleton, TableCell, TableRow } from "@mui/material";
import { DropdownIcon } from "assets/constants";

export const PolicyRowSkeleton = () => {
  return (
    <TableRow>
      <TableCell
        sx={{
          paddingBottom: "10px",
          paddingTop: "10px",
        }}
      >
        <Skeleton animation="wave" width={500} />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          paddingBottom: "10px",
          paddingTop: "10px",
        }}
      >
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          display: "flex",
          justifyContent: "center",
          paddingBottom: "10px",
          paddingTop: "10px",
        }}
      >
        <Skeleton animation="wave" variant="circular">
          <DropdownIcon />
        </Skeleton>
      </TableCell>
    </TableRow>
  );
};
