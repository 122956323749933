import {
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DashboardTableItemLineItem } from "social-pro-common/interfaces/dashboard";

import {
  StyledHeading,
  StyledSubHeading,
  StyledTableCell,
  StyledTableHeadCell,
} from "./components";

interface TableProps {
  heading: string;
  loading: boolean;
  data: DashboardTableItemLineItem[];
  subTitle?: string;
}

export const DashboardSummaryTableLabourHour: React.FC<TableProps> = ({
  data,
  heading,
  loading,
  subTitle,
}) => {
  if (loading) {
    return <Skeleton variant="rectangular" height={500} />;
  }

  const sortedData = data.sort((a, b) => {
    return a.targetName.localeCompare(b.targetName);
  });
  return (
    <Stack direction="column" gap={2}>
      {loading ? (
        <Stack direction="column" gap={1}>
          <StyledHeading>{heading}</StyledHeading>
          <StyledSubHeading>{subTitle || ""}</StyledSubHeading>
        </Stack>
      ) : (
        <Stack direction="column" gap={1}>
          <StyledHeading>{heading}</StyledHeading>
          <StyledSubHeading>{subTitle || ""}</StyledSubHeading>
        </Stack>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell align={"left"}>Target</StyledTableHeadCell>
              <StyledTableHeadCell align={"right"}>
                Actual Hours
              </StyledTableHeadCell>
              <StyledTableHeadCell align={"right"}>
                Target Hours
              </StyledTableHeadCell>
              <StyledTableHeadCell align={"right"}>
                Overall %
              </StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <StyledTableCell align={"left"}>
                  {row.targetName}
                </StyledTableCell>
                <StyledTableCell align={"right"}>{row.actual}</StyledTableCell>
                <StyledTableCell align={"right"}>{row.target}</StyledTableCell>
                <StyledTableCell align={"right"}>
                  {row.pcProgress}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export const DashboardSummaryTableSocialSpend: React.FC<TableProps> = ({
  data,
  heading,
}) => {
  const sortedData = data.sort((a, b) => {
    return a.targetName.localeCompare(b.targetName);
  });
  return (
    <Stack direction="column" gap={2}>
      <StyledHeading>{heading}</StyledHeading>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell align={"left"}>Target</StyledTableHeadCell>
              <StyledTableHeadCell align={"right"}>
                Actual Spend
              </StyledTableHeadCell>
              <StyledTableHeadCell align={"right"}>
                Target Spend
              </StyledTableHeadCell>
              <StyledTableHeadCell align={"right"}>
                Overall %
              </StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <StyledTableCell align={"left"}>
                  {row.targetName}
                </StyledTableCell>
                <StyledTableCell align={"right"}>{row.actual}</StyledTableCell>
                <StyledTableCell align={"right"}>{row.target}</StyledTableCell>
                <StyledTableCell align={"right"}>
                  {row.pcProgress}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export const DashboardSummaryTableLocalContent: React.FC<TableProps> = ({
  data,
  heading,
}) => {
  //TODO: Fix this hack - Normalise data from server
  const firstTen = data
    .sort((a, b) => {
      return parseFloat(a.actual) - parseFloat(b.actual);
    })
    .slice(0, 10);
  return (
    <Stack direction="column" gap={2}>
      <StyledHeading>{heading}</StyledHeading>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell align={"left"}>Target</StyledTableHeadCell>
              <StyledTableHeadCell align={"right"}>
                Value Reported
              </StyledTableHeadCell>
              <StyledTableHeadCell align={"right"}>
                ANZ Value Add %
              </StyledTableHeadCell>
              <StyledTableHeadCell align={"right"}>
                Target Local Content %
              </StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {firstTen.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <StyledTableCell align={"left"}>
                  {row.targetName}
                </StyledTableCell>
                <StyledTableCell align={"right"}>{row.actual}</StyledTableCell>
                <StyledTableCell align={"right"}>{row.target}</StyledTableCell>
                <StyledTableCell align={"right"}>
                  {row.pcProgress}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
