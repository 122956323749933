import { Grid } from "@mui/material";
import SupportingDocumentDialog from "@stories/molecules/SupportingDocumentDialog/SupportingDocumentDialog";
import {
  ActionCell,
  getHeaderAndCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useEffect, useMemo, useState } from "react";
import { AnalyticsLabourHoursLevel4PageModelLineItem } from "social-pro-common/interfaces/analytics";
import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";
import { formatReportDate, stringToDate } from "social-pro-common/utils/date";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { AnalyticsHoursLevel4TableRowSkeleton } from "./AnalyticsHoursLevel4TableRowSkeleton";
import { filterByKeys, generateTableHead, Table } from "../../Table/Table";

interface AnalyticsHoursLevel4TableProps {
  loading: boolean;
  labourHoursAnalytics?: AnalyticsLabourHoursLevel4PageModelLineItem;
  targetId: string;
  reportId: string;
  mode?: string;
  searchTerm: string;
  onFilteredDataChange: (filteredData: LabourHourForTargetLineItem[]) => void;
}
const searchKeys: (keyof LabourHourForTargetLineItem)[] = [
  "organisationName",
  "employeeName",
];
export const AnalyticsHoursLevel4Table = ({
  labourHoursAnalytics,
  loading,
  onFilteredDataChange,
  searchTerm,
}: AnalyticsHoursLevel4TableProps) => {
  const [selectedLabourHour, setSelectedLabourHour] =
    useState<LabourHourForTargetLineItem>();

  const handleClose = () => {
    setSelectedLabourHour(undefined);
  };

  const handleAction = (labourHour: LabourHourForTargetLineItem) => {
    setSelectedLabourHour(labourHour);
  };
  const cellActions = [
    {
      action: handleAction,
      adminOnly: true,
      singleAction: true,
      title: "View",
    },
  ];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...getHeaderAndCellStyles(),
      field: "reportId",
      flex: 1.5,
      headerName: "Report Date",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params: any) =>
        formatReportDate(stringToDate(params.value)),
    },
    {
      ...getHeaderAndCellStyles(),
      field: "employeeName",
      flex: 2,
      headerName: "Name or Code",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params: any) => {
        return `${params.data.employeeName} ${params.data.employeeIdentifier ? `(${params.data.employeeIdentifier})` : ""}`;
      },
    },
    {
      ...getHeaderAndCellStyles(),
      field: "organisationName",
      flex: 2,
      headerName: "Subcontractor Name",
      onCellClicked: (params) => handleAction(params.data),
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "previousHoursWorked",
      flex: 2,
      headerName: "Previous Hours Worked",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "currentHoursWorked",
      flex: 2,
      headerName: "Current Hours Worked",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "totalHoursWorked",
      flex: 2,
      headerName: "Total Hours Worked",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "totalHoursStudied",
      flex: 2,
      headerName: "Total Hours Studied",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCell,
      cellRendererParams: {
        text: "View",
      },
      field: "",
      headerName: "",
    },
  ]);

  const filteredData = useMemo(() => {
    if (!labourHoursAnalytics?.labourHours) return [];
    const searchFilter = filterByKeys(
      labourHoursAnalytics?.labourHours,
      searchKeys,
      colDefs,
    );
    return searchFilter(searchTerm);
  }, [labourHoursAnalytics?.labourHours, searchTerm]);

  useEffect(() => {
    onFilteredDataChange(filteredData);
  }, [filteredData, onFilteredDataChange]);

  return (
    <>
      <Grid item xs={12} md={12}>
        {loading ? (
          <TableSkeleton
            tableHead={generateTableHead(colDefs)}
            rows={AnalyticsHoursLevel4TableRowSkeleton}
            noFullTableBorder
          />
        ) : null}
        {!loading ? (
          <Table<LabourHourForTargetLineItem>
            columnDefs={colDefs}
            loading={loading}
            context={{ cellActions }}
            noFullTableBorder
            data={labourHoursAnalytics?.labourHours || []}
            searchKeys={searchKeys}
            searchValue={searchTerm}
          />
        ) : null}
      </Grid>

      {selectedLabourHour ? (
        <SupportingDocumentDialog
          open
          handleClose={handleClose}
          labourHour={selectedLabourHour}
        />
      ) : null}
    </>
  );
};
