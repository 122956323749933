import { Grid } from "@mui/material";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  CellAction,
  getHeaderAndCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { EditProjectIcon } from "assets/constants";
import { memo, useMemo, useState } from "react";
import { ForecastUnitType } from "social-pro-common/entities/forecastData";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ForecastModelLineItem } from "social-pro-common/interfaces/forecastData";
import { ProjectLineItem } from "social-pro-common/interfaces/project";

import {
  DisplayDataCell,
  RemainingTargetCell,
  TotalTargetCell,
} from "./ForecastTableRow";
import { ForecastTableRowSkeleton } from "./ForecastTableRowSkeleton";

interface ForecastTableProps {
  loading: boolean;
  disabled: boolean;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  forecastModels: ForecastModelLineItem[];
  chartType: number;
  updateForecastModel: (forecast: ForecastModelLineItem) => void;
  editForecastModel: (forecast: ForecastModelLineItem) => void;
}

interface TableContextProps {
  contractorPackage?: ContractorPackageLineItem;
  disabled: boolean;
  project?: ProjectLineItem;
  updateForecastModel: (forecast: ForecastModelLineItem) => void;
  cellActions: CellAction[];
}

export const ForecastTable = ({
  chartType,
  contractorPackage,
  disabled,
  editForecastModel,
  forecastModels,
  loading,
  project,
  updateForecastModel,
}: ForecastTableProps) => {
  const cellActions = [
    {
      action: editForecastModel,
      icon: <EditProjectIcon />,
      singleAction: true,
      title: "Edit",
    },
  ];
  const MemoizedToggle = memo(DisplayDataCell);
  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: MemoizedToggle,
      field: "value",
      flex: 0.7,
      headerName: "Display",
      minWidth: 100,
    },
    {
      ...getHeaderAndCellStyles("left", false),
      field: "modelName",
      flex: 2,
      headerName: "Target",
      minWidth: 250,
    },
    {
      cellRenderer: TotalTargetCell,
      ...getHeaderAndCellStyles("right", false),
      field: "totalTarget",
      headerName: `Total Target`,
      minWidth: 180,
    },
    {
      cellRenderer: TotalTargetCell,
      ...getHeaderAndCellStyles("right", false),
      field: "actual",
      headerName: `Actual`,
      minWidth: 180,
    },
    {
      cellRenderer: RemainingTargetCell,
      ...getHeaderAndCellStyles("right", false),
      field: "reported",
      headerName: `Projected `,
      minWidth: 180,
    },
    {
      cellRenderer: RemainingTargetCell,
      ...getHeaderAndCellStyles("right", false),
      field: "remaining",
      headerName: `Remaining`,
      minWidth: 180,
    },

    {
      cellRenderer: ActionCell,
      ...getHeaderAndCellStyles("center", false),
      field: "",
      flex: 0.7,
      headerName: "",
      minWidth: 80,
    },
  ]);

  const isPrimaryPackage = project?.id == contractorPackage?.id;

  const filteredForecastData = useMemo(
    () =>
      chartType === 0
        ? forecastModels
            .filter((f) => f.units === ForecastUnitType.Hour)
            .sort((a, b) => a.modelName.localeCompare(b.modelName))
        : forecastModels
            .filter((f) => f.units === ForecastUnitType.Spend)
            .sort((a, b) => a.modelName.localeCompare(b.modelName)),
    [forecastModels, chartType],
  );

  const commitments = useMemo(
    () =>
      chartType === 1
        ? isPrimaryPackage
          ? project?.commitmentsHours.map((c) => c.id) || []
          : contractorPackage?.commitmentsHours.map((c) => c.id) || []
        : isPrimaryPackage
          ? project?.commitmentsSpend.map((c) => c.id) || []
          : contractorPackage?.commitmentsSpend.map((c) => c.id) || [],
    [chartType, isPrimaryPackage, project, contractorPackage],
  );

  const combinedTargets = useMemo(
    () =>
      filteredForecastData.filter((f) => {
        const matchingCommitment = commitments.find((c: string) => c === f.id);
        return !matchingCommitment;
      }),
    [filteredForecastData, commitments],
  );

  const allTargets = useMemo(
    () =>
      filteredForecastData.filter((f) => {
        const matchingCommitment = commitments.find((c: string) => c === f.id);
        return matchingCommitment;
      }),
    [filteredForecastData, commitments],
  );

  const finalTargets = useMemo(
    () => [...combinedTargets, ...allTargets],
    [combinedTargets, allTargets],
  );

  return (
    <Grid item xs={12} md={12} mt={2} className="oddrowbg">
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={ForecastTableRowSkeleton}
        />
      ) : (
        <Table<ForecastModelLineItem>
          columnDefs={colDefs}
          loading={loading}
          data={finalTargets || []}
          context={
            {
              cellActions,
              contractorPackage,
              disabled,
              project,
              updateForecastModel,
            } as TableContextProps
          }
        />
      )}
    </Grid>
  );
};
