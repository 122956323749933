import { Skeleton, Stack } from "@mui/material";

import { StyledSubHeading, StyledTitle } from "./components";

interface PageHeadingProps {
  subHeading?: string;
  title: string;
  loading: boolean;
  handleBack?: () => void;
  isSubheading?: boolean;
  fontsizes?: number[];
  headerGap?: number;
}

export const PageHeading = ({
  fontsizes = [24, 16],
  headerGap = 1,
  loading,
  subHeading,
  title,
}: PageHeadingProps) => {
  if (loading) {
    return (
      <Stack direction="row" spacing={2}>
        {/* {handleBack ? (
          <Skeleton variant="circular" animation="wave">
            <IconButton
              size="large"
              sx={{
                color: "black",
                height: "auto",
                padding: "5px",
                width: "auto",
              }}
            >
              <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "30px" }} />
            </IconButton>
          </Skeleton>
        ) : null} */}
        <Stack direction="column" gap={1}>
          {title.length ? (
            <Skeleton animation="wave">
              <StyledTitle variant="h2" fontsizes={fontsizes}>
                Loading...
              </StyledTitle>
            </Skeleton>
          ) : null}
          {subHeading?.length ? (
            <Skeleton animation="wave">
              <StyledTitle variant="h2" fontsizes={fontsizes}>
                Loading...
              </StyledTitle>
            </Skeleton>
          ) : null}
        </Stack>
      </Stack>
    );
  }
  return (
    <Stack direction="row" spacing={2}>
      {/* {handleBack ? (
        <Tooltip TransitionComponent={Zoom} placement="top" title="Back">
          <IconButton
            data-test-id={`back-button-${isSubheading ? "subheading" : "heading"}`}
            size="large"
            onClick={handleBack}
            sx={{
              color: "black",
              height: "auto",
              padding: "5px",
              width: "auto",
            }}
          >
            <ArrowBackIosNewOutlinedIcon sx={{ fontSize: "30px" }} />
          </IconButton>
        </Tooltip>
      ) : null} */}
      <Stack direction="column" gap={headerGap}>
        <StyledTitle variant="h2" fontsizes={fontsizes}>
          {title}
        </StyledTitle>
        <StyledSubHeading paragraph fontsizes={fontsizes}>
          {subHeading || ""}
        </StyledSubHeading>
      </Stack>
    </Stack>
  );
};
