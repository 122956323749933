import { Box, Button } from "@mui/material";
import { CustomButtonWithIcon } from "@stories/atoms/CustomButtonWithIcon/CustomButtonWithIcon";
import { ArrowBackIosNewIcon, ArrowForwardIosIcon } from "assets/constants";

export const PAGINATION_LIMIT = 15;

type PaginationResult<T> = {
  totalPages: number;
  currentPage: number;
  data: T[];
};

export function paginate<T>(
  items: T[],
  page: number,
  limit = 15,
): PaginationResult<T> {
  const totalItems = items.length;
  const totalPages = Math.ceil(totalItems / limit);

  // Ensure the requested page is within valid range
  const currentPage = Math.min(Math.max(page, 1), totalPages);

  // Calculate the start and end index for slicing
  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;

  // Slice the items array to get the data for the current page
  const data = items.slice(startIndex, endIndex);

  return {
    currentPage,
    data,
    totalPages,
  };
}

interface CustomPaginationProps {
  onPageChange: (page: number) => void;
  page: number;
  totalPages: number;
}

export const CustomPagination = ({
  onPageChange,
  page,
  totalPages,
}: CustomPaginationProps) => {
  const generatePageNumbers = () => {
    const visiblePages = new Set<number>();

    // Always show first two pages
    visiblePages.add(1);
    if (totalPages > 1) visiblePages.add(2);

    // Always show last two pages
    visiblePages.add(totalPages);
    if (totalPages > 1) visiblePages.add(totalPages - 1);

    // Show current page and neighbors
    visiblePages.add(page);
    if (page > 1) visiblePages.add(page - 1);
    if (page < totalPages) visiblePages.add(page + 1);

    // Convert to sorted array
    const sortedPages = Array.from(visiblePages).sort((a, b) => a - b);

    // Insert ellipses where there are gaps
    const finalPages: (number | "...")[] = [];
    sortedPages.forEach((num, index) => {
      if (index > 0 && num - sortedPages[index - 1] > 1) {
        finalPages.push("...");
      }
      finalPages.push(num);
    });
    return finalPages;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: { sm: "space-between", xs: "center" },
        p: "12px 0",
      }}
    >
      <CustomButtonWithIcon
        disabled={page === 1}
        handleClick={() => onPageChange(page - 1)}
        text="Previous"
        startIcon={<ArrowBackIosNewIcon />}
      />

      <Box sx={{ alignItems: "center", display: "flex", gap: "4px" }}>
        {pageNumbers.map((p, index) =>
          p === "..." ? (
            <Box key={`ellipsis-${index}`} sx={{ padding: "0 8px" }}>
              ...
            </Box>
          ) : (
            <Button
              disableRipple
              key={p}
              onClick={() => onPageChange(p as number)}
              sx={{
                "&:hover": {
                  backgroundColor: "#FAFAFA",
                },
                alignItems: "center",
                backgroundColor: `${page === p ? "#FAFAFA" : "transparent"}`,
                borderRadius: "8px",
                color: "#535862",
                display: "flex",
                fontFamily: '"Inter", sans-serif',
                fontSize: "14px",
                fontWeight: "500",
                height: "40px",
                lineHeight: "20px",
                minWidth: "40px",
                outline: "none",
                padding: "0px",
                width: "40px",
              }}
            >
              {p}
            </Button>
          ),
        )}
      </Box>

      <CustomButtonWithIcon
        disabled={page === totalPages}
        handleClick={() => onPageChange(page + 1)}
        text="Forward"
        endIcon={<ArrowForwardIosIcon />}
      />
    </Box>
  );
};
