import { Skeleton, Stack, TableCell, TableRow } from "@mui/material";

export const OrganisationTableRowSkeleton = () => {
  const skeletonCells = [{ width: "25%" }, { width: "25%" }, { width: "25%" }];

  return (
    <TableRow>
      {skeletonCells.map((cell, index) => (
        <TableCell
          key={index}
          sx={{
            paddingBottom: "10px",
            paddingTop: "10px",
            width: cell.width,
          }}
        >
          <Skeleton animation="wave" />
        </TableCell>
      ))}
      <TableCell>
        <Stack direction="row" justifyContent={"center"} spacing={2}>
          <Skeleton animation="wave" width={100} height={40} />
          <Skeleton animation="wave" width={100} height={40} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
