import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Grid, Stack } from "@mui/material";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import LidpModal from "@stories/organisms/LidpModal/LidpModal";
import {
  generateTableHead,
  getCellStyle,
  isTotalRow,
  Table,
} from "@stories/organisms/Table/Table";
import {
  ActionCell,
  getHeaderAndCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import {
  ColDef,
  ColGroupDef,
  ITooltipParams,
  SizeColumnsToContentStrategy,
} from "ag-grid-community";
import { ArchiveProjectIcon, EditProjectIcon } from "assets/constants";
import { useCallback, useMemo, useState } from "react";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  calculateLocalContentPackageAnzTotal,
  calculateLocalContentPackageContractTotal,
  calculateLocalContentPackageValueTotal,
} from "social-pro-common/utils/calc";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import LidpImport, { ImportStep } from "../LidpImport/LidpImport";
import { LidpTableRowSkeleton } from "../LidpTableRow/LidpTableRowSkeleton";

interface LidpRequirementFormProps {
  loading: boolean;
  project: ProjectLineItem;
  isPrimaryPackage: boolean;
  projectLocalContent: PackageLocalContentLineItem[];
  setProject: (project: ProjectLineItem) => void;
  handleNext: () => void;
  handleBack: () => void;
  setDirtyOnChange: (isDirty: boolean) => void;
}

export const LidpRequirementForm = ({
  handleBack,
  handleNext,
  isPrimaryPackage,
  loading,
  project,
  projectLocalContent,
  setDirtyOnChange,
  setProject,
}: LidpRequirementFormProps) => {
  const [step, setStep] = useState<ImportStep>(ImportStep.Init);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [localContents, setLocalContents] =
    useState<PackageLocalContentLineItem[]>(projectLocalContent);
  const [selectedLocalContent, setSelectedLocalContent] = useState<
    PackageLocalContentLineItem | undefined
  >();
  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const confirmDelete = (lc: PackageLocalContentLineItem) => {
    setSelectedLocalContent(lc);
    setOpenConfirmDialog(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenImportModal = () => {
    setOpenImportModal(true);
  };

  const createLocalContent = async (
    lc: PackageLocalContentLineItem,
  ): Promise<void> => {
    setLocalContents([...localContents, lc]);
    setDirtyOnChange(true);
  };

  const upsertLocalContent = async (
    lc: PackageLocalContentLineItem[],
  ): Promise<void> => {
    setLocalContents([...localContents, ...lc]);
    setDirtyOnChange(true);
  };

  const updateLocalContent = async (
    lc: PackageLocalContentLineItem,
  ): Promise<void> => {
    setLocalContents(
      localContents.map((f) => {
        return lc.id === f.id ? lc : f;
      }),
    );
    setDirtyOnChange(true);
  };

  const goBack = (lcs: PackageLocalContentLineItem[]) => {
    setProject({
      ...project,
      financial: {
        ...project.financial,
        lidpPc: lcs.length ? calculateLocalContentPackageAnzTotal(lcs) : -1,
      },
      localContents: lcs,
    });
    handleBack();
  };

  const setProjectLocalContents = (lcs: PackageLocalContentLineItem[]) => {
    setProject({
      ...project,
      financial: {
        ...project.financial,
        lidpPc: lcs.length ? calculateLocalContentPackageAnzTotal(lcs) : -1,
      },
      localContents: lcs,
    });
    handleNext();
  };

  const handleEditLocalContent = useCallback(
    async (localContent: PackageLocalContentLineItem) => {
      setSelectedLocalContent(localContent);
      setOpen(true);
    },
    [selectedLocalContent],
  );

  const handleDelete = () => {
    setLocalContents((prev) =>
      prev.filter((f) => f.id !== selectedLocalContent?.id),
    );
    setDirtyOnChange(true);
    setOpenConfirmDialog(false);
    setSelectedLocalContent(undefined);
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      cellStyle: (params) => getCellStyle(params, "right"),
      colSpan: (params) => (isTotalRow(params) ? 3 : 1),
      field: "itemDescription",
      headerClass: "left-table-header",
      headerName: "Item Description",
      maxWidth: 250,
      minWidth: 250,
      tooltipField: "itemDescription",
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "manufacturer",
      headerClass: "left-table-header",
      headerName: "Manufacturer",
      maxWidth: 250,
      minWidth: 250,
      tooltipField: "manufacturer",
    },
    {
      cellStyle: (params) => getCellStyle(params),
      field: "supplier",
      headerClass: "left-table-header",
      headerName: "Supplier",
      maxWidth: 250,
      minWidth: 250,
      tooltipField: "supplier",
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "value",
      headerName: "Value",
      maxWidth: 120,
      minWidth: 120,
      tooltipValueGetter: (p: ITooltipParams) =>
        `$${formatDecimalPlaces(p.value, 0)}`,
      valueFormatter: (params) => `$${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "steelMass",
      headerName: "Steel Mass",
      maxWidth: 150,
      minWidth: 150,
      valueFormatter: (params) => {
        return params.value != null && params.value !== "-"
          ? `${formatDecimalPlaces(params.value, 2)}T`
          : `${params.value}`;
      },
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "localValueAdd",
      headerName: "Local Value Add",
      maxWidth: 150,
      minWidth: 150,
      valueFormatter: (params) => {
        return params.value != null && params.value !== "-"
          ? `${formatDecimalPlaces(params.value, 2)}%`
          : `${params.value}`;
      },
    },
    {
      cellStyle: {
        ...getHeaderAndCellStyles("right", false).cellStyle,
        backgroundColor: "#f4f4f4",
      },
      field: "importValueAdd",
      headerClass: getHeaderAndCellStyles("right", false).headerClass,
      headerName: "Import Value Add",
      maxWidth: 150,
      minWidth: 150,
      valueFormatter: (params) => {
        return params.value != null && params.value !== "-"
          ? `${formatDecimalPlaces(params.value, 2)}%`
          : `${params.value}`;
      },
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "contractContent",
      headerName: "Contract Content",
      maxWidth: 150,
      minWidth: 150,
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 4)}%`,
    },
    {
      cellStyle: {
        ...getHeaderAndCellStyles("right", false).cellStyle,
        backgroundColor: "#f4f4f4",
      },
      field: "anzValueAdd",
      headerClass: getHeaderAndCellStyles("right", false).headerClass,
      headerName: "ANZ Value-Add Activity",
      maxWidth: 150,
      minWidth: 150,
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 4)}%`,
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "smeCount",
      headerName: "SME Count",
      maxWidth: 100,
      minWidth: 100,
      valueFormatter: (params) => `${params.value ? params.value : "-"}`,
    },
    {
      ...getHeaderAndCellStyles("right", false),
      field: "supplierCount",
      headerName: "Supplier Count",
      maxWidth: 120,
      minWidth: 120,
      valueFormatter: (params) => `${params.value ? params.value : "-"}`,
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCell,
      headerName: "",
      minWidth: 50,
    },
  ]);

  const autoSizeStrategy = useMemo<SizeColumnsToContentStrategy>(
    () => ({
      type: "fitCellContents",
    }),
    [],
  );

  const cellActions = [
    {
      action: handleEditLocalContent,
      icon: <EditProjectIcon />,
      title: "Edit Local Content",
    },
    {
      action: confirmDelete,
      icon: <ArchiveProjectIcon />,
      title: "Delete Local Content",
    },
  ];

  const contractTotal =
    calculateLocalContentPackageContractTotal(localContents);

  const anzTotalTotal = calculateLocalContentPackageAnzTotal(localContents);

  const valueTotal = calculateLocalContentPackageValueTotal(localContents);

  const totalSme = localContents.reduce(
    (acc, lc) => acc + (lc.smeCount ?? 0),
    0,
  );
  const totalSuppliers = localContents.reduce(
    (acc, lc) => acc + (lc.supplierCount ?? 0),
    0,
  );

  const totalRow = {
    anzValueAdd: anzTotalTotal,
    contractContent: contractTotal,
    importValueAdd: "-",
    isTotalRow: true,
    itemDescription: "Total Local Content",
    localValueAdd: "-",
    smeCount: totalSme,
    steelMass: "-",
    supplier: "Total Local Content",
    supplierCount: totalSuppliers,
    value: valueTotal,
  };

  return (
    <>
      <Grid
        container
        sx={{
          padding: "25px 40px 0px 40px",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "25px",
            width: "100%",
          }}
        >
          <SubTitle title={"Agreed Local Content"} />

          <Stack
            direction="row"
            spacing={2}
            alignContent={"end"}
            justifyContent={"end"}
          >
            <StyledButton
              data-test-id="import-localContent-button"
              className="blackBtn"
              loading={loading}
              variant="contained"
              onClick={handleOpenImportModal}
              startIcon={<CloudUploadIcon />}
            >
              Import Local Content
            </StyledButton>
            <StyledButton
              data-test-id="add-localContent-button"
              className="blackBtn"
              loading={loading}
              variant="contained"
              startIcon={<AddCircleIcon />}
              onClick={() => {
                setOpen(true);
              }}
            >
              Add Local Content
            </StyledButton>
          </Stack>
        </Box>

        <Grid item md={12}>
          {loading ? (
            <TableSkeleton
              tableHead={generateTableHead(colDefs)}
              rows={LidpTableRowSkeleton}
            />
          ) : (
            <Table<PackageLocalContentLineItem>
              columnDefs={colDefs}
              loading={loading}
              autoSizeStrategy={autoSizeStrategy}
              context={{ cellActions, loading }}
              data={localContents || []}
              pinnedBottomRowData={
                localContents.length ? ([totalRow] as ColDef[]) : []
              }
            />
          )}
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          padding: "20px 40px 40px",
        }}
      >
        <Stack direction="row" spacing={1}>
          <StyledButton
            loading={loading}
            onClick={() => goBack(localContents)}
            disabled={loading}
            variant="outlined"
          >
            Back
          </StyledButton>
          <StyledButton
            loading={loading}
            onClick={() => setProjectLocalContents(localContents)}
            variant="contained"
            type="submit"
          >
            Next
          </StyledButton>
        </Stack>
      </Grid>

      {open ? (
        <LidpModal
          open={open}
          loading={loading}
          projectId={project.id}
          isCreateModal
          isPrimaryPackage={isPrimaryPackage}
          existingLocalContent={selectedLocalContent}
          packageValue={project.financial.totalValue || 0}
          createLocalContent={createLocalContent}
          updateLocalContent={updateLocalContent}
          handleClose={handleClose}
        />
      ) : null}
      <LidpImport
        open={openImportModal}
        step={step}
        project={project}
        setIsOpen={setOpenImportModal}
        setStep={setStep}
        upsertLidp={upsertLocalContent}
      />

      <ConfirmationDialog
        message={"Are you sure you want to delete this Local Content item?"}
        open={openConfirmDialog}
        title={"Delete Local Content"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => handleDelete()}
      />
    </>
  );
};
