import { useProjectContext } from "@hooks/context/useProjectContext";
import { useContractorPackageDetails } from "@hooks/crud/contractorPackages/useContractorPackageDetails";
import { PackageDetailsTabOption } from "@stories/molecules/PackageDetailsTab/PackageDetailsTab";
import { Header } from "@stories/organisms/Header/Header";
import { PackageDetailsComments } from "@stories/organisms/PackageDetails/PackageDetailsComments";
import { PackageDetailsEnviroSustainability } from "@stories/organisms/PackageDetails/PackageDetailsEnviroSustainability";
import { PackageDetailsForecast } from "@stories/organisms/PackageDetails/PackageDetailsForecast";
import { PackageDetailsHoursLevel1 } from "@stories/organisms/PackageDetails/PackageDetailsHoursLevel1";
import { PackageDetailsHoursLevel2 } from "@stories/organisms/PackageDetails/PackageDetailsHoursLevel2";
import { PackageDetailsHoursLevel3 } from "@stories/organisms/PackageDetails/PackageDetailsHoursLevel3";
import { PackageDetailsLocalContent } from "@stories/organisms/PackageDetails/PackageDetailsLocalContent";
import { PackageDetailsPolicy } from "@stories/organisms/PackageDetails/PackageDetailsPolicy";
import { PackageReports } from "@stories/organisms/PackageDetails/PackageDetailsReports";
import { PackageDetailsSpend } from "@stories/organisms/PackageDetails/PackageDetailsSpend";
import { PackageDetailsSpendTarget } from "@stories/organisms/PackageDetails/PackageDetailsSpendTarget";
import { SummaryDetails } from "@stories/organisms/PackageDetails/PackageDetailsSummary";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import * as RouteHelper from "@utils/routes";
import { HardHat } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  LabourHourTargetAnalyticsLineItem,
  SocialSpendTargetAnalyticsLineItem,
} from "social-pro-common/interfaces/analytics";
import { getSortedLocalContent } from "social-pro-common/interfaces/packageLocalContent";

export const PackageDetails = () => {
  const { packageId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const {
    contractorOrganisation,
    contractorPackageDetails,
    isContractorPackageLoading,
  } = useContractorPackageDetails(packageId);

  const [labourHourTargetId, setLabourHourTargetId] = useState<string>();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>();

  const onLabourHourTargetChange = (
    target?: LabourHourTargetAnalyticsLineItem,
  ) => {
    setLabourHourTargetId(target?.id);
  };

  const [socialSpendTargetId, setSocialSpendTargetId] = useState<string>();

  const onSocialSpendTargetChange = (
    targetId?: SocialSpendTargetAnalyticsLineItem,
  ) => {
    setSocialSpendTargetId(targetId?.id);
  };

  const handleOnBack = () => {
    if (selectedProject && contractorPackage) {
      navigate(RouteHelper.packages(selectedProject.id, contractorPackage.id));
    }
  };

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabChange = async (tabIndex: number) => {
    setLabourHourTargetId(undefined);
    setSocialSpendTargetId(undefined);
    setTabIndex(tabIndex);
  };

  useEffect(() => {
    const tab = searchParams.get("tab");
    if (tab) {
      const tabIndex = (() => {
        switch (tab) {
          default:
          case PackageDetailsTabOption.Hours:
            return 0;
          case PackageDetailsTabOption.Spend:
            return 1;

          case PackageDetailsTabOption.LocalContent:
            return 2;

          case PackageDetailsTabOption.Policy:
            return 3;

          case PackageDetailsTabOption.Enviro:
            return 4;

          case PackageDetailsTabOption.Forecast:
            return 5;

          case PackageDetailsTabOption.Reports:
            return 6;

          case PackageDetailsTabOption.Comments:
            return 7;
          case PackageDetailsTabOption.Details:
            return 8;
        }
      })();
      setTabIndex(tabIndex);
    }
    setSearchParams();
  }, []);

  const loading = isProjectLoading || isContractorPackageLoading;

  const selectedPackageStep = (() => {
    switch (tabIndex) {
      case 0:
        return !labourHourTargetId ? (
          <PackageDetailsHoursLevel1
            isLoading={loading}
            subPackage={contractorPackageDetails}
            onLabourHourTargetChange={onLabourHourTargetChange}
          />
        ) : selectedEmployeeId ? (
          <PackageDetailsHoursLevel2
            isLoading={loading}
            subPackage={contractorPackageDetails}
            targetId={labourHourTargetId}
            handleOnBack={() => {
              setLabourHourTargetId(undefined);
            }}
            setSelectedEmployeeId={(employeeId?: string) => {
              setSelectedEmployeeId(employeeId);
            }}
          />
        ) : (
          <PackageDetailsHoursLevel3
            isLoading={loading}
            subPackage={contractorPackageDetails}
            targetId={labourHourTargetId}
            handleOnBack={() => {
              setLabourHourTargetId(undefined);
            }}
          />
        );

      case 1:
        return !socialSpendTargetId ? (
          <PackageDetailsSpend
            isLoading={loading}
            subPackage={contractorPackageDetails}
            onSocialSpendTargetChange={onSocialSpendTargetChange}
          />
        ) : (
          <PackageDetailsSpendTarget
            isLoading={loading}
            subPackage={contractorPackageDetails}
            targetId={socialSpendTargetId}
            handleOnBack={() => {
              setSocialSpendTargetId(undefined);
            }}
          />
        );
      case 2:
        return (
          <PackageDetailsLocalContent
            loading={loading}
            subPackage={contractorPackageDetails}
            localContents={getSortedLocalContent(
              contractorPackageDetails?.localContent,
            )}
          />
        );
      case 3:
        return (
          <PackageDetailsPolicy
            isLoading={loading}
            subPackage={contractorPackageDetails}
          />
        );
      case 4:
        return (
          <PackageDetailsEnviroSustainability
            loading={loading}
            project={selectedProject}
            subPackage={contractorPackageDetails}
          />
        );
      case 5:
        return (
          <PackageDetailsForecast
            isLoading={loading}
            subPackage={contractorPackageDetails}
          />
        );
      case 6:
        return (
          <PackageReports
            isLoading={loading}
            contractorPackage={contractorPackage}
            subPackage={contractorPackageDetails}
          />
        );
      case 7:
        return (
          <PackageDetailsComments
            isLoading={loading}
            project={selectedProject}
            organisation={contractorOrganisation}
            subPackage={contractorPackageDetails}
          />
        );
      case 8:
        return (
          <SummaryDetails
            isLoading={loading}
            project={selectedProject}
            contractorOrganisation={contractorOrganisation}
            subPackage={contractorPackageDetails}
          />
        );
    }
  })();

  const tabs = [
    // {
    //   label: "Enviro & Sustainability",
    //  index: 4,
    // },
  ];

  if (selectedProject?.commitmentsHours.length) {
    tabs.push({
      index: 0,
      label: "Labour Hours",
    });
  }

  if (selectedProject?.commitmentsSpend.length) {
    tabs.push({
      index: 1,
      label: "Social Spend",
    });
  }

  if (selectedProject?.localContents.length) {
    tabs.push({
      index: 2,
      label: "Local Content",
    });
  }

  if (selectedProject?.policies.length) {
    tabs.push({
      index: 3,
      label: "Policy",
    });
  }

  // if (selectedProject?.greenMetrics.length) {
  //   tabs.push({
  //     index: 4,
  //     label: "Envionmental & Sustainability",
  //   });
  // }

  if (
    selectedProject?.commitmentsHours.length ||
    selectedProject?.commitmentsSpend.length
  ) {
    tabs.push({
      index: 5,
      label: "Forecast",
    });
  }
  tabs.push(
    ...[
      {
        index: 6,
        label: "Reports",
      },
      {
        index: 7,
        label: "Comments",
      },
      {
        index: 8,
        label: "Details",
      },
    ],
  );
  const breadcrumbData = [
    {
      href: RouteHelper.packages(
        selectedProject?.id || "",
        contractorPackage?.id || "",
      ),
      label: "Packages",
    },
    {
      href: RouteHelper.contractorPackage(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        contractorPackageDetails?.id || "",
      ),
      label: `${contractorPackageDetails?.contractorName || ""}`,
    },
  ];

  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={`${contractorPackageDetails?.contractorName || "Organisation"} specific target information`}
        mainTitle="Package Details"
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
        handleBack={handleOnBack}
        breadcrumbIcon={<HardHat />}
        breadcrumbs={breadcrumbData}
      />

      {selectedPackageStep}
    </PageContainer>
  );
};
