import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourAnalyticsLevel3 } from "@hooks/crud/analytics/useLabourHourAnalyticsLevel3";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { writeXlsxTotalWorkforceIDData } from "@utils/xlsx/labourHours";
import { ExportIcon } from "assets/constants";
import { Package } from "lucide-react";
import { useCallback, useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import {
  dateToString,
  getMaximalDate,
  stringToDate,
} from "social-pro-common/utils/date";

import { AnalyticsHoursLevel3Table } from "../AnalyticsHoursTables/AnalyticsHoursLevel3Table/AnalyticsHoursLevel3Table";
import { Header, HeaderButtonConfig, HeaderButtonType } from "../Header/Header";
import LabourHourAnalyticsSummaryLevel3 from "../Summary/LabourHourAnalyticsSummaryLevel3";

interface PackageDetailsHoursLevel2Props {
  isLoading: boolean;
  subPackage?: ContractorPackageLineItem;
  targetId: string;
  handleOnBack: () => void;
  setSelectedEmployeeId: (employeeId?: string) => void;
}

export const PackageDetailsHoursLevel2 = ({
  handleOnBack,
  isLoading,
  setSelectedEmployeeId,
  subPackage,
  targetId,
}: PackageDetailsHoursLevel2Props) => {
  const [reportId, _setReportDate] = useState<string>(dateToString(new Date()));

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, labourHoursAnalytics } =
    useLabourHourAnalyticsLevel3(
      reportId,
      selectedProject?.id,
      targetId,
      subPackage?.id,
    );

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAnalytics, setFilteredAnalytics] = useState<
    LabourHourForTargetLineItem[]
  >([]);

  const handleExportHours = async () => {
    if (filteredAnalytics?.length > 0) {
      writeXlsxTotalWorkforceIDData(filteredAnalytics);
    }
  };
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const [currentDate, setCurrentDate] = useState<Date>(
    stringToDate(
      getMaximalDate(
        isPrimaryPackage
          ? selectedProject?.projectStartDate
          : contractorPackage?.packageStartDate,
        isPrimaryPackage
          ? selectedProject?.projectEndDate
          : contractorPackage?.packageEndDate,
      ),
    ),
  );

  const handleDateChange = async (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const loading = isLoading || isProjectLoading || isAnalysisLoading;
  const selectedTarget = selectedProject?.commitmentsHours.find(
    (c) => c.id === targetId,
  );

  const buttons: HeaderButtonConfig[] = [
    {
      action: handleExportHours,
      icon: <ExportIcon />,
      text: "Export Targets",
      type: HeaderButtonType.SECONDARY,
      width: 150,
    },
  ];

  const breadcrumbData = [
    {
      action: handleOnBack,
      label: "My package",
    },
    {
      label: "Labour Hours (Level 1)",
    },
  ];

  const viewEmployeeDetails = useCallback(
    (labourHour: LabourHourForTargetLineItem) => {
      if (selectedProject && contractorPackage) {
        setSelectedEmployeeId(labourHour.id);
      }
    },
    [selectedProject, contractorPackage, setSelectedEmployeeId],
  );

  return (
    <>
      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <LabourHourAnalyticsSummaryLevel3
          summary={labourHoursAnalytics?.summary}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <WhiteBox>
          <Stack direction="column" gap={4}>
            <Header
              mainTitle={`Labour Hours`}
              subTitle={`${
                selectedTarget
                  ? selectedTarget.targetDescription ||
                    labourHourCommitmentTypeToString(selectedTarget.targetName)
                  : ""
              }`}
              loading={loading}
              buttons={buttons}
              breadcrumbIcon={<Package />}
              handleBack={handleOnBack}
              handleSearchChange={handleSearchChange}
              searchTerm={searchTerm}
              isSubheading
              currentDate={currentDate}
              breadcrumbs={breadcrumbData}
              handleDateChange={handleDateChange}
              headerFontSizes={[18, 14]}
              headerGap={0}
            />

            <AnalyticsHoursLevel3Table
              loading={loading}
              targetId={targetId}
              reportId={""}
              searchTerm={searchTerm}
              labourHoursAnalytics={labourHoursAnalytics}
              onFilteredDataChange={setFilteredAnalytics}
              handleAction={viewEmployeeDetails}
            />
          </Stack>
        </WhiteBox>
      </Grid>
    </>
  );
};
