"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createDefaultLabourHour = exports.createDefaultEmployeeHoursToDateLineItem = void 0;
const uuid_1 = require("uuid");
const contractorEmployee_1 = require("../../entities/contractorEmployee");
const createDefaultEmployeeHoursToDateLineItem = (employee) => {
    return {
        hoursStudied: 0,
        hoursWorked: 0,
        id: employee.id,
    };
};
exports.createDefaultEmployeeHoursToDateLineItem = createDefaultEmployeeHoursToDateLineItem;
const createDefaultLabourHour = (projectId, reportId, contractorPackageId, userOrganisation, employee, socialFactors = []) => {
    return {
        amountOfHoursStudied: 0,
        amountOfHoursWorked: 0,
        contractorPackageId,
        created: new Date(),
        employeeId: employee?.id || "",
        employeeName: employee?.employeeName || "",
        employmentLevel: employee?.employmentLevel || contractorEmployee_1.EmploymentLevel.FullEmployment,
        gender: employee?.gender || contractorEmployee_1.Gender.Male,
        id: (0, uuid_1.v4)(),
        organisationName: userOrganisation.organisationName,
        projectId,
        reportId,
        socialFactors,
        supportingDocuments: [],
    };
};
exports.createDefaultLabourHour = createDefaultLabourHour;
