import {
  deleteBatchApiData,
  listApiData,
  postBatchApiData,
} from "@hooks/utils/api";
import { catchSentryError } from "@utils/sentry";
import { useCallback, useState, useEffect } from "react";
import {
  decodePackageLocalContent,
  encodePackageLocalContent,
} from "social-pro-common/decoders/packageLocalContent";
import { PackageLocalContent } from "social-pro-common/entities/packageLocalContent";
import { PackageLocalContentLineItem } from "social-pro-common/interfaces/packageLocalContent";

export const usePackageLocalContent = (
  packageId?: string,
  reportId?: string,
) => {
  const [packageLocalContents, setPackageLocalContents] = useState<
    PackageLocalContentLineItem[]
  >([]);
  const [isPackageLocalContentLoading, setIsPackageLocalContentLoading] =
    useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const listPackageLocalContent = useCallback(
    async (packageId: string, reportId: string) => {
      try {
        setIsPackageLocalContentLoading(true);
        const localContentResult = await listApiData(
          "listPackageLocalContent",
          "packageLocalContent",
          packageId,
          { reportId },
        );
        const localContents = localContentResult.data.map((p) =>
          decodePackageLocalContent(p as PackageLocalContent),
        );
        setPackageLocalContents(localContents);
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not list localContents");
      } finally {
        setIsPackageLocalContentLoading(false);
      }
    },
    [listApiData, decodePackageLocalContent],
  );

  const createPackageLocalContent = useCallback(
    async (
      localContent: PackageLocalContentLineItem | PackageLocalContentLineItem[],
    ) => {
      const newContents = Array.isArray(localContent)
        ? localContent
        : [localContent];

      if (!newContents.length) return;

      try {
        setIsPackageLocalContentLoading(true);
        const encodedLocalContents = newContents.map(encodePackageLocalContent);
        await postBatchApiData(
          "createPackageLocalContent",
          "packageLocalContent",
          encodedLocalContents,
        );
        setPackageLocalContents((prevContents) => [
          ...prevContents,
          ...newContents,
        ]);
        return localContent;
      } catch (e: any) {
        catchSentryError(e);
        setError("Failed to import local content");
      } finally {
        setIsPackageLocalContentLoading(false);
      }
      return localContent;
    },
    [encodePackageLocalContent, postBatchApiData],
  );

  const updatePackageLocalContent = useCallback(
    async (localContent: PackageLocalContentLineItem) => {
      try {
        setIsPackageLocalContentLoading(true);
        const encodedLocalContent = encodePackageLocalContent(localContent);
        await postBatchApiData(
          "updateLocalPackageContent",
          "packageLocalContent",
          [encodedLocalContent],
        );
        setPackageLocalContents((prevContents) =>
          prevContents.map((c) =>
            c.id === localContent.id ? localContent : c,
          ),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not update packageLocalContent");
      } finally {
        setIsPackageLocalContentLoading(false);
      }
    },
    [encodePackageLocalContent, postBatchApiData],
  );

  const deletePackageLocalContents = useCallback(
    async (localContentsToDelete: PackageLocalContentLineItem[]) => {
      try {
        setIsPackageLocalContentLoading(true);
        const encodedLocalContent = localContentsToDelete.map((e) =>
          encodePackageLocalContent(e),
        );
        await deleteBatchApiData(
          "deletePackageLocalContent",
          "packageLocalContent",
          encodedLocalContent,
        );

        const deletedRowIds = localContentsToDelete.map((e) => e.id);
        setPackageLocalContents((prevContents) =>
          prevContents.filter((e) => !deletedRowIds.includes(e.id)),
        );
      } catch (e: any) {
        catchSentryError(e);
        setError("Could not delete localContent");
      } finally {
        setIsPackageLocalContentLoading(false);
      }
    },
    [encodePackageLocalContent, deleteBatchApiData],
  );

  useEffect(() => {
    if (packageId && reportId) {
      listPackageLocalContent(packageId, reportId);
    } else {
      setIsPackageLocalContentLoading(false);
    }
  }, [packageId, reportId, listPackageLocalContent]);

  const searchLocalContent = useCallback(
    async (projectId: string, query: string) => {
      try {
        setIsPackageLocalContentLoading(true);
        const localContentResult = await listApiData(
          "searchPackageLocalContent",
          "packageLocalContent",
          projectId,
          { query },
        );

        return localContentResult.data
          ? localContentResult.data.map((lc) =>
              decodePackageLocalContent(lc as PackageLocalContent),
            )
          : [];
      } catch (e: any) {
        setError("Could not search localContent");
        return [];
      } finally {
        setIsPackageLocalContentLoading(false);
      }
    },
    [listApiData, decodePackageLocalContent],
  );

  return {
    createPackageLocalContent,
    deletePackageLocalContents,
    error,
    isPackageLocalContentLoading,
    listPackageLocalContent,
    packageLocalContents,
    searchLocalContent,
    updatePackageLocalContent,
  };
};
