import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourAnalyticsLevel3 } from "@hooks/crud/analytics/useLabourHourAnalyticsLevel3";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { AnalyticsHoursLevel3Table } from "@stories/organisms/AnalyticsHoursTables/AnalyticsHoursLevel3Table/AnalyticsHoursLevel3Table";
import {
  Header,
  HeaderButtonConfig,
  HeaderButtonType,
} from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import LabourHourAnalyticsSummaryLevel3 from "@stories/organisms/Summary/LabourHourAnalyticsSummaryLevel3";
import * as RouteHelper from "@utils/routes";
import { writeXlsxTotalWorkforceData } from "@utils/xlsx/labourHours";
import { ExportIcon } from "assets/constants";
import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import {
  dateToString,
  getMaximalDate,
  stringToDate,
} from "social-pro-common/utils/date";

export const AnalyticsHoursLevel3 = () => {
  const { mode, projectId, reportId, subPackageId, targetId } = useParams();

  if (!projectId) {
    throw Error("No project id");
  }
  if (!targetId) {
    throw Error("No target id");
  }
  if (!reportId) {
    throw Error("No report id");
  }
  if (!subPackageId) {
    throw Error("No sub package id");
  }

  const navigate = useNavigate();

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, labourHoursAnalytics } =
    useLabourHourAnalyticsLevel3(
      reportId,
      selectedProject?.id,
      targetId,
      subPackageId,
      mode,
    );

  const loading = isProjectLoading || isAnalysisLoading;
  const selectedTarget = selectedProject?.commitmentsHours.find(
    (c) => c.id === targetId,
  );

  const [currentDate, setCurrentDate] = useState<Date>(
    stringToDate(
      getMaximalDate(
        isPrimaryPackage
          ? selectedProject?.projectStartDate
          : contractorPackage?.packageStartDate,
        isPrimaryPackage
          ? selectedProject?.projectEndDate
          : contractorPackage?.packageEndDate,
      ),
    ),
  );
  const [filteredAnalytics, setFilteredAnalytics] = useState<
    LabourHourForTargetLineItem[]
  >([]);

  const handleExportHours = async () => {
    if (filteredAnalytics.length > 0) {
      writeXlsxTotalWorkforceData(filteredAnalytics);
    }
  };

  const handleDateChange = async (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const matchingTarget = selectedProject?.commitmentsHours.find(
    (target) => target.id === targetId,
  );

  const displayText =
    mode == "all"
      ? "All"
      : labourHoursAnalytics?.subContractor.organisationName || "";

  const breadcrumbData = [
    {
      href: RouteHelper.analyticsLabourHoursLevel1(
        selectedProject?.id || "",
        contractorPackage?.id || "",
        dateToString(currentDate),
      ),
      label: "Labour Hours",
    },
  ];

  if (isPrimaryPackage) {
    breadcrumbData.push({
      href: RouteHelper.analyticsLabourHoursLevel2(
        selectedProject?.id || "asdadas",
        contractorPackage?.id || "",
        targetId,
        reportId,
      ),
      label: matchingTarget
        ? matchingTarget.targetDescription ||
          labourHourCommitmentTypeToString(matchingTarget.targetName)
        : "",
    });
  }
  breadcrumbData.push({
    href: RouteHelper.analyticsLabourHoursLevel3(
      selectedProject?.id || "",
      contractorPackage?.id || "",
      subPackageId,
      reportId,
      targetId,
      "all",
    ),
    label: displayText,
  });

  const viewEmployeeDetails = useCallback(
    (labourHour: LabourHourForTargetLineItem) => {
      if (selectedProject && contractorPackage) {
        navigate(
          RouteHelper.analyticsLabourHoursLevel4(
            selectedProject.id,
            contractorPackage.id,
            subPackageId,
            reportId,
            targetId,
            labourHour.id,
          ),
        );
      }
    },
    [selectedProject, contractorPackage, navigate, targetId, reportId],
  );

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const buttons: HeaderButtonConfig[] = [
    {
      action: handleExportHours,
      icon: <ExportIcon />,
      text: "Export Records",
      type: HeaderButtonType.SECONDARY,
      width: 150,
    },
  ];

  return (
    <PageContainer>
      <Header
        loading={loading}
        breadcrumbs={breadcrumbData}
        breadcrumbIcon={<HourglassEmptyIcon />}
        mainTitle={`Labour Hours - ${
          selectedTarget
            ? selectedTarget.targetDescription ||
              labourHourCommitmentTypeToString(selectedTarget.targetName)
            : ""
        }`}
        subTitle={isPrimaryPackage ? "Project Analytics" : "Package Analytics"}
        handleSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        buttons={buttons}
        handleDateChange={handleDateChange}
        currentDate={currentDate}
      />
      <Grid md={12} item>
        <LabourHourAnalyticsSummaryLevel3
          summary={labourHoursAnalytics?.summary}
          loading={loading}
        />
      </Grid>

      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <WhiteBox>
          <Stack direction="column" gap={2}>
            <Header
              loading={loading}
              mainTitle="Target Summary"
              subTitle="Level 3 Analytics"
              isSubheading
              headerFontSizes={[18, 14]}
              headerGap={0}
            />
            <AnalyticsHoursLevel3Table
              loading={loading}
              targetId={targetId}
              reportId={reportId}
              labourHoursAnalytics={labourHoursAnalytics}
              handleAction={viewEmployeeDetails}
              searchTerm={searchTerm}
              mode={mode}
              onFilteredDataChange={setFilteredAnalytics}
            />
          </Stack>
        </WhiteBox>
      </Grid>
    </PageContainer>
  );
};
