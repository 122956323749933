import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourPackageDetailsLevel1 } from "@hooks/crud/analytics/useLabourHourPackageDetailsLevel1";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { AnalyticsHoursLevel1Table } from "@stories/organisms/AnalyticsHoursTables/AnalyticsHoursLevel1Table/AnalyticsHoursLevel1Table";
import { writeXlsxLabourHourTargets } from "@utils/xlsx/labourHours";
import { ExportIcon } from "assets/constants";
import { useState } from "react";
import { LabourHourTargetAnalyticsLineItem } from "social-pro-common/interfaces/analytics";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  dateToString,
  getMaximalDate,
  stringToDate,
} from "social-pro-common/utils/date";

import { Header, HeaderButtonConfig, HeaderButtonType } from "../Header/Header";

interface PackageDetailsHoursLevel1Props {
  isLoading: boolean;
  subPackage?: ContractorPackageLineItem;
  onLabourHourTargetChange: (
    target?: LabourHourTargetAnalyticsLineItem,
  ) => void;
}

export const PackageDetailsHoursLevel1 = ({
  isLoading,
  onLabourHourTargetChange,
  subPackage,
}: PackageDetailsHoursLevel1Props) => {
  const [reportId, _setReportDate] = useState<string>(dateToString(new Date()));

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();
  const { isAnalysisLoading, labourHoursAnalytics } =
    useLabourHourPackageDetailsLevel1(
      reportId,
      selectedProject?.id,
      subPackage?.id,
    );

  const isPrimaryPackage = subPackage?.id === selectedProject?.id;

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAnalytics, setFilteredAnalytics] = useState<
    LabourHourTargetAnalyticsLineItem[]
  >([]);

  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const [currentDate, setCurrentDate] = useState<Date>(
    stringToDate(
      getMaximalDate(
        isPrimaryPackage
          ? selectedProject?.projectStartDate
          : contractorPackage?.packageStartDate,
        isPrimaryPackage
          ? selectedProject?.projectEndDate
          : contractorPackage?.packageEndDate,
      ),
    ),
  );

  const handleDateChange = async (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const loading = isLoading || isProjectLoading || isAnalysisLoading;

  const handleExportHours = async () => {
    if (filteredAnalytics?.length > 0) {
      writeXlsxLabourHourTargets(filteredAnalytics);
    }
  };

  const buttons: HeaderButtonConfig[] = [
    {
      action: handleExportHours,
      icon: <ExportIcon />,
      text: "Export Targets",
      type: HeaderButtonType.SECONDARY,
      width: 150,
    },
  ];

  return (
    <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
      <WhiteBox>
        <Stack direction="column" gap={4}>
          <Header
            mainTitle="Labour Hours"
            subTitle="Labour Hour Target Summary"
            loading={loading}
            buttons={buttons}
            handleSearchChange={handleSearchChange}
            searchTerm={searchTerm}
            isSubheading
            currentDate={currentDate}
            handleDateChange={handleDateChange}
            headerFontSizes={[18, 14]}
            headerGap={0}
          />
          <AnalyticsHoursLevel1Table
            loading={loading}
            searchTerm={searchTerm}
            isPrimaryPackage={isPrimaryPackage}
            labourHourAnalytics={labourHoursAnalytics}
            handleAction={onLabourHourTargetChange}
            onFilteredDataChange={setFilteredAnalytics}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
