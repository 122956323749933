import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { StyledButton } from "@stories/atoms/StyledButton/StyledButton";
import { SubTitle } from "@stories/atoms/SubTitle/SubTitle";
import PolicyModal from "@stories/organisms/PolicyModal/PolicyModal";
import { Table } from "@stories/organisms/Table/Table";
import { getHeaderAndCellStyles } from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useState } from "react";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import {
  ProjectPolicyLineItem,
  createDefaultProjectPolicy,
} from "social-pro-common/interfaces/projectPolicy";

import { ConfirmationDialog } from "../ConfirmationDialog/ConfirmationDialog";
import {
  ActionCell,
  InfoCell,
  TABLE_HEAD,
} from "../PolicyTableRow/PolicyTableRowCells";
import { PolicyTableRowSkeleton } from "../PolicyTableRow/PolicyTableRowSkeleton";
interface PolicyRequirementFormProps {
  loading: boolean;
  project: ProjectLineItem;
  projectPolicies: ProjectPolicyLineItem[];
  setProject: (project: ProjectLineItem) => void;
  handleNext: () => void;
  handleBack: () => void;
  setDirtyOnChange: (isDirty: boolean) => void;
}

export const PolicyRequirementForm = ({
  handleBack,
  handleNext,
  loading,
  project,
  projectPolicies,
  setDirtyOnChange,
  setProject,
}: PolicyRequirementFormProps) => {
  const [policies, setPolicies] =
    useState<ProjectPolicyLineItem[]>(projectPolicies);
  const [selectedPolicy, setSelectedPolicy] = useState<
    ProjectPolicyLineItem | undefined
  >();
  const [open, setOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const createPolicy = (policy: ProjectPolicyLineItem) => {
    setPolicies([...policies, policy]);
    setDirtyOnChange(true);
  };

  const goBack = () => {
    project.policies = policies;
    setProject({ ...project });
    handleBack();
  };

  const setProjectPolicies = () => {
    project.policies = policies;
    setProject({ ...project });
    handleNext();
  };

  const handleDelete = (policy: ProjectPolicyLineItem) => {
    setSelectedPolicy(policy);
    setOpenConfirmDialog(true);
  };

  const onDelete = () => {
    setPolicies(policies.filter((f) => f.id !== selectedPolicy?.id));
    setOpenConfirmDialog(false);
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...getHeaderAndCellStyles(undefined, false),
      cellRenderer: InfoCell,
      field: "policyDescription",
      flex: 3,
      headerName: "Policy Name",
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCell,
      cellRendererParams: {
        handleDelete,
        loading,
      },
      field: "",
      flex: 0.5,
      headerName: "",
    },
  ]);

  return (
    <Box>
      <Grid
        container
        sx={{
          padding: "25px 40px 0px 40px",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "25px",
            width: "100%",
          }}
        >
          <SubTitle title={"Policies"} />

          <StyledButton
            data-test-id="add-policy-button"
            className="blackBtn"
            loading={loading}
            variant="contained"
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setSelectedPolicy(createDefaultProjectPolicy(project.id));
              setOpen(true);
            }}
          >
            Add Policy
          </StyledButton>
        </Box>

        <Grid item xs={12} md={12}>
          {loading ? (
            <TableSkeleton
              tableHead={TABLE_HEAD}
              rows={PolicyTableRowSkeleton}
            />
          ) : (
            <Table<ProjectPolicyLineItem>
              columnDefs={colDefs}
              loading={loading}
              data={policies}
            />
          )}
        </Grid>
      </Grid>

      <Grid
        container
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          padding: "20px 40px 30px",
        }}
      >
        <Stack direction="row" spacing={1}>
          <StyledButton
            loading={loading}
            onClick={goBack}
            disabled={loading}
            variant="outlined"
          >
            Back
          </StyledButton>
          <StyledButton
            loading={loading}
            onClick={setProjectPolicies}
            variant="contained"
            type="submit"
          >
            Next
          </StyledButton>
        </Stack>
      </Grid>
      {selectedPolicy ? (
        <PolicyModal
          open={open}
          loading={loading}
          projectPolicy={selectedPolicy}
          createPolicy={createPolicy}
          handleClose={handleClose}
        />
      ) : null}

      <ConfirmationDialog
        message={"Are you sure you want to delete this policy?"}
        open={openConfirmDialog}
        title={"Delete Policy"}
        intent={"error"}
        buttonText={"Delete"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={onDelete}
      />
    </Box>
  );
};
