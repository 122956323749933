import { Button, Grid, Pagination, Stack } from "@mui/material";
import { ConfirmationDialog } from "@stories/molecules/ConfirmationDialog/ConfirmationDialog";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { useState } from "react";
import { DEFAULT_PAGINATION_LIMIT } from "social-pro-common/entities/contractorEmployee";
import { OrganisationLineItem } from "social-pro-common/interfaces/organisation";

import { OrganisationTableRowSkeleton } from "./OrganisationTableRowSkeleton";
import { generateTableHead, Table } from "../Table/Table";
import {
  AvatarNameCellRenderer,
  getHeaderAndCellStyles,
} from "../Table/TableCells";
import TableSkeleton from "../Table/TableSkeleton";

interface OrganisationTableProps {
  loading: boolean;
  userOrganisation?: OrganisationLineItem;
  organisations: OrganisationLineItem[];
  organisationCount: number;
  setPage: (page: number) => void;
  handleJoinOrganisation: (organisation: OrganisationLineItem) => void;
  handleUpdateOrganisationContractor: (
    organisation: OrganisationLineItem,
    isContractor: boolean,
  ) => void;
  handleUpdateMaxProjectCount: (
    organisation: OrganisationLineItem,
    maxActiveProjects: number,
  ) => void;
  handleClickOrganisation: (organisation: OrganisationLineItem) => void;
}

interface ActionCellProps extends CustomCellRendererProps {
  loading: boolean;
  handleDelete: (name: string) => void;
}

export const OrganisationTable = ({
  handleClickOrganisation,
  handleJoinOrganisation,
  loading,
  organisationCount,
  organisations,
  setPage,
  userOrganisation,
}: OrganisationTableProps) => {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value - 1);
  };
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedOrganisation, setSelectedOrganisation] =
    useState<OrganisationLineItem>();

  const handleOpenConfirmDialog = (organisation: OrganisationLineItem) => {
    setSelectedOrganisation(organisation);
    setOpenConfirmDialog(true);
  };

  const ActionCellRender = ({ context, data }: ActionCellProps) => {
    return (
      <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
        <Button
          color="primary"
          size="small"
          disabled={data.id === userOrganisation?.id || !data.isSetup}
          onClick={() => context.handleClickOrganisation(data)}
          className="blackBtn"
          sx={{
            color: "white",
            fontSize: "12px",
          }}
        >
          Users
        </Button>
        <Button
          color="primary"
          size="small"
          disabled={data.id === userOrganisation?.id || !data.isSetup}
          onClick={() => context.handleOpenConfirmDialog(data)}
          className="blackBtn"
          sx={{ color: "white", fontSize: "12px" }}
        >
          Join
        </Button>
      </Stack>
    );
  };

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...getHeaderAndCellStyles(),
      cellRenderer: AvatarNameCellRenderer,
      cellRendererParams: { isOrganisation: true },
      field: "organisationName",
      flex: 2,
      headerName: "Organisation Name",
    },
    {
      ...getHeaderAndCellStyles("center"),
      field: "maxActiveProjects",
      headerName: "Max Projects",
    },
    {
      ...getHeaderAndCellStyles("center"),
      field: "maxActiveProjects",
      headerName: "Is Contractor",
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCellRender,
      field: "",
      flex: 2,
      headerName: "",
    },
  ]);

  return (
    <>
      <Grid item xs={12} md={12}>
        {loading ? (
          <TableSkeleton
            tableHead={generateTableHead(colDefs)}
            rows={OrganisationTableRowSkeleton}
          />
        ) : (
          <Table<OrganisationLineItem>
            columnDefs={colDefs}
            loading={loading}
            data={organisations || []}
            context={{
              handleClickOrganisation,
              handleOpenConfirmDialog,
              userOrganisation,
            }}
            searchKeys={["organisationName"]}
          />
        )}
      </Grid>

      {organisationCount > DEFAULT_PAGINATION_LIMIT ? (
        <Grid
          item
          xs={12}
          md={12}
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Pagination
            count={Math.ceil(organisationCount / DEFAULT_PAGINATION_LIMIT)}
            sx={{ margin: "auto" }}
            onChange={handleChange}
            boundaryCount={2}
          />
        </Grid>
      ) : null}
      <ConfirmationDialog
        message={"Do you wish to join this organisation?"}
        open={openConfirmDialog}
        title={"Join Organisation"}
        intent={"error"}
        buttonText={"Join"}
        onCancel={() => setOpenConfirmDialog(false)}
        onConfirm={() => {
          if (selectedOrganisation) {
            handleJoinOrganisation(selectedOrganisation);
          }
        }}
      />
    </>
  );
};
