import { useProjectContext } from "@hooks/context/useProjectContext";
import { useDashboard } from "@hooks/crud/dashboard/useDashboard";
import { Grid } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import DashboardSummary from "@stories/organisms/DashboardSummary/DashboardSummary";
import { Header } from "@stories/organisms/Header/Header";
import MatricesHome from "@stories/organisms/MatricesHome/MatricesHome";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import React, { useState } from "react";
import { getMaximalDate } from "social-pro-common/utils/date";

export const Dashboard = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const reportId = getMaximalDate(
    isPrimaryPackage
      ? selectedProject?.projectStartDate
      : contractorPackage?.packageStartDate,
    isPrimaryPackage
      ? selectedProject?.projectEndDate
      : contractorPackage?.packageEndDate,
  );
  const { dashboardData, isAnalysisLoading } = useDashboard(
    selectedProject?.id,
    contractorPackage?.id,
    reportId,
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const loading = isProjectLoading || isAnalysisLoading;

  const showLidp =
    loading ||
    isPrimaryPackage ||
    (contractorPackage?.financial && contractorPackage?.financial.lidpPc > 0) ||
    false;
  return (
    <PageContainer>
      <Header
        mainTitle="Project Dashboard"
        subTitle={dashboardData?.projectName || "Project"}
        loading={loading}
      />
      <MatricesHome loading={loading} data={dashboardData} />
      <Grid md={12} item gap={1}>
        <WhiteBox sx={{ padding: "24px" }}>
          <DashboardSummary
            loading={loading}
            data={dashboardData}
            tabIndex={tabIndex}
            handleTabChange={handleTabChange}
            showLidp={showLidp}
          />
        </WhiteBox>
      </Grid>
    </PageContainer>
  );
};
