import { useProfileContext } from "@hooks/context/useProfileContext";
import { useProjectContext } from "@hooks/context/useProjectContext";
import { Header } from "@stories/organisms/Header/Header";
import { PackageDetailsForecast } from "@stories/organisms/PackageDetails/PackageDetailsForecast";
import { PackageDetailsHoursLevel1 } from "@stories/organisms/PackageDetails/PackageDetailsHoursLevel1";
import { PackageDetailsHoursLevel2 } from "@stories/organisms/PackageDetails/PackageDetailsHoursLevel2";
import { PackageDetailsHoursLevel3 } from "@stories/organisms/PackageDetails/PackageDetailsHoursLevel3";
import { PackageDetailsLocalContent } from "@stories/organisms/PackageDetails/PackageDetailsLocalContent";
import { PackageDetailsPolicy } from "@stories/organisms/PackageDetails/PackageDetailsPolicy";
import { PackageReports } from "@stories/organisms/PackageDetails/PackageDetailsReports";
import { PackageDetailsSpend } from "@stories/organisms/PackageDetails/PackageDetailsSpend";
import { PackageDetailsSpendTarget } from "@stories/organisms/PackageDetails/PackageDetailsSpendTarget";
import { SummaryDetails } from "@stories/organisms/PackageDetails/PackageDetailsSummary";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import { useState } from "react";
import {
  LabourHourTargetAnalyticsLineItem,
  SocialSpendTargetAnalyticsLineItem,
} from "social-pro-common/interfaces/analytics";
import { getSortedLocalContent } from "social-pro-common/interfaces/packageLocalContent";

export const MyPackage = () => {
  const { isAuthProfileLoading, userOrganisation } = useProfileContext();

  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();
  const [labourHourTargetId, setLabourHourTargetId] = useState<string>();
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>();

  const onLabourHourTargetChange = (
    target?: LabourHourTargetAnalyticsLineItem,
  ) => {
    setLabourHourTargetId(target?.id);
  };

  const [socialSpendTargetId, setSocialSpendTargetId] = useState<string>();

  const onSocialSpendTargetChange = (
    target?: SocialSpendTargetAnalyticsLineItem,
  ) => {
    setSocialSpendTargetId(target?.id);
  };

  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabChange = async (tabIndex: number) => {
    setLabourHourTargetId(undefined);
    setSocialSpendTargetId(undefined);
    setTabIndex(tabIndex);
  };

  const loading = isAuthProfileLoading || isProjectLoading;

  const selectedPackageStep = (() => {
    switch (tabIndex) {
      case 0:
        return !labourHourTargetId ? (
          <PackageDetailsHoursLevel1
            isLoading={loading}
            subPackage={contractorPackage}
            onLabourHourTargetChange={onLabourHourTargetChange}
          />
        ) : !selectedEmployeeId ? (
          <PackageDetailsHoursLevel2
            isLoading={loading}
            subPackage={contractorPackage}
            targetId={labourHourTargetId}
            handleOnBack={() => {
              setLabourHourTargetId(undefined);
            }}
            setSelectedEmployeeId={(employeeId?: string) => {
              setSelectedEmployeeId(employeeId);
            }}
          />
        ) : (
          <PackageDetailsHoursLevel3
            isLoading={loading}
            subPackage={contractorPackage}
            isMyPackageSection
            targetId={labourHourTargetId}
            handleOnBack={() => {
              setSelectedEmployeeId(undefined);
            }}
          />
        );

      case 1:
        return !socialSpendTargetId ? (
          <PackageDetailsSpend
            isLoading={loading}
            subPackage={contractorPackage}
            onSocialSpendTargetChange={onSocialSpendTargetChange}
          />
        ) : (
          <PackageDetailsSpendTarget
            isLoading={loading}
            subPackage={contractorPackage}
            targetId={socialSpendTargetId}
            isMyPackageSection
            handleOnBack={() => {
              setSocialSpendTargetId(undefined);
            }}
          />
        );
      case 2:
        return (
          <PackageDetailsLocalContent
            loading={loading}
            subPackage={contractorPackage}
            localContents={getSortedLocalContent(
              selectedProject?.localContents,
            )}
          />
        );
      case 3:
        return (
          <PackageDetailsPolicy
            isLoading={loading}
            subPackage={contractorPackage}
          />
        );
      // case 4:
      //   return (
      //     <PackageDetailsEnviroSustainability
      //       loading={loading}
      //       subPackage={contractorPackage}
      //       project={selectedProject}
      //     />
      //   );
      case 4:
        return (
          <PackageDetailsForecast
            isLoading={loading}
            subPackage={contractorPackage}
          />
        );
      case 5:
        return (
          <PackageReports
            isLoading={loading}
            contractorPackage={contractorPackage}
            subPackage={contractorPackage}
          />
        );
      default:
      case 6:
        return (
          <SummaryDetails
            isLoading={loading}
            project={selectedProject}
            contractorOrganisation={userOrganisation}
            subPackage={contractorPackage}
          />
        );
    }
  })();

  const tabs = [
    // {
    //   label: "Enviro & Sustainability",
    //  index: 4,
    // },
  ];

  if (selectedProject?.commitmentsHours.length) {
    tabs.push({
      index: 0,
      label: "Labour Hours",
    });
  }

  if (selectedProject?.commitmentsSpend.length) {
    tabs.push({
      index: 1,
      label: "Social Spend",
    });
  }

  if (selectedProject?.localContents.length) {
    tabs.push({
      index: 2,
      label: "Local Content",
    });
  }

  if (selectedProject?.policies.length) {
    tabs.push({
      index: 3,
      label: "Policy",
    });
  }

  if (
    selectedProject?.commitmentsHours.length ||
    selectedProject?.commitmentsSpend.length
  ) {
    tabs.push({
      index: 4,
      label: "Forecast",
    });
  }
  tabs.push(
    ...[
      {
        index: 5,
        label: "Reports",
      },
      {
        index: 6,
        label: "Details",
      },
    ],
  );
  return (
    <PageContainer>
      <Header
        loading={loading}
        subTitle={`${userOrganisation?.organisationName || "Organisation"} specific target information`}
        mainTitle="My Package"
        tabs={tabs}
        handleTabChange={handleTabChange}
        tabIndex={tabIndex}
      />

      {selectedPackageStep}
    </PageContainer>
  );
};
