import { Box, Stack } from "@mui/material";
import FederatedLoginForm from "@stories/organisms/FederatedLoginForm/FederatedLoginForm";
import LoginForm from "@stories/organisms/LoginForm/LoginForm";
import ResetPasswordSubmitForm from "@stories/organisms/ResetPasswordForm/ResetPasswordForm";
import Logo from "assets/images/home/whiteLogo.svg";
import { isBMD } from "init";
import { useState } from "react";

const Login = () => {
  const isSubdomain = isBMD();
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [emailAddress, setEmailAddress] = useState<string>();
  const [password, setPassword] = useState<string>();
  return (
    <Box className="loginPage">
      <Stack spacing={2} className="loginCard">
        <a href="/" style={{ margin: "0 auto 10px", maxWidth:'276px',textAlign: "center" }}>
          <img src={Logo} alt="logo"/>
        </a>
        {isSubdomain ? (
          <FederatedLoginForm />
        ) : showResetPassword && emailAddress && password ? (
          <ResetPasswordSubmitForm
            emailAddress={emailAddress}
            password={password}
            showResetPassword={showResetPassword}
            setShowResetPassword={setShowResetPassword}
          />
        ) : (
          <LoginForm
            showResetPassword={showResetPassword}
            setShowResetPassword={setShowResetPassword}
            setPassword={setPassword}
            setEmailAddress={setEmailAddress}
          />
        )}
      </Stack>
    </Box>
  );
};

export default Login;
