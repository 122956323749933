import { useProjectContext } from "@hooks/context/useProjectContext";
import { useLabourHourAnalyticsLevel4 } from "@hooks/crud/analytics/useLabourHourAnalyticsLevel4";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { writeXlsxTotalWorkforceIDData } from "@utils/xlsx/labourHours";
import { ExportIcon } from "assets/constants";
import { CalendarClock, Package } from "lucide-react";
import { useState } from "react";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { LabourHourForTargetLineItem } from "social-pro-common/interfaces/labourHour";
import { labourHourCommitmentTypeToString } from "social-pro-common/interfaces/packageLabourHourCommitment";
import {
  dateToString,
  getMaximalDate,
  stringToDate,
} from "social-pro-common/utils/date";

import { AnalyticsHoursLevel4Table } from "../AnalyticsHoursTables/AnalyticsHoursLevel4Table/AnalyticsHoursLevel4Table";
import { BreadcrumbData } from "../Breadcrumbs/Breadcrumbs";
import { Header, HeaderButtonConfig, HeaderButtonType } from "../Header/Header";
import LabourHourAnalyticsSummaryLevel4 from "../Summary/LabourHourAnalyticsSummaryLevel4";

interface PackageDetailsHoursLevel3Props {
  isLoading: boolean;
  subPackage?: ContractorPackageLineItem;
  targetId: string;
  handleOnBack: () => void;
  isMyPackageSection?: boolean;
}

export const PackageDetailsHoursLevel3 = ({
  handleOnBack,
  isLoading,
  isMyPackageSection,
  subPackage,
  targetId,
}: PackageDetailsHoursLevel3Props) => {
  const [reportId, _setReportDate] = useState<string>(dateToString(new Date()));

  const {
    contractorPackage,
    isPrimaryPackage,
    isProjectLoading,
    selectedProject,
  } = useProjectContext();

  const { isAnalysisLoading, labourHoursAnalytics } =
    useLabourHourAnalyticsLevel4(
      reportId,
      selectedProject?.id,
      subPackage?.id,
      targetId,
    );

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAnalytics, setFilteredAnalytics] = useState<
    LabourHourForTargetLineItem[]
  >([]);

  const handleExportHours = async () => {
    if (filteredAnalytics?.length > 0) {
      writeXlsxTotalWorkforceIDData(filteredAnalytics);
    }
  };
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const [currentDate, setCurrentDate] = useState<Date>(
    stringToDate(
      getMaximalDate(
        isPrimaryPackage
          ? selectedProject?.projectStartDate
          : contractorPackage?.packageStartDate,
        isPrimaryPackage
          ? selectedProject?.projectEndDate
          : contractorPackage?.packageEndDate,
      ),
    ),
  );

  const handleDateChange = async (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const loading = isLoading || isProjectLoading || isAnalysisLoading;
  const selectedTarget = selectedProject?.commitmentsHours.find(
    (c) => c.id === targetId,
  );

  const buttons: HeaderButtonConfig[] = [
    {
      action: handleExportHours,
      icon: <ExportIcon />,
      text: "Export Targets",
      type: HeaderButtonType.SECONDARY,
      width: 150,
    },
  ];

  const breadcrumbData = [] as BreadcrumbData[];

  if (isMyPackageSection) {
    breadcrumbData.push(
      {
        action: handleOnBack,
        label: "My package",
      },
      {
        action: handleOnBack,
        label: "Labour Hours (Level 1)",
      },
      {
        label: "Labour Hours (Level 2)",
      },
    );
  } else {
    breadcrumbData.push(
      {
        action: handleOnBack,
        label: "Overview",
      },
      {
        label: "Labour Hours (Level 1)",
      },
    );
  }

  return (
    <>
      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <LabourHourAnalyticsSummaryLevel4
          summary={labourHoursAnalytics?.summary}
          loading={loading}
        />
      </Grid>
      <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
        <WhiteBox>
          <Stack direction="column" gap={4}>
            <Header
              mainTitle={`Labour Hours`}
              subTitle={`${
                selectedTarget
                  ? selectedTarget.targetDescription ||
                    labourHourCommitmentTypeToString(selectedTarget.targetName)
                  : ""
              }`}
              loading={loading}
              buttons={buttons}
              breadcrumbIcon={
                isMyPackageSection ? <Package /> : <CalendarClock />
              }
              handleBack={handleOnBack}
              handleSearchChange={handleSearchChange}
              searchTerm={searchTerm}
              isSubheading
              currentDate={currentDate}
              breadcrumbs={breadcrumbData}
              handleDateChange={handleDateChange}
              headerFontSizes={[18, 14]}
              headerGap={0}
            />

            <AnalyticsHoursLevel4Table
              loading={loading}
              targetId={targetId}
              reportId={""}
              searchTerm={searchTerm}
              labourHoursAnalytics={labourHoursAnalytics}
              onFilteredDataChange={setFilteredAnalytics}
            />
          </Stack>
        </WhiteBox>
      </Grid>
    </>
  );
};
