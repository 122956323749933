import { Box, Button, styled, Typography } from "@mui/material";

const StyledBoxContainer = styled(Box)({
  alignItems: "center",
  background: "linear-gradient(41.49deg, #0288E1 -3.74%, #055E99 74.15%)",
  backgroundAttachment: "fixed",
  display: "flex",
  fontFamily: "'Inter', sans-serif",
  justifyContent: "center",
  padding: "64px 24px",
});

const StyledBox = styled(Box)({
  backgroundColor: "#FFFFFF26",
  borderRadius: "25px",
  fontFamily: "'Inter', sans-serif",
  margin: "0 auto",
  maxWidth: "792px",
  padding: "89px 72px",
  width: "100%",
});

const Typography404 = styled(Typography)({
  color: "#ffffff",
  fontFamily: "'Inter', sans-serif",
  fontSize: "150px",
  fontWeight: "600",
  lineHeight: "175.78px",
  textAlign: "center",
});

const StyledTitle = styled(Typography)({
  color: "#ffffff",
  fontFamily: "'Inter', sans-serif",
  fontSize: "50px",
  fontWeight: "500",
  lineHeight: "70px",
  textAlign: "center",
});

const StyledDescription = styled(Typography)({
  color: "#ffffff",
  fontFamily: "'Inter', sans-serif",
  fontSize: "25px",
  fontWeight: "500",
  lineHeight: "30px",
  textAlign: "center",
});

const NavigateButton = styled(Button)({
  "&:hover": {
    backgroundColor: "#05609D",
  },
  backgroundColor: "#0B4A6F",
  border:
    "2px solid linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
  borderRadius: "8px",
  color: "white",
  fontFamily: "'Inter', sans-serif",
  fontSize: "16px",
  fontWeight: "600",
  lineHeight: "24px",
  minWidth: "360px",
  padding: "10px 16px",
  textTransform: "initial",
});

export {
  StyledBoxContainer,
  StyledBox,
  Typography404,
  StyledTitle,
  StyledDescription,
  NavigateButton,
};
