import { useProjectContext } from "@hooks/context/useProjectContext";
import { useSocialSpendPackageDetailsLevel1 } from "@hooks/crud/analytics/useSocialSpendPackageDetailsLevel1";
import { Grid, Stack } from "@mui/material";
import { WhiteBox } from "@stories/atoms/Whitebox/WhiteBox";
import { writeXlsxSocialSpendTargets } from "@utils/xlsx/spend";
import { ExportIcon } from "assets/constants";
import { useState } from "react";
import { SocialSpendTargetAnalyticsLineItem } from "social-pro-common/interfaces/analytics";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import {
  dateToString,
  getMaximalDate,
  stringToDate,
} from "social-pro-common/utils/date";

import { AnalyticsSpendLevel1Table } from "../AnalyticsSpendTables/AnalyticsSpendLevel1Table/AnalyticsSpendLevel1Table";
import { Header, HeaderButtonConfig, HeaderButtonType } from "../Header/Header";

interface PackageDetailsSpendProps {
  isLoading: boolean;
  subPackage?: ContractorPackageLineItem;
  onSocialSpendTargetChange: (
    target: SocialSpendTargetAnalyticsLineItem,
  ) => void;
}

export const PackageDetailsSpend = ({
  isLoading,
  onSocialSpendTargetChange,
  subPackage,
}: PackageDetailsSpendProps) => {
  const [reportId, _setReportDate] = useState<string>(dateToString(new Date()));
  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { isAnalysisLoading, socialSpendAnalytics } =
    useSocialSpendPackageDetailsLevel1(
      reportId,
      selectedProject?.id,
      subPackage?.id,
    );
  const [searchTerm, setSearchTerm] = useState("");
  // Handle Table record Search
  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };
  const [filteredAnalytics, setFilteredAnalytics] = useState<
    SocialSpendTargetAnalyticsLineItem[]
  >([]);

  const isPrimaryPackage = subPackage?.id === selectedProject?.id;

  const [currentDate, setCurrentDate] = useState<Date>(
    stringToDate(
      getMaximalDate(
        isPrimaryPackage
          ? selectedProject?.projectStartDate
          : contractorPackage?.packageStartDate,
        isPrimaryPackage
          ? selectedProject?.projectEndDate
          : contractorPackage?.packageEndDate,
      ),
    ),
  );

  const handleDateChange = async (currentDate: Date) => {
    setCurrentDate(currentDate);
  };

  const loading = isLoading || isProjectLoading || isAnalysisLoading;

  const handleExportSpend = async () => {
    if (filteredAnalytics?.length > 0) {
      writeXlsxSocialSpendTargets(filteredAnalytics);
    }
  };

  const buttons: HeaderButtonConfig[] = [
    {
      action: handleExportSpend,
      icon: <ExportIcon />,
      text: "Export Targets",
      type: HeaderButtonType.SECONDARY,
      width: 150,
    },
  ];
  return (
    <Grid item xs={12} md={12} sx={{ padding: "0px !important" }}>
      <WhiteBox>
        <Stack direction="column" gap={4}>
          <Header
            mainTitle="Social Spend"
            subTitle="Social Spend Target Summary"
            loading={loading}
            buttons={buttons}
            handleSearchChange={handleSearchChange}
            searchTerm={searchTerm}
            isSubheading
            currentDate={currentDate}
            handleDateChange={handleDateChange}
            headerFontSizes={[18, 14]}
            headerGap={0}
          />

          <AnalyticsSpendLevel1Table
            loading={loading}
            isPrimaryPackage={isPrimaryPackage}
            searchTerm={searchTerm}
            socialSpendAnalysis={socialSpendAnalytics}
            handleAction={onSocialSpendTargetChange}
            onFilteredDataChange={setFilteredAnalytics}
          />
        </Stack>
      </WhiteBox>
    </Grid>
  );
};
