import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  AvatarNameCellRenderer,
  CellAction,
  getHeaderAndCellStyles,
  ProgressCell,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { useState } from "react";
import {
  AnalyticsLabourHoursLevel2PageModelLineItem,
  LabourHourTargetAnalyticsForPackageLineItem,
} from "social-pro-common/interfaces/analytics";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { formatDecimalPlaces } from "social-pro-common/utils/number";

import { AnalyticsHoursLevel2TableRowSkeleton } from "./AnalyticsHoursLevel2TableRowSkeleton";

interface AnalyticsHoursLevel2TableProps {
  loading: boolean;
  targetId: string;
  reportId: string;
  searchTerm: string;
  labourHoursAnalytics?: AnalyticsLabourHoursLevel2PageModelLineItem;
  handleAction: (contractorPackage: ContractorPackageLineItem) => void;
}

export const AnalyticsHoursLevel2Table = ({
  handleAction,
  labourHoursAnalytics,
  loading,
  searchTerm,
}: AnalyticsHoursLevel2TableProps) => {
  const cellActions = [
    {
      action: handleAction,
      adminOnly: true,
      singleAction: true,
      title: "View",
    },
  ] as CellAction[];

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...getHeaderAndCellStyles(),
      cellRenderer: AvatarNameCellRenderer as any,
      field: "contractorName",
      flex: 3,
      headerName: "Subcontractor",
      onCellClicked: (params) => handleAction(params.data),
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "targetValue",
      headerName: "Target (Hours)",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "totalReportedValue",
      headerName: "Actual (Hours)",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right"),
      field: "monthReportedValue",
      headerName: "This Month",
      onCellClicked: (params) => handleAction(params.data),
      valueFormatter: (params) => `${formatDecimalPlaces(params.value, 0)}`,
    },
    {
      ...getHeaderAndCellStyles("right"),
      cellRenderer: ProgressCell,
      field: "progress",
      flex: 2,
      headerName: "Progress To Target",
      onCellClicked: (params) => handleAction(params.data),
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCell,
      cellRendererParams: {
        text: "View",
      },
      field: "",
      headerName: "",
    },
  ]);

  return (
    <>
      {loading ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={AnalyticsHoursLevel2TableRowSkeleton}
          noFullTableBorder
        />
      ) : (
        <Table<LabourHourTargetAnalyticsForPackageLineItem>
          columnDefs={colDefs}
          loading={loading}
          searchValue={searchTerm}
          context={{ cellActions }}
          noFullTableBorder
          data={labourHoursAnalytics?.subcontractorData || []}
          searchKeys={["contractorName"]}
        />
      )}
    </>
  );
};
