import { useProjectContext } from "@hooks/context/useProjectContext";
import { usePolicyAnalytics } from "@hooks/crud/analytics/usePolicyAnalytics";
import { Grid } from "@mui/material";
import { ContractorAnalyticsPolicyTable } from "@stories/molecules/ContractorAnalyticsPolicy/ContractorAnalyticsPolicyTable";
import { Header } from "@stories/organisms/Header/Header";
import { PageContainer } from "@stories/organisms/PageContainer/PageContainer";
import * as RouteHelper from "@utils/routes";
import { Scroll } from "lucide-react";
import { useState } from "react";

export const AnalyticsPolicy = () => {
  const { contractorPackage, isProjectLoading, selectedProject } =
    useProjectContext();

  const { isAnalysisLoading, policyAnalytics } = usePolicyAnalytics(
    selectedProject?.id,
    contractorPackage?.id,
  );

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = async (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const loading = isProjectLoading || isAnalysisLoading;

  const breadcrumbData = [
    {
      href:
        selectedProject && contractorPackage
          ? RouteHelper.analyticsPolicies(
              selectedProject.id,
              contractorPackage.id,
            )
          : "",
      label: "Policies",
    },
  ];
  return (
    <PageContainer>
      <Header
        breadcrumbIcon={<Scroll />}
        loading={loading}
        subTitle={"Submission Status of Policies"}
        mainTitle={"Policies"}
        handleSearchChange={handleSearchChange}
        breadcrumbs={breadcrumbData}
      />
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          margin: "0",
          width: "100%",
        }}
      >
        <ContractorAnalyticsPolicyTable
          loading={loading}
          policyAnalytics={policyAnalytics}
          searchTerm={searchTerm}
        />
      </Grid>
    </PageContainer>
  );
};
