import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DangerousOutlinedIcon from "@mui/icons-material/DangerousOutlined";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { StyledTextField } from "@stories/atoms/StyledTextField/StyledTextField";
import { CircularProgressWithLabel } from "@stories/molecules/CircularProgressWithLabel/CircularProgressWithLabel";
import { ColDef } from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import { DropdownIcon } from "assets/constants";
import { FocusEvent, useCallback, useRef, useState } from "react";
import { LabourHourLineItem } from "social-pro-common/interfaces/labourHour";
import { PolicySubmissionLineItem } from "social-pro-common/interfaces/policySubmission";
import { ProfileLineItem } from "social-pro-common/interfaces/profile";
import {
  policyTypeToString,
  ProjectPolicyLineItem,
} from "social-pro-common/interfaces/projectPolicy";
import { formatDecimalPlaces } from "social-pro-common/utils/number";
import { getDocumentName } from "social-pro-common/utils/string";

interface ActionCellProps extends CustomCellRendererProps {
  userProfile: ProfileLineItem;
  text?: string;
  validateEnabled?: (data: any) => boolean;
}

interface AvatarCellProps extends CustomCellRendererProps {
  isOrganisation?: boolean;
}

export const getHeaderAndCellStyles = (
  alignment: "left" | "center" | "right" = "left",
  clickable = true,
) => {
  const cellStyle = {
    alignItems: "center",
    cursor: clickable ? "pointer" : "default",
    display: "flex",
    justifyContent:
      alignment === "center"
        ? "center"
        : alignment === "left"
          ? "flex-start"
          : "flex-end",
    textAlign: alignment,
  };

  const header = alignment === "center" ? "centered" : alignment;

  const headerClass = `${header}-table-header`;
  return { cellStyle, headerClass };
};

export const defaultCellConfig: ColDef = {
  flex: 1,
  resizable: false,
  wrapHeaderText: true,
};

export interface CellAction {
  title: string;
  icon: React.ReactNode;
  action: (data: any) => Promise<void>;
  disabled?: boolean;
  adminOnly?: boolean;
  singleAction?: boolean;
  validateEnabled?: (data: any) => boolean;
}

export const ActionCell = ({
  context: { cellActions },
  data,
}: ActionCellProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const enabledButtons =
    cellActions && cellActions.length
      ? cellActions.filter((action: CellAction) => {
          const activeValue = action.validateEnabled
            ? action.validateEnabled(data)
            : true;
          if (!activeValue) {
            return false;
          }
          return true;
        })
      : [];

  if (data.isTotalRow || !enabledButtons?.length) {
    return <> </>;
  }

  if (cellActions?.length === 1 && cellActions[0]?.singleAction) {
    const action = cellActions[0];
    return (
      <Button
        sx={{ ":hover": { background: "none" }, color: "#155EEF" }}
        disabled={action.disabled}
        disableRipple
        variant="text"
        onClick={() => action.action(data)}
      >
        {action.title}
      </Button>
    );
  }

  return cellActions && cellActions.length ? (
    <>
      <Box
        sx={{
          alignItem: "center",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <IconButton
          disableRipple
          onClick={handleClick}
          aria-label="more"
          size="medium"
          sx={{
            minWidth: "auto",
          }}
        >
          <DropdownIcon />
        </IconButton>
      </Box>
      <Menu
        className="actionlist"
        disableScrollLock
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": { p: 0 },
          "& .MuiMenu-paper ul li": {
            m: 0,
            p: 0,
          },
          "& .MuiMenu-paper ul li:focus": {
            backgroundColor: "transparent !important",
          },
          "& .MuiMenu-paper ul li:hover": {
            backgroundColor: "transparent !important",
            borderRadius: 0,
          },
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              border: "1px solid #E9EAEB",
              borderRadius: "10px",
              boxShadow: "0px 1px 2px 0px #0A0D120D",
              mt: 0.5,
              overflow: "visible",
              padding: "12px",
            },
          },
        }}
      >
        {cellActions
          .filter((action: CellAction) => {
            const activeValue = action.validateEnabled
              ? action.validateEnabled(data)
              : true;
            if (!activeValue) {
              return false;
            }
            return true;
          })
          .map((action: CellAction, index: number, arr: CellAction[]) => {
            return [
              <MenuItem
                key={`${index}-menu-item`}
                disableRipple
                onClick={() => {
                  action.action(data);
                  setAnchorEl(null);
                }}
                sx={{ p: 0 }}
                disabled={action.disabled}
              >
                <Box sx={{ alignItems: "center", display: "flex", gap: "8px" }}>
                  {action.icon}
                  <Typography
                    color="#181D27"
                    sx={{
                      fontFamily: "'Inter'",
                      fontSize: "12px",
                      lineHeight: "18px",
                    }}
                  >
                    {action.title}
                  </Typography>
                </Box>
              </MenuItem>,
              arr.length > 1 && index < arr.length - 1 ? (
                <Divider
                  key={`${index}-divider`}
                  orientation="horizontal"
                  sx={{ backgroundColor: "#D5D7DA" }}
                />
              ) : null,
            ].filter(Boolean);
          })}
      </Menu>
    </>
  ) : null;
};

export const ActionCellUriToggle = (props: ActionCellProps) => {
  const { context, data, text } = props;

  function handleClick() {
    context.handleAction(data);
  }

  return (
    <Button
      sx={{ ":hover": { background: "none" }, color: "#155EEF" }}
      disableRipple
      onClick={handleClick}
      variant="text"
      disabled={context.loading || !data.uri}
    >
      {text}
    </Button>
  );
};

export const ProgressCell = (props: CustomCellRendererProps) => {
  const progress = props.data.progress ?? props.data.estimatedProgress ?? 0;
  const clampedProgress = Math.min(100, Math.max(0, progress));
  return (
    <>
      <Stack
        direction={"row"}
        gap={"12px"}
        alignItems={"center"}
        width={"100%"}
      >
        <Box
          sx={{
            bgcolor: "#E9EAEB",
            borderRadius: "9999px",
            flex: 1,
            height: "8px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              bgcolor: "#004eeb",
              borderRadius: "9999px",
              height: "8px",
              width: `${clampedProgress}%`,
            }}
          />
        </Box>
        <Typography
          sx={{
            color: "#181D27",
            fontFamily: '"Inter", sans-serif',
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "20px",
          }}
        >
          {formatDecimalPlaces(clampedProgress, 0)}%
        </Typography>
      </Stack>
    </>
  );
};

export const SubmissionCell = (props: CustomCellRendererProps) => {
  const { context, data } = props;

  const projectPolicy = data as ProjectPolicyLineItem;
  const policySubmission = context.policySubmissions.find(
    (pp: PolicySubmissionLineItem) => pp.projectPolicyId === projectPolicy.id,
  );

  if (!policySubmission) {
    return (
      <Box alignContent={"center"} display={"flex"} justifyContent={"center"}>
        <DangerousOutlinedIcon
          color="error"
          sx={{ margin: "auto", padding: "auto" }}
        />
      </Box>
    );
  }
  return data.created.toLocaleDateString("en-GB");
};

interface DownloadCellProps extends CustomCellRendererProps {
  text: string;
}

export const UploadDownloadCell = ({ context, data }: DownloadCellProps) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const projectPolicy = data as ProjectPolicyLineItem;
  const policySubmission = context.policySubmissions.find(
    (pp: PolicySubmissionLineItem) => pp.projectPolicyId === projectPolicy.id,
  );

  const isDownloading = context.isDownloading;
  const isUploading = context.isUploading;
  const uploadProgress = context.uploadProgress;
  const selectedTargetId = context.selectedTargetId;

  const handleDownload = () => {
    if (policySubmission?.uri) {
      context.onDownload(
        policySubmission.uri,
        getDocumentName(
          projectPolicy.policyDescription ||
            policyTypeToString(projectPolicy.policyType),
          policySubmission.uri,
        ),
      );
    }
    setAnchorEl(null);
  };

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    context.onUpload(e, data);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFileInput = () => {
    if (fileInput.current) {
      fileInput.current.value = "";
      fileInput.current.click();
    }
  };

  const enabledActions = [
    policySubmission?.uri && {
      icon: <CloudDownloadIcon fontSize="small" />,
      label: "Download",
      onClick: handleDownload,
    },
    {
      icon: <CloudUploadIcon fontSize="small" />,
      label: policySubmission ? "Reupload" : "Upload",
      onClick: onFileInput,
    },
  ].filter(Boolean);

  if (data.isTotalRow || enabledActions.length === 0) {
    return <></>;
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {isUploading && projectPolicy.id === selectedTargetId ? (
          <CircularProgressWithLabel value={uploadProgress} />
        ) : isDownloading && projectPolicy.id === selectedTargetId ? (
          <CircularProgress />
        ) : (
          <IconButton disableRipple onClick={handleClick} size="medium">
            <DropdownIcon />
          </IconButton>
        )}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="actionlist"
        MenuListProps={{ "aria-labelledby": "upload-download-button" }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiList-root": { p: 0 },
          "& .MuiMenu-paper ul li": {
            m: 0,
            p: 0,
          },
          "& .MuiMenu-paper ul li:focus": {
            backgroundColor: "transparent !important",
          },
          "& .MuiMenu-paper ul li:hover": {
            backgroundColor: "transparent !important",
            borderRadius: 0,
          },
        }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              border: "1px solid #E9EAEB",
              borderRadius: "10px",
              boxShadow: "0px 1px 2px 0px #0A0D120D",
              mt: 0.5,
              overflow: "visible",
              padding: "12px",
            },
          },
        }}
      >
        {enabledActions.map((action, index) => (
          <div key={index}>
            <MenuItem onClick={action.onClick} sx={{ p: 0 }}>
              <Box sx={{ alignItems: "center", display: "flex", gap: "8px" }}>
                {action.icon}
                <Typography sx={{ fontSize: "12px", lineHeight: "18px" }}>
                  {action.label}
                </Typography>
              </Box>
            </MenuItem>
            {index < enabledActions.length - 1 && (
              <Divider sx={{ backgroundColor: "#D5D7DA" }} />
            )}
          </div>
        ))}
      </Menu>

      <input ref={fileInput} hidden type="file" onChange={handleUpload} />
    </>
  );
};

export const AvatarNameCellRenderer = (props: AvatarCellProps) => {
  let contractorLogo = props.data.contractorLogo;
  let contractorName = props.data.contractorName;

  if (props.isOrganisation) {
    contractorLogo = props.data.organisationLogo;
    contractorName = props.data.organisationName;
  }

  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Avatar
        src={contractorLogo}
        alt={contractorName}
        style={{
          backgroundColor: "#ffffff",
          height: 24,
          marginRight: "8px",
          width: 24,
        }}
        sizes="small"
      />
      <span>{contractorName}</span>
    </Box>
  );
};

export const HeaderSubHeaderCell = (props: CustomCellRendererProps) => {
  const { header, subheader } = props.data;
  return (
    <Box sx={{ alignItems: "center", display: "flex" }}>
      <Stack direction="column">
        <Typography variant="h6">{header}</Typography>
        <Typography variant="body2">{subheader}</Typography>
      </Stack>
    </Box>
  );
};

interface LabourHourInputCustomCellRendererProps {
  context: any;
  data: any;
  valueKey: "amountOfHoursWorked" | "amountOfHoursStudied";
}

export const LabourHourInputCell = (
  props: LabourHourInputCustomCellRendererProps,
) => {
  const { context, data, valueKey } = props;

  const labourHours = context.labourHours as LabourHourLineItem[];
  const hourForEmployee = labourHours.find((lh) => lh.employeeId === data.id);
  if (!hourForEmployee) {
    throw new Error("hourForEmployee is undefined");
  }

  const [value, setValue] = useState(
    hourForEmployee ? formatDecimalPlaces(hourForEmployee[valueKey], 2) : "0",
  );

  const handleSave = useCallback(
    async (e: FocusEvent<HTMLInputElement>) => {
      const newValue = e.target.value || "0";
      setValue(() => newValue);
      const dataToSave = {
        ...hourForEmployee,
        [valueKey]: parseFloat(newValue),
      };
      await context.handleUpdateValue(dataToSave);
    },
    [hourForEmployee, context, data, valueKey, value, setValue],
  );
  // TODO: fix, it doesn't disable the field correctly
  const isSubmitting = context.submittingRowId === data.id;

  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        variant="standard"
        fullWidth
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={handleSave}
        disabled={isSubmitting || context.disabled}
        InputProps={{
          disableUnderline: true,
          style: {
            backgroundColor: "white",
            borderColor: "#D5D7DA",
            fontSize: "14px",
            lineHeight: "20px",
            opacity: "100%",
            outline: "none",
          },
        }}
        sx={{
          "& .MuiInputBase-input": {
            alignItems: "center",
            borderRadius: "0px !important",
            color: "#717680",
            fontFamily: '"Inter", sans-serif',
            fontSize: "14px",
            lineHeight: "20px",
            maxHeight: "52px",
            ml: 1,
            padding: "0px",
          },
          bgcolor: "white",
          border: "1px solid #D5D7DA",
          borderRadius: "5px",
          boxShadow: "0px 1px 2px 0px #0A0D120D",
          padding: "10px 14px 10px 12px",
        }}
      />
    </Box>
  );
};

interface EditableCellProps extends CustomCellRendererProps {
  rowData: any;
  field: "targetValue" | "targetValueRealised";
  unit?: string;
  context: {
    isLoading: boolean;
    handleCellValueChange: (
      id: string,
      field: string,
      newValue: number,
    ) => void;
  };
}

export const InputEditableCell = (props: EditableCellProps) => {
  const { context, data, field, unit } = props;

  const formattedValue = formatDecimalPlaces(data[field], 2);

  const [inputValue, setInputValue] = useState(formattedValue);

  const handleBlur = useCallback(
    async (e: FocusEvent<HTMLInputElement>) => {
      const newValue = parseFloat(e.target.value.replace(/[^0-9.]/g, "")) || 0;
      setInputValue(formatDecimalPlaces(newValue, 2));
      await context.handleCellValueChange(data.id, field, newValue);
    },
    [context.handleCellValueChange, data.id, field],
  );

  return (
    <StyledTextField
      loading={context.isLoading}
      disabled={context.isLoading}
      margin="dense"
      fullWidth
      value={inputValue}
      className="search tableInput"
      onChange={(e) => setInputValue(e.target.value)}
      onBlur={handleBlur}
      id={`${data.id}-${field}`}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              color: "black",
            }}
          >
            {unit}
          </InputAdornment>
        ),
      }}
    />
  );
};
