import usePdf from "@hooks/crud/downloadFiles/usePdf";
import { useReportSocialSubmission } from "@hooks/crud/reportSocialSubmission/useReportSocialSubmission";
import { Grid } from "@mui/material";
import { generateTableHead, Table } from "@stories/organisms/Table/Table";
import {
  ActionCell,
  getHeaderAndCellStyles,
} from "@stories/organisms/Table/TableCells";
import TableSkeleton from "@stories/organisms/Table/TableSkeleton";
import { ReportStatusCell } from "@stories/organisms/Tables/ProjectTableCells";
import * as RouteHelper from "@utils/routes";
import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  ArchiveProjectIcon,
  BellIcon,
  CheckedboxIcon,
  ExportIcon,
  ViewProjectIcon,
} from "assets/constants";
import { useCallback, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ReportStatus } from "social-pro-common/entities/projectReportSubmission";
import { ContractorPackageLineItem } from "social-pro-common/interfaces/contractorPackage";
import { ProjectLineItem } from "social-pro-common/interfaces/project";
import { ReportSubmissionLineItem } from "social-pro-common/interfaces/reportSubmission";
import {
  formatReportDate,
  reportIdToReportingDate,
  stringToDate,
} from "social-pro-common/utils/date";
import { getZipTitle } from "social-pro-common/utils/string";

import { ReportTableSubRowSkeleton } from "./ReportTableRowSkeleton";
import { AcceptReportDialog } from "../AcceptReportDialog/AcceptReportDialog";
import { PdfDownlodDialog } from "../PdfDownlodDialog/PdfDownlodDialog";
import { RejectReportDialog } from "../RejectReportDialog/RejectReportDialog";

interface ReportTableProps {
  isLoading: boolean;
  project?: ProjectLineItem;
  contractorPackage?: ContractorPackageLineItem;
  subPackage?: ContractorPackageLineItem;
  isPrimaryPackage: boolean;
}

export interface TableContextProps {
  handlViewReport: (reportMonthId: string) => void;
  handleOpenRejectModal: (reportSubmission: ReportSubmissionLineItem) => void;
  handleOpenAcceptModal: (reportSubmission: ReportSubmissionLineItem) => void;
  handleDownloadReport: (contractorPackageId: string, reportId: string) => void;
  handleRevertReport: (
    reportSubmission: ReportSubmissionLineItem,
    rejectMessage: string,
  ) => Promise<void>;
  isPrimaryPackage: boolean;
  project?: ProjectLineItem;
  sendReportReminder: (contractorPackageId: string, reportId: string) => void;
  subPackage?: ContractorPackageLineItem;
}

export const ReportTableSocial = ({
  contractorPackage,
  isLoading,
  isPrimaryPackage,
  project,
  subPackage,
}: ReportTableProps) => {
  const navigate = useNavigate();
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openAcceptModal, setOpenAcceptModal] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState<
    ReportSubmissionLineItem | undefined
  >(undefined);

  const {
    deleteReportSubmission,
    isReportLoading,
    reportSubmissions,
    sendReportReminder,
    updateReportSubmission,
  } = useReportSocialSubmission(subPackage?.id);
  const { downloadProgress, downloadStep, getPdfInfo } = usePdf();

  const loading = isLoading || isReportLoading;

  const orderedSubmissions = reportSubmissions.slice().reverse();

  const tableData =
    project && contractorPackage && subPackage
      ? orderedSubmissions
          .filter((r) => !!r)
          .filter(
            (r) =>
              r.reportStatus === ReportStatus.Overdue ||
              r.reportStatus === ReportStatus.Accepted ||
              r.reportStatus === ReportStatus.Complete,
          )
      : [];

  const handleOpenRejectModal = (sub: ReportSubmissionLineItem) => {
    setSelectedSubmission(sub);
    setOpenRejectModal(true);
  };

  const handleOpenAcceptModal = (sub: ReportSubmissionLineItem) => {
    setSelectedSubmission(sub);
    setOpenAcceptModal(true);
  };

  const handleSendReportReminder = useCallback(
    async (sub: ReportSubmissionLineItem) => {
      await sendReportReminder(sub.contractorPackageId, sub.reportId);
    },
    [reportSubmissions],
  );

  const handleDownloadReport = useCallback(
    async (reportSubmission: ReportSubmissionLineItem) => {
      const reportDate = stringToDate(reportSubmission.reportId);
      const reportTitle = getZipTitle(reportDate);
      getPdfInfo(
        reportSubmission.projectId,
        reportSubmission.reportId,
        reportTitle,
        reportSubmission.contractorPackageId,
      );
    },
    [project, contractorPackage, subPackage, reportSubmissions],
  );

  const handleRevertReport = useCallback(
    async (
      reportSubmission: ReportSubmissionLineItem,
      rejectMessage: string,
    ): Promise<void> => {
      await deleteReportSubmission({
        ...reportSubmission,
        rejectMessage,
      });
    },
    [reportSubmissions],
  );

  const handlViewReport = useCallback(
    (reportSubmission: ReportSubmissionLineItem): void => {
      if (project && contractorPackage && subPackage) {
        navigate({
          pathname: RouteHelper.readOnlyReportSocial(
            project.id,
            contractorPackage.id,
            subPackage.id,
            reportSubmission.reportId,
          ),
          search: createSearchParams({
            redirect: window.location.pathname,
          }).toString(),
        });
      }
    },
    [project, contractorPackage, subPackage, reportSubmissions],
  );

  const handleAcceptReport = useCallback(
    async (reportSubmission: ReportSubmissionLineItem): Promise<void> => {
      await updateReportSubmission({
        ...reportSubmission,
        accepted: true,
        reportStatus: ReportStatus.Accepted,
      });
    },
    [reportSubmissions],
  );

  const cellActions = [
    {
      action: handlViewReport,
      adminOnly: true,
      icon: <ViewProjectIcon />,
      title: "View",
      validateEnabled: (reportSubmission: ReportSubmissionLineItem) =>
        reportSubmission.reportStatus === ReportStatus.Accepted ||
        reportSubmission.reportStatus === ReportStatus.Complete ||
        reportSubmission.reportStatus === ReportStatus.InProgress,
    },
    {
      action: handleDownloadReport,
      adminOnly: true,
      icon: <ExportIcon />,
      title: "Download Report",
      validateEnabled: (reportSubmission: ReportSubmissionLineItem) =>
        reportSubmission.reportStatus === ReportStatus.Accepted ||
        reportSubmission.reportStatus === ReportStatus.Complete,
    },
  ];
  if (isPrimaryPackage) {
    cellActions.push({
      action: handleOpenAcceptModal,
      adminOnly: true,
      icon: <CheckedboxIcon />,
      title: "Accept Report",
      validateEnabled: (reportSubmission: ReportSubmissionLineItem) =>
        isPrimaryPackage &&
        reportSubmission.reportStatus === ReportStatus.Complete,
    });
    cellActions.push({
      action: handleOpenRejectModal,
      adminOnly: true,
      icon: <ArchiveProjectIcon />,
      title: "Reject Report",
      validateEnabled: (reportSubmission: ReportSubmissionLineItem) =>
        isPrimaryPackage &&
        (reportSubmission.reportStatus === ReportStatus.Accepted ||
          reportSubmission.reportStatus === ReportStatus.Complete),
    });

    cellActions.push({
      action: handleSendReportReminder,
      adminOnly: true,
      icon: <BellIcon />,
      title: "Resend Invite",
      validateEnabled: (reportSubmission: ReportSubmissionLineItem) =>
        isPrimaryPackage &&
        reportSubmission.reportStatus !== ReportStatus.Accepted &&
        reportSubmission.reportStatus !== ReportStatus.Complete &&
        reportSubmission.reportStatus !== ReportStatus.Upcoming,
    });
  }

  const [colDefs, _setColDefs] = useState<(ColDef | ColGroupDef)[]>([
    {
      ...getHeaderAndCellStyles(undefined, false),
      field: "reportId",
      flex: 2,
      headerName: "Report Month",
      valueFormatter: (params) =>
        `${formatReportDate(stringToDate(params.data.reportId))}`,
    },
    {
      ...getHeaderAndCellStyles("center", false),
      field: "dueDate",
      flex: 2,
      headerName: "Due Date",
      valueFormatter: (params) =>
        `${reportIdToReportingDate(
          params.data.reportId,
          params.context.project?.reportingDueDate,
        ).toLocaleDateString("en-GB")}`,
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ReportStatusCell,
      field: "status",
      flex: 2,
      headerName: "Status",
    },
    {
      ...getHeaderAndCellStyles("center", false),
      cellRenderer: ActionCell,
      field: "",
      flex: 1,
      headerName: "",
    },
  ]);

  return (
    <Grid item xs={12} md={12}>
      {loading || !reportSubmissions ? (
        <TableSkeleton
          tableHead={generateTableHead(colDefs)}
          rows={ReportTableSubRowSkeleton}
          noFullTableBorder
        />
      ) : (
        <Table<ReportSubmissionLineItem>
          columnDefs={colDefs}
          loading={loading}
          noFullTableBorder
          data={tableData as ReportSubmissionLineItem[]}
          context={
            {
              cellActions,
              isPrimaryPackage,
              project,
              subPackage,
            } as unknown as TableContextProps
          }
        />
      )}
      <PdfDownlodDialog
        downloadStep={downloadStep}
        total={downloadProgress}
        title={"Export Report"}
      />

      {isPrimaryPackage && selectedSubmission && (
        <RejectReportDialog
          open={openRejectModal}
          reportSubmission={selectedSubmission}
          onCancel={() => setOpenRejectModal(false)}
          onConfirm={async (
            reportSubmission: ReportSubmissionLineItem,
            rejectionMessage: string,
          ): Promise<void> => {
            setOpenRejectModal(false);
            await handleRevertReport(reportSubmission, rejectionMessage);
          }}
        />
      )}

      {isPrimaryPackage && selectedSubmission && (
        <AcceptReportDialog
          open={openAcceptModal}
          reportSubmission={selectedSubmission}
          onCancel={() => setOpenAcceptModal(false)}
          onConfirm={async (
            reportSubmission: ReportSubmissionLineItem,
          ): Promise<void> => {
            setOpenAcceptModal(false);
            await handleAcceptReport(reportSubmission);
          }}
        />
      )}
    </Grid>
  );
};
